import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

// hooks
import useResponsive from "../../hooks/useResponsive";
import { useAppContext } from "src/hooks";
import { del_from_local_storage, logout_user } from "src/utils";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
//
import navConfig from "./NavConfig";
import { s3baseUrl } from "src/config/config";
import { MuiDialog } from "src/components";
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));
const LogoutContainer = styled("div")(({ theme }) => ({
  paddingInline: theme.spacing(2.5),
  paddingBlock: theme.spacing(1),
  marginTop: 15,
  backgroundColor: "#ffff",
  position: "absolute",
  width: "100%",
  bottom: "0",
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    dispatch_get_user_profile,
    dispatch_set_search_value,
    disconnectSocket,
    handleLogout,
    logoutDialogOpen,
    setLogoutDialogOpen,
    logoutRadioValue,
    setLogoutRadioValue,
  } = useAppContext();
  const isDesktop = useResponsive("up", "lg");
  const profile = dispatch_get_user_profile();
  // ----------------------------------------------------------------

  // ----------------------------------------------------------------
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }

    dispatch_set_search_value("");
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          px: 2.5,
          py: 2.6,
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          mx: "auto",
        }}
      >
        <Logo />
      </Box>

      <Box sx={{ mb: 2.6, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="/profile">
          <AccountStyle>
            {profile.image ? (
              <>
                <Avatar
                  src={s3baseUrl + profile.image}
                  alt={profile.first_name}
                />
              </>
            ) : (
              <>
                <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main }}>
                  {profile?.first_name?.charAt(0)}
                </Avatar>
              </>
            )}
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {profile.first_name}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={navConfig} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
          <LogoutContainer>
            <Button onClick={handleLogout} variant="contained" fullWidth>
              Logout
            </Button>
          </LogoutContainer>
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
          <LogoutContainer>
            <Button
              onClick={() => setLogoutDialogOpen(true)}
              variant="contained"
              fullWidth
            >
              Logout
            </Button>
          </LogoutContainer>
        </Drawer>
      )}
      <MuiDialog
        open={logoutDialogOpen}
        onToggle={(val) => {
          setLogoutDialogOpen(val);
          setLogoutRadioValue("0");
        }}
        onAgree={handleLogout}
        title="Logout"
        sx={{ minWidth: "40vw" }}
        message="Are you sure you want to logout?"
        ExtraComponent={
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={logoutRadioValue}
            value={logoutRadioValue}
            onChange={(e) => setLogoutRadioValue(e.target.value)}
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="0"
              control={<Radio />}
              label="Logout from this device"
            />
            <FormControlLabel
              value="1"
              control={<Radio />}
              label="Logout from all devices"
            />
          </RadioGroup>
        }
      />
    </RootStyle>
  );
}
