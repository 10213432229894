import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useState } from "react";
import Iconify from "../../Iconify";
import PropTypes from "prop-types";
import { TASK_PRIORITY_OPTIONS } from "src/constants";
// ---------------------------------------------------------
const icon_props = {
  width: "1.3rem",
  height: "1.3rem",
};
const menu_icon_props = {
  width: "1rem",
  height: "1rem",
};
// ---------------------------------------------------------
const PriorityButton = ({ value, allowOpen, onChange }) => {
  const [open, setOpen] = useState(null);
  // ---------------------------------------------------------
  const handleOpen = (event) => {
    if (!allowOpen) {
      console.log("not allowed");
      return;
    }
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };
  const handleClick = (_option, _index) => {
    console.log(_option, _index);
    onChange(_option, _index);
    setOpen(null);
  };
  const getSelectedColor = () => {
    let [target] = TASK_PRIORITY_OPTIONS.filter((item) => item.value === value);
    if (target) {
      return target.color;
    }
    return "#969292";
  };
  const getTitle = () => {
    let [target] = TASK_PRIORITY_OPTIONS.filter((item) => item.value === value);
    if (target) {
      return "Priority" + " " + target?.label;
    }
    return "Priority";
  };
  // ---------------------------------------------------------
  return (
    <div>
      <Tooltip title={getTitle()}>
        <div
          className="sidebar-button d-flex align-items-center"
          style={{ minHeight: "36px" }}
          onClick={handleOpen}
        >
          {TASK_PRIORITY_OPTIONS.find((option) => option.value == value).label}
          <IconButton
            sx={{
              bgcolor: (theme) => theme.palette.action.hover,
              width: "1.3rem",
              height: "1.3rem",
              ml: 1,
            }}
            size="small"
          >
            <Iconify
              sx={{ width: "1rem", height: "1rem", color: getSelectedColor() }}
              icon="carbon:flag-filled"
            />
          </IconButton>
        </div>
      </Tooltip>
      <Menu
        disableEnforceFocus
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{ mt: 1 }}
      >
        {TASK_PRIORITY_OPTIONS.map((option, index) => (
          <MenuItem
            key={index}
            selected={value === option.value}
            onClick={() => handleClick(option, index)}
            sx={{ typography: "body2", px: 1 }}
          >
            <Iconify
              sx={{ ...menu_icon_props, color: option.color, mr: 1 }}
              // icon='clarity:flag-solid'
              icon="carbon:flag-filled"
            />
            {option?.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default PriorityButton;
PriorityButton.propTypes = {
  value: PropTypes.number.isRequired,
  allowOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
