import { filter } from "lodash";
import { useSnackbar } from "notistack";
import { _generate_token } from "src/DAL";
export const handle_localSearch = (input, array) => {
  if (!array) {
    console.error("invalid params supplied to localSearch ");
    return [];
  }
  let filtered = filter(array, (_item) => {
    if (!_item.query_field) {
      console.error("no query_field in object");
      return [];
    }
    return _item.query_field.toLowerCase().indexOf(input.toLowerCase()) !== -1;
  });
  return filtered;
};
