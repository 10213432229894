import {
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { filter } from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { _delete_project_template, _project_template_list } from "src/DAL";
import { CircularLoader, MuiDialog, Page, SearchBar } from "src/components";
import TemplateCard from "./components/TemplateCard";
import { useAppContext } from "src/hooks";
import AddNewCard from "src/components/AddNewCard";
const MENU_OPTIONS = [
  {
    label: "Edit",
    action: "edit",
  },
  {
    label: "Delete",
    action: "delete",
  },
];
function ProjectTemplates() {
  const { dispatch_get_current_selected_item } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const matches_breakpoint = useMediaQuery("(max-width:858px)");
  const [searchValue, setSearchValue] = useState("");
  const [templatesList, setTemplatesList] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleLocalSearch = (input, array) => {
    let filtered = filter(
      array,
      (_item) => _item.title.toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
    return filtered;
  };
  const getFilteredList = (data) => {
    let filtered = handleLocalSearch(searchValue, data);
    return filtered;
  };
  const getTemplatesList = async () => {
    try {
      setListLoading(true);
      const result = await _project_template_list();
      if (result.code == 200) {
        setTemplatesList(result.project_template_list);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while geting template list");
    } finally {
      setListLoading(false);
    }
  };
  const handleEditTemplate = () => {
    const item = dispatch_get_current_selected_item();
    navigate(`/templates/edit-template/${item._id}`, {
      state: { template: item },
    });
  };
  const handleDeleteTemplate = async () => {
    const item = dispatch_get_current_selected_item();
    try {
      setDeleteLoading(true);
      const result = await _delete_project_template(item._id);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setTemplatesList((prev) =>
          prev.filter((template) => template._id !== item._id)
        );
        setDeleteDialogOpen(false);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while delete the project template");
    } finally {
      setDeleteLoading(false);
    }
  };
  const handleMenuItemClick = (option) => {
    const { action } = option;
    if (action == "edit") {
      handleEditTemplate();
    } else {
      setDeleteDialogOpen(true);
    }
  };
  useLayoutEffect(() => {
    getTemplatesList();
  }, []);
  return (
    <Page title="Workspaces">
      <Container maxWidth="xl">
        {listLoading && <CircularLoader />}
        <div className="mb-4">
          <Grid
            rowSpacing={matches_breakpoint ? 2 : 0}
            alignItems="center"
            container
            columnSpacing={2}
          >
            <Grid item>
              <Typography
                sx={{ fontSize: { xs: "27px", sm: "29px", md: "29px" } }}
                variant="h4"
              >
                Project Templates
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                marginLeft: { xs: "initial", sm: "auto" },
              }}
            >
              <Button
                variant="contained"
                onClick={() => navigate("/templates/create")}
              >
                New Template
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className="mb-4">
          <Stack
            direction="row"
            sx={{ justifyContent: { xs: "flex-start", sm: "flex-end" } }}
          >
            <SearchBar
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Stack>
        </div>
        <Grid container spacing={3}>
          {getFilteredList(templatesList).map((template, index) => {
            return (
              <Grid
                sx={{ position: "relative" }}
                key={index}
                item
                xs={12}
                sm={6}
                md={3}
              >
                <TemplateCard
                  item={template}
                  menuOptions={MENU_OPTIONS}
                  onClickMenuItem={handleMenuItemClick}
                />
              </Grid>
            );
          })}
          {templatesList == 0 && !listLoading && (
            <AddNewCard
              title="project template"
              handleClick={() => navigate("/templates/create")}
            />
          )}
        </Grid>
      </Container>
      <MuiDialog
        title="Delete Template"
        message="Are you sure you want to delete this project template?"
        open={deleteDialogOpen}
        onToggle={(val) => setDeleteDialogOpen(val)}
        loading={deleteLoading}
        onAgree={handleDeleteTemplate}
      />
    </Page>
  );
}

export default ProjectTemplates;
