import {
  Paper,
  Box,
  Stack,
  Avatar,
  Typography,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { Iconify, Label } from "src/components";
import { s3baseUrl } from "src/config";
import { get_zone_date } from "src/utils";

// ---------------------------------------------------------
const CommentCard = ({ item, onEdit, onDel, currentUserRole, disabled }) => {
  const {
    comment_title,
    comment_type,
    createdAt,
    creator_type,
    comment_creator,
    creator,
  } = item;
  const { first_name, last_name, image } = creator;
  // creator has allow_add_project,allow_drag_drop,role,user for uid,user_status
  const [open, setOpen] = useState(null);
  const parsedContent = (html) => {
    const parser = new DOMParser();
    const parsedContent = parser.parseFromString(html, "text/html");
    const text = parsedContent.body.textContent;
    return text;
  };
  // ---------------------------------------------------------
  const handleClose = () => {
    setOpen(null);
  };
  const handleOpen = (e) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }
    setOpen(e.currentTarget);
  };
  const handleClick = (e, action) => {
    e.stopPropagation();
    setOpen(null);
    if (action === "edit") {
      onEdit(item);
    }
    if (action === "delete") {
      onDel(item);
    }
  };
  const getCommentTime = () => {
    let now = moment(createdAt).fromNow();
    return now;
  };
  const canEditComment = () => {
    if (comment_creator === true) {
      return true;
    }
    // if (String(currentUserRole) === "admin") {
    //   return true;
    // }
    // if (String(currentUserRole) === "manager") {
    //   return true;
    // }

    return false;
  };

  // ---------------------------------------------------------
  return (
    <Paper
      sx={{ p: 1 }}
      className="border"
      onClick={() => console.log(item)}
      elevation={1}
    >
      <Stack className="relative" spacing={1} direction="column">
        <Stack direction="row" spacing={1}>
          <Box alignItems="center" justifyContent="center">
            {image ? (
              <Avatar src={s3baseUrl + image} />
            ) : (
              // <Avatar src={s3baseUrl + image} />
              <Avatar>{first_name.substring(0, 2)}</Avatar>
            )}
          </Box>
          <Typography
            sx={{
              alignSelf: "center",
              ml: 1,
            }}
            fontWeight="500"
          >
            {first_name + " " + last_name}
          </Typography>
          <Stack
            alignItems="center"
            className="comment-option-bar"
            direction="row"
            spacing={1}
          >
            <Label
              color={String(comment_type) === "0" ? "info" : "error"}
              variant="ghost"
            >
              {String(comment_type) === "0" ? "Public" : "Private"}
            </Label>
            <Typography variant="caption">{getCommentTime()}</Typography>
            {canEditComment() && (
              <IconButton
                disabled={disabled}
                onClick={handleOpen}
                sx={{ right: "3px", p: 0.5 }}
              >
                <Iconify icon="bi:three-dots-vertical" />
              </IconButton>
            )}
          </Stack>
        </Stack>
        <Stack>
          {/* <Typography
            sx={{ alignSelf: "center", width: 1, whiteSpace: "pre-wrap" }}
          > */}
          {/* {parsedContent(comment_title)} */}
          <div
            dangerouslySetInnerHTML={{ __html: comment_title }}
            style={{
              wordWrap: "break-word",
              wordBreak: "break-word",
            }}
          ></div>
          {/* </Typography> */}
        </Stack>
      </Stack>
      <Menu
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          p: 0.5,
          mt: 1,
        }}
      >
        <MenuItem
          // selected={isUserSelected(user)}
          onClick={(e) => handleClick(e, "edit")}
          sx={{
            typography: "body2",
            px: 1,
            mx: 0.5,
            borderRadius: 0.5,
            mr: 0,
            marginInline: "3px",
            width: "100%",
            marginTop: "1px",
            marginBottom: "1px",
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          // selected={isUserSelected(user)}
          onClick={(e) => handleClick(e, "delete")}
          sx={{
            typography: "body2",
            px: 1,
            mx: 0.5,
            borderRadius: 0.5,
            mr: 0,
            marginInline: "3px",
            width: "100%",
            marginTop: "1px",
            marginBottom: "1px",
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </Paper>
  );
};

export default CommentCard;
