import { LoadingButton } from "@mui/lab";
import { useLocation } from "react-router-dom";
import {
  Avatar,
  Container,
  IconButton,
  Button,
  Stack,
  TextField,
  Typography,
  Tooltip,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  CircularLoader,
  Iconify,
  MuiDialog,
  NavBackHeader,
  Page,
  RichEditor,
} from "src/components";
import { useSnackbar } from "notistack";
import { ALLOWED_IMAGE_TYPES } from "src/constants";
import { useParams, useNavigate } from "react-router-dom";
import {
  _add_custom_status,
  _delete_custom_status,
  _edit_user_workspace,
  _user_workspace_detail,
} from "src/DAL";
import { s3baseUrl } from "src/config";
import {
  del_from_local_storage,
  get_from_localStorage,
  set_to_localStorage,
  upload_image,
} from "src/utils";
import ImageSelector from "src/components/ImageSelector";
import {
  CUSTOM_TASK_STATUS_COLOR,
  TASK_STATUS_OPTIONS,
} from "src/constants/constants";
// ---------------------------------------------------------
const common_button_size = "5rem";
// ---------------------------------------------------------
const EditWorkspace = () => {
  const { state } = useLocation();
  console.log(state, "asdwajkd");
  const navigate = useNavigate();
  const [formInputs, setFormInputs] = useState({
    name: state.item.name,
    description: state.item.description,
    image: state.item.image,
    status: state.item.status,
  });
  const { workspace_id } = useParams();
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const editorRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  // ----------------------------------
  // const getWorkspaceDetail = async () => {
  //   const result = await _user_workspace_detail(null, workspace_id);
  //   if (result.code === 200) {
  //     // setWorkspace(result.workspace)
  //     console.log(result, 'res');
  //     const { workspace } = result;
  //     console.log(workspace, 'wrk');
  //     setFormInputs({
  //       ...formInputs,
  //       ['name']: workspace.name,
  //       ['description']: workspace.description,
  //       ['image']: workspace.image ? workspace.image : '',
  //     });
  //     setLoadingData(false);
  //   } else {
  //     setLoadingData(false);
  //     enqueueSnackbar(result.message, { variant: 'error' });
  //   }
  // };
  //////////============Status===================////////////////
  const [newStatus, setNewStatus] = useState({
    title: "",
    color: "",
  });
  const [editStatus, setEditStatus] = useState();
  const [editIndex, setEditIndex] = useState();
  const [addStatusDialogOpen, setAddStatusDialogOpen] = useState(false);
  const [editStatusDialogOpen, setEditStatusDialogOpen] = useState(false);
  const [deleteStatusDialogOpen, setDeleteStatusDialogOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const [alternateStatus, setAlternateStatus] = useState(5);
  const [deleteCustomStatusLoading, setDeleteCustomStatusLoading] =
    useState(false);
  const [addStatusloading, setAddStatusloading] = useState(false);
  const closeNewStatusDialog = () => {
    setAddStatusDialogOpen(false);
    setNewStatus({
      title: "",
      color: "",
    });
  };
  const closeEditStatusDialog = () => {
    setEditStatusDialogOpen(false);
    setEditStatus();
    setEditIndex();
  };
  const [customStatus, setCustomStatus] = useState(
    get_from_localStorage("customStatus")
      ? get_from_localStorage("customStatus")
      : state?.item?.extra_status
  );
  const addStatus = async () => {
    if (!newStatus.title) {
      enqueueSnackbar("Please add title for the status", { variant: "error" });
      return;
    }
    if (!newStatus.color) {
      enqueueSnackbar("Please choose color for the status", {
        variant: "error",
      });
      return;
    }
    try {
      setAddStatusloading(true);
      const req = {
        workspace_id: state.item._id,
        extra_status: [
          ...customStatus,
          {
            color_code: newStatus.color.color_code,
            title: newStatus.title,
            is_custom: true,
            is_default: false,
            color_id: newStatus.color.color_id,
            value: newStatus.color.value,
            color: newStatus.color,
          },
        ].map((status) => {
          let temp = status;
          delete temp.color;
          return temp;
        }),
      };
      const result = await _add_custom_status(req);
      if (result.code == 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        setCustomStatus(req.extra_status);
        closeNewStatusDialog();
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while adding new custom status");
    } finally {
      setAddStatusloading(false);
    }
  };
  const deleteFromCustomStatus = (index) => {
    setDeleteIndex(index);
    setDeleteStatusDialogOpen(true);
    // customStatus.splice(index, 1);
    // setCustomStatus([...customStatus]);
  };
  const closeDeleteStatusDialog = () => {
    setDeleteStatusDialogOpen(false);
    setDeleteIndex();
  };
  const handleDeleteCustomStatus = async () => {
    try {
      setDeleteCustomStatusLoading(true);
      const req = {
        workspace_id: state.item._id,
        extra_status: customStatus.filter(
          (status) => status.value !== customStatus[deleteIndex].value
        ),
        old_status: customStatus[deleteIndex].value.toString(),
        new_status: alternateStatus.toString(),
      };
      const result = await _delete_custom_status(req);
      if (result.code == 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        setCustomStatus((prev) =>
          prev.filter((status) => status.value !== prev[deleteIndex].value)
        );
        closeDeleteStatusDialog();
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while deleting custom status");
    } finally {
      setDeleteCustomStatusLoading(false);
    }
  };
  const editFromCustomStatus = (index) => {
    console.log(customStatus[index], "AScaschasjkchas");
    setEditStatus({
      ...customStatus[index],
    });
    setEditStatusDialogOpen(true);
    setEditIndex(index);
  };
  const handleEditStatus = () => {
    let temp = customStatus;
    temp[editIndex] = {
      color_code: editStatus.color.color_code,
      title: editStatus.title,
      is_custom: true,
      is_default: editIndex.is_default,
      color_id: editStatus.color.color_id,
      value: editIndex.value,
      color: editStatus.color,
    };
    setCustomStatus(temp);
    closeEditStatusDialog();
  };
  ////////////////////////////////////////////////////////////
  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const getImgSrc = (path) => {
    if (!path) {
      return path;
    }
    if (path.includes("blob:")) {
      /* try blob includes */
      console.log("returned path", path);
      return path;
    }
    console.log("returned path", s3baseUrl + path);
    return s3baseUrl + path;
  };
  const handleFileChange = async (e) => {
    let file = e.target.files[0];
    const { type } = file;
    let valid_type = false;
    console.log(file, "target-file");
    ALLOWED_IMAGE_TYPES.map((item, i) => {
      if (item === String(type)) {
        valid_type = true;
      }
    });
    if (!valid_type) {
      enqueueSnackbar("Please choose an image", { variant: "error" });
      return;
    }
    setFile(file);
    setFormInputs({ ...formInputs, ["image"]: URL.createObjectURL(file) });
    //
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let image = formInputs.image;
    setLoading(true);
    if (file) {
      console.log(file);
      const result = await upload_image(file);
      console.log(result, "upload-res");
      if (result.code === 200) {
        const { path } = result;
        image = path;
        setLoading(false);
      } else {
        setLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
        return;
      }
    }
    const data = {
      name: formInputs.name,
      description: formInputs.description,
      image: image,
      status: formInputs.status,
      // extra_status: customStatus.map((status) => {
      //   let temp = status;
      //   delete temp.color;
      //   return temp;
      // }),
    };
    const result = await _edit_user_workspace(data, workspace_id);
    if (result.code === 200) {
      console.log(result, "edit-res");
      setLoading(false);
      // let temp = get_from_localStorage("workspaces");
      // if (state.item.role === "admin") {
      //   let arr = temp.personal;
      //   arr.map((workspace, index) => {
      //     if (workspace._id == state.item._id) {
      //       workspace = {
      //         ...workspace,
      //         name: result.workspace.name,
      //         slug: result.workspace.slug,
      //         description: result.workspace.description,
      //         image: result.workspace.image,
      //       };
      //       arr[index] = workspace;
      //     }
      //   });
      //   temp = { ...temp, personal: arr };
      // }
      enqueueSnackbar("Workspace updated successfully", { variant: "success" });
      // set_to_localStorage("workspaces", temp);
      navigate(-1);
    } else {
      setLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    set_to_localStorage("customStatus", customStatus);
    return () => {
      del_from_local_storage("customStatus");
    };
  }, [customStatus]);
  // ----------------------------------
  // useLayoutEffect(() => {
  //   getWorkspaceDetail();
  // }, []);
  // ----------------------------------
  // if (loadingData) {
  //   return (
  //     <Page title='Edit Workspace'>
  //       <CircularLoader />;
  //     </Page>
  //   );
  // }
  return (
    <Page title="Edit Workspace">
      <Container maxWidth="xl">
        <div className="mb-4">
          <NavBackHeader heading="Edit Your Workspace" />
        </div>
        <Container maxWidth="sm">
          <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {/* {formInputs.image ? (
                <div className="centered-row">
                  <img
                    width="50%"
                    height="50%"
                    src={getImgSrc(formInputs.image)}
                    alt="user-image"
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    height: "58px",
                    border: "dashed 1px rgb(113,128,141)",
                    borderRadius: "7px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{ color: "rgb(113,128,141)" }}
                    textAlign="center"
                  >
                    Please choose an image. (optional)
                  </Typography>
                </div>
              )}
              <div className="centered-row">
                <label htmlFor="icon-button-file">
                  <input
                    className="d-none"
                    accept="image/png,image/jpg,image/jpeg"
                    id="icon-button-file"
                    type="file"
                    value=""
                    onChange={handleFileChange}
                  />
                  <Button
                    sx={{ width: common_button_size }}
                    variant="contained"
                    aria-label="upload picture"
                    component="span"
                    // disabled={loading}
                  >
                    Upload
                  </Button>
                </label>
              </div>
              <div className="centered-col d-none">
                <div className="relative">
                  <Avatar
                    sx={{ width: "13rem", height: "13rem" }}
                    src={getImgSrc(formInputs.image)}
                    alt="user-image"
                  />
                  <div
                    role="button"
                    style={{ right: "24px" }}
                    className="img-upload-btn"
                  >
                    <Tooltip placement="top" title="upload picture">
                      <label htmlFor="icon-button-file">
                        <input
                          className="d-none"
                          accept="image/png,image/jpg,image/jpeg"
                          id="icon-button-file"
                          type="file"
                          value=""
                          onChange={handleFileChange}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <Iconify
                            sx={{ width: "1rem", height: "1rem" }}
                            icon="fluent:camera-add-24-filled"
                          />
                        </IconButton>
                      </label>
                    </Tooltip>
                  </div>
                </div>
              </div> */}
              <div>
                <TextField
                  value={formInputs.name}
                  onChange={handleChange}
                  name="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  required
                  autoFocus
                />
              </div>
              <div className="d-flex align-items-center">
                <FormControlLabel
                  required
                  control={
                    <Switch
                      checked={formInputs.status}
                      onChange={(e) =>
                        setFormInputs((prev) => {
                          return { ...prev, status: e.target.checked };
                        })
                      }
                    />
                  }
                  label={formInputs.status ? "Active" : "Inactive"}
                />
              </div>
              <ImageSelector
                setFile={setFile}
                currentImage={formInputs.image && s3baseUrl + formInputs.image}
              />

              {/* <div className="d-flex justify-content-between align-items-center">
                <Typography variant="subtitle" fontWeight={"bold"}>
                  Custom Task Status
                </Typography>
                {customStatus.length < 5 && (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setAddStatusDialogOpen(true)}
                  >
                    Add status
                  </Button>
                )}
              </div>
              {customStatus != 0 && (
                <div>
                  <Container>
                    <Card>
                      <CardContent>
                        <List>
                          {customStatus.map((status, index) => {
                            return (
                              <ListItem key={index}>
                                <ListItemButton>
                                  <div className="d-flex justify-content-between align-items-center w-100">
                                    <div className="d-flex align-items-center">
                                      <div
                                        style={{
                                          height: "20px",
                                          width: "20px",
                                          backgroundColor: status.color_code,
                                          borderRadius: "50%",
                                          marginRight: "8px",
                                        }}
                                      />
                                      <Typography variant="subtitle">
                                        {status.title}
                                      </Typography>
                                    </div>

                                    <div className="d-flex justify-content-end">
                                      <IconButton
                                        onClick={() =>
                                          deleteFromCustomStatus(index)
                                        }
                                      >
                                        <Iconify icon="ic:outline-delete" />
                                      </IconButton>
                                    </div>
                                  </div>
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                        </List>
                      </CardContent>
                    </Card>
                  </Container>
                </div>
              )} */}
              <div>
                <RichEditor
                  editorRef={editorRef}
                  value={formInputs.description}
                  setValue={(val) =>
                    setFormInputs({
                      ...formInputs,
                      description: val,
                    })
                  }
                />
              </div>

              <div className="centered-row">
                <LoadingButton
                  sx={{ width: common_button_size }}
                  type="submit"
                  variant="contained"
                  loading={loading}
                >
                  Save
                </LoadingButton>
              </div>
            </Stack>
          </Box>
        </Container>
      </Container>
      <MuiDialog
        open={addStatusDialogOpen}
        loading={addStatusloading}
        onToggle={closeNewStatusDialog}
        title="Add Custom Status"
        message="Add a custom status for your tasks"
        onAgree={addStatus}
        ExtraComponent={
          <>
            <TextField
              name="title"
              value={newStatus.title}
              onChange={(e) =>
                setNewStatus((prev) => {
                  return { ...prev, title: e.target.value };
                })
              }
              label="Status title"
            />
            <FormControl fullWidth>
              <InputLabel id="colorSelector">Select Color</InputLabel>
              <Select
                value={newStatus.color}
                label="Select Color"
                id="colorSelector"
                onChange={(e) =>
                  setNewStatus((prev) => {
                    return { ...prev, color: e.target.value };
                  })
                }
              >
                {CUSTOM_TASK_STATUS_COLOR.filter(
                  (obj1) =>
                    !customStatus.some(
                      (obj2) => obj2.color_code === obj1.color_code
                    )
                ).map((color, index) => {
                  return (
                    <MenuItem key={index} value={color}>
                      <div className="d-flex align-items-center">
                        <div
                          style={{
                            height: "15px",
                            width: "15px",
                            backgroundColor: color.color_code,
                          }}
                        />
                        <Typography sx={{ ml: 2 }}>
                          {color.color_name}
                        </Typography>
                      </div>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </>
        }
      />
      <MuiDialog
        open={editStatusDialogOpen}
        onToggle={closeEditStatusDialog}
        onAgree={handleEditStatus}
        title="Edit Status"
        message="Do you want to edit this status"
        ExtraComponent={
          editStatus && (
            <>
              <TextField
                name="title"
                value={editStatus.title}
                onChange={(e) =>
                  setEditStatus((prev) => {
                    return { ...prev, title: e.target.value };
                  })
                }
                label="Status title"
              />
              <FormControl fullWidth>
                <InputLabel id="colorSelector">Select Color</InputLabel>

                <Select
                  value={editStatus?.color}
                  label="Select Color"
                  id="colorSelector"
                  onMouseOver={(e) =>
                    console.log(editStatus?.color, "dfjjsdjfkasd")
                  }
                  onChange={(e) => {
                    console.log(editStatus?.color, "dfkjsdkl");
                    setEditStatus((prev) => {
                      return { ...prev, color: e.target.value };
                    });
                  }}
                >
                  {CUSTOM_TASK_STATUS_COLOR.filter(
                    (obj1) =>
                      !customStatus.some(
                        (obj2) =>
                          obj2?.color_code === obj1?.color_code &&
                          obj1?.color_code !== editStatus?.color_code
                      )
                  ).map((color) => {
                    {
                      console.log(editStatus.color, "acascascascas", color);
                    }
                    return (
                      <MenuItem key={color?.value} value={color}>
                        <div className="d-flex align-items-center">
                          <div
                            style={{
                              height: "15px",
                              width: "15px",
                              backgroundColor: color?.color_code,
                            }}
                          />
                          <Typography sx={{ ml: 2 }}>
                            {color?.color_name}
                          </Typography>
                        </div>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </>
          )
        }
      />
      {deleteIndex >= 0 && (
        <MuiDialog
          open={deleteStatusDialogOpen}
          loading={deleteCustomStatusLoading}
          onAgree={handleDeleteCustomStatus}
          onToggle={closeDeleteStatusDialog}
          title="Delete Status"
          message="Do you want to delete this custom task status from workspace?"
          ExtraComponent={
            <>
              <FormControl fullWidth>
                <InputLabel id="statusSelector">
                  Select status to replace the deleted status
                </InputLabel>
                <Select
                  id="statusSelector"
                  value={alternateStatus}
                  onChange={(e) => setAlternateStatus(e.target.value)}
                  label="Select status to replace the deleted status"
                >
                  {TASK_STATUS_OPTIONS.concat(
                    customStatus.map((status) => {
                      return {
                        label: status.title,
                        value: status.value,
                        color: status.color_code,
                      };
                    })
                  )
                    .filter(
                      (status) =>
                        status.value !== customStatus[deleteIndex].value
                    )
                    .map((status) => (
                      <MenuItem value={status.value} key={status.color}>
                        <div className="d-flex align-items-center">
                          <div
                            style={{
                              height: "15px",
                              width: "15px",
                              backgroundColor: status.color,
                            }}
                          />
                          <Typography sx={{ ml: 1 }}>{status.label}</Typography>
                        </div>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </>
          }
        />
      )}
    </Page>
  );
};

export default EditWorkspace;
