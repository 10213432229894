import {
  Button,
  Card,
  CircularProgress,
  Container,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Iconify, Label, NoData, Page } from "src/components";
import { _subscribtion_history } from "src/DAL";

const columns = [
  { id: "plan", label: "Plan" },
  { id: "amount", label: "Amount" },
  { id: "date", label: "Date" },
  {
    id: "time",
    label: "Time",
  },
  {
    id: "status",
    label: "Status",
  },
];

function Transactions() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState([]);
  console.log(rows, "transactions");
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const getMyTransactions = async () => {
    setLoading(true);
    setIsLoading(true);
    try {
      const result = await _subscribtion_history();
      if (result.code == 200) {
        setLoading(false);
        setIsLoading(false);
        setRows(result.subscription_history);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while fetching my redeems");
    } finally {
      setLoading(false);
      setIsLoading(false);
    }
  };

  useLayoutEffect(() => {
    getMyTransactions();
  }, []);

  return (
    <>
      <Page title="Transactions">
        {isLoading ? (
          <div
            style={{ minHeight: "100vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Container maxWidth="xl">
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <div className="d-flex align-items-center">
                  <IconButton onClick={() => navigate(-1)}>
                    <Iconify icon="ion:arrow-back" />
                  </IconButton>
                  <Typography variant="h4">Transactions</Typography>
                </div>
              </Stack>

              {rows.length > 0 && (
                <Card sx={{ mt: 2 }}>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.code}
                              >
                                <TableCell>{row.name}</TableCell>
                                <TableCell>
                                  {row.currency_symbol
                                    ? row.currency_symbol
                                    : "$"}
                                  {row.price ? row.price : "0"}
                                </TableCell>
                                <TableCell>
                                  {moment(row.createdAt).format("DD-MMM-YYYY")}
                                </TableCell>
                                <TableCell>
                                  {moment(row.createdAt).format("LT")}
                                </TableCell>
                                <TableCell>
                                  <Label
                                    variant="ghost"
                                    color={
                                      row.status == "success"
                                        ? "success"
                                        : "error"
                                    }
                                  >
                                    {row.status}
                                  </Label>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Card>
              )}
              <NoData dataLength={rows.length} loading={loading} />
            </Container>
          </>
        )}
      </Page>
    </>
  );
}

export default Transactions;
