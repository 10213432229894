import { get_from_localStorage, invokeApi } from "src/utils";
export const _get_reports_for_invoice = (data) => {
  const reqObj = {
    method: "POST",
    path: `api/report/client_report_list_with_date`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(reqObj);
};

export const _add_invoice = (data) => {
  const reqObj = {
    method: "POST",
    path: `api/invoice/add_invoice`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(reqObj);
};

export const _invoice_list = () => {
  const reqObj = {
    method: "GET",
    path: `api/invoice/invoice_list`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(reqObj);
};

export const _invoice_list_as_client = (id) => {
  const reqObj = {
    method: "GET",
    path: `api/invoice/invoice_list_for_cliet/${id}`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(reqObj);
};

export const _delete_invoice = (id) => {
  const reqObj = {
    method: "DELETE",
    path: `api/invoice/delete_invoice/${id}`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(reqObj);
};

export const _save_as_draft = (data) => {
  const reqObj = {
    method: "POST",
    path: `api/invoice/add_invoice_as_draft`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(reqObj);
};

export const _update_invoice_status = (data) => {
  const reqObj = {
    method: "POST",
    path: `api/invoice/update_invoice_status`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(reqObj);
};

export const _get_invoice_detail = (id) => {
  const reqObj = {
    method: "GET",
    path: `api/invoice/invoice_detail/${id}`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(reqObj);
};
