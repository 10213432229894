import { LoadingButton } from "@mui/lab";
import { Button, IconButton, Menu, TextField } from "@mui/material";
import React, { useState } from "react";
import { _add_task_checklist } from "src/DAL/taskDetailDrawer";
import Iconify from "src/components/Iconify";

function CheckList({ task, setTask, setTasks, canEditTask }) {
  const [open, setOpen] = useState();
  const [newCheckListName, setNewCheckListName] = useState("My List");
  const [addLoading, setAddLoading] = useState(false);
  const handleOpen = (e) => {
    setOpen(e.target);
  };
  const handleClose = () => {
    setOpen();
    setNewCheckListName("My List");
  };
  const handleAdd = async () => {
    try {
      setAddLoading(true);
      const req_data = {
        task_id: task._id,
        name: newCheckListName,
      };
      const result = await _add_task_checklist(req_data);
      if (result.code == 200) {
        setTask(result.task_data);
        setTasks(
          result.task_data.section_id,
          result.task_data._id,
          result.task_data
        );
        handleClose();
      }
    } catch (error) {
      console.log(error, " catched while adding checklist");
    } finally {
      setAddLoading(false);
    }
  };
  return (
    <div>
      <div
        className="sidebar-button d-flex align-items-center"
        onClick={(e) => {
          canEditTask && handleOpen(e);
        }}
      >
        CheckList
        <IconButton
          sx={{ bgcolor: (theme) => theme.palette.action.hover }}
          size="small"
        >
          <Iconify
            sx={{ width: "24px", height: "24px" }}
            icon="basil:checked-box-outline"
          />
        </IconButton>
      </div>
      <Menu open={Boolean(open)} onClose={handleClose} anchorEl={open}>
        <div className="p-3">
          <div>
            <TextField
              size="small"
              label="Title"
              value={newCheckListName}
              onKeyDown={(e) => e.stopPropagation()}
              onChange={(e) => {
                setNewCheckListName(e.target.value);
              }}
            />
          </div>
          <LoadingButton
            loading={addLoading}
            sx={{ mt: 1 }}
            variant="contained"
            onClick={handleAdd}
          >
            Add
          </LoadingButton>
        </div>
      </Menu>
    </div>
  );
}

export default CheckList;
