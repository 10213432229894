import {
  Container,
  Typography,
  Stack,
  Avatar,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Tooltip,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material';
import { s3baseUrl } from 'src/config/config';
import React, { useState } from 'react';
import { Page, RichEditor } from 'src/components';
import { useAppContext } from 'src/hooks';
import { Iconify } from 'src/components';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
const Profile = () => {
  const { dispatch_get_user_profile } = useAppContext();
  const navigate = useNavigate();
  const user = dispatch_get_user_profile();

  console.log(user);
  // -------------------------------------------------
  const handleChange = (e) => {
    console.log(e);
  };
  // -------------------------------------------------
  return (
    <Page title='Profile'>
      <Container maxWidth='xl'>
        <Stack flexWrap='wrap' direction='row' className='mb-4 '>
          <Typography variant='h4'>Profile</Typography>
        </Stack>
        <Container maxWidth='sm'>
          <Stack direction='column' className='ms-auto' spacing={3}>
            <div style={{ flexDirection: 'column' }} className='centered-row'>
              {user.image ? (
                <Avatar
                  sx={{ width: '7rem', height: '7rem' }}
                  src={s3baseUrl + user.image}
                  alt='user-image'
                />
              ) : (
                <Avatar
                  sx={{
                    width: '7rem',
                    height: '7rem',
                    fontSize: '3rem',
                    background: (theme) => theme.palette.primary.main,
                  }}
                >
                  {user.first_name.charAt(0)}
                </Avatar>
              )}
              <Typography sx={{ marginTop: '3px' }} fontWeight='bold'>
                {user.first_name + ' ' + user.last_name}{' '}
              </Typography>
              <Typography> {user.email} </Typography>
            </div>
            <div className='centered-col' style={{ alignItems: 'flex-start' }}>
              <Typography fontWeight='bold'>Bio</Typography>
              <Typography className='pre-wrap'>
                {user.biography ? user.biography : 'No Bio added !'}
              </Typography>
            </div>
            <Stack direction='row' alignItems='center' spacing={1} justifyContent='start'>
              {/* <div className='centered-col'> */}
              <Typography
                // noWrap
                fontWeight='bold'
                onClick={() => null}
                component='span'
              >
                First Name
              </Typography>
              <Typography noWrap onClick={() => null} component='span'>
                {user.first_name}
              </Typography>
              {/* </div> */}
            </Stack>
            <Stack direction='row' alignItems='center' spacing={1} justifyContent='start'>
              {/* <div className='centered-col'> */}
              <Typography
                // noWrap
                fontWeight='bold'
                onClick={() => null}
                component='span'
              >
                Last Name
              </Typography>
              <Typography noWrap onClick={() => null} component='span'>
                {user.last_name}
              </Typography>
              {/* </div> */}
            </Stack>
            <Stack direction='row' alignItems='center' spacing={1} justifyContent='start'>
              <Typography
                // noWrap
                fontWeight='bold'
                onClick={() => null}
                component='span'
              >
                Phone
              </Typography>
              <Typography noWrap onClick={() => null} component='span'>
                {user.contact_number}
              </Typography>
            </Stack>
            <Stack direction='row' alignItems='center' spacing={1} justifyContent='start'>
              <Typography
                // noWrap
                fontWeight='bold'
                onClick={() => null}
                component='span'
              >
                Gender
              </Typography>
              <Typography noWrap onClick={() => null} component='span'>
                {user.gender}
              </Typography>
            </Stack>

            <div className='centered-row'>
              <Button
                sx={{ mb: 3 }}
                onClick={() => navigate('/profile/edit-profile')}
                variant='contained'
              >
                Edit Profile
              </Button>
            </div>
          </Stack>
        </Container>
      </Container>
    </Page>
  );
};

export default Profile;
