import { useState } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import Iconify from "./Iconify";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { ALLOWED_IMAGE_TYPES } from "src/constants";
import { useEffect } from "react";
const ImageSelector = ({
  file,
  setFile,
  currentImage,
  removeCurrentImage = () => {},
  placeHolder = "Choose or Drop Image (optional)",
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [previewUrl, setPreviewUrl] = useState(currentImage ?? "");
  const previewImage = (file) => {
    if (file) {
      const { type, size } = file;
      let valid_type = false;
      ALLOWED_IMAGE_TYPES.map((item, i) => {
        if (item === String(type)) {
          valid_type = true;
        }
      });
      if (!valid_type) {
        enqueueSnackbar("Please choose an image", { variant: "error" });
        setFile();
        return;
      }
      if (size > 1048576) {
        enqueueSnackbar("Please select Image upto size of 1mb", {
          variant: "warning",
        });
        setFile();
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
      setFile(file);
    }
  };
  const onDrop = (files) => {
    const file = files[0];
    previewImage(file);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [],
      "image/jpeg": [],
      "image/jpg": [],
    }, // Specify accepted file types (e.g., images)
    // noClick: true,
    noKeyboard: true,
    height: "100%",
    width: "100%",
  });
  useEffect(() => {
    if (file) {
      previewImage(file);
    }
  }, [file]);
  return (
    <div>
      {/* {({ getRootProps, getInputProps }) => ( */}
      <section>
        {!previewUrl && (
          <div {...getRootProps()} className="d-flex justify-content-center">
            <input {...getInputProps()} />
            <Tooltip title="Select Image">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  height: "150px",
                  width: "100%",
                  border: "1px dashed black",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                <div>
                  <div className="d-flex justify-content-center">
                    <Iconify
                      icon="iconoir:add-media-image"
                      sx={{ width: 50, height: 50, color: "#b5b3b3" }}
                    />
                  </div>
                  <Typography>{placeHolder}</Typography>
                </div>
              </div>
            </Tooltip>
          </div>
        )}
        {previewUrl && (
          <div className="d-flex justify-content-center">
            <div
              style={{ position: "relative", height: "160px", width: "160px" }}
            >
              <img
                src={previewUrl}
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  height: "140px",
                  width: "140px",
                  objectFit: "contain",
                }}
              />
              <div style={{ position: "absolute", top: 0, right: 0 }}>
                <Tooltip title="Remove this image">
                  {/* <IconButton> */}
                  <Iconify
                    onClick={() => {
                      setPreviewUrl("");
                      setFile();
                      removeCurrentImage();
                    }}
                    sx={{ color: "red", height: "20px", width: "20px" }}
                    icon="gridicons:cross-circle"
                  />
                  {/* </IconButton> */}
                </Tooltip>
              </div>
            </div>
          </div>
        )}
      </section>
      {/* )} */}
    </div>
  );
};
export default ImageSelector;
