import { get_from_localStorage, invokeApi } from "src/utils";
export const _create_project_template = (data) => {
  const requestObj = {
    path: `api/project_template/add_project_template`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_project_template_detail = (id) => {
  const requestObj = {
    path: `api/project_template/project_template_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _project_template_list = () => {
  const requestObj = {
    path: `api/project_template/project_template_list`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _edit_project_template = (id, data) => {
  const requestObj = {
    path: `api/project_template/update_project_template/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _delete_project_template = (id) => {
  const requestObj = {
    path: `api/project_template/delete_project_template/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};
