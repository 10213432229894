import { Card, CircularProgress, Container, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Iconify, Page } from "src/components";
import PaymentBanner from "./PaymentBanner";
import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import {
  _confirm_onetime_payment,
  _confirm_stripe_payment,
  _get_active_plans_detail,
  _stripe_payment,
} from "src/DAL/plans";
import { useSnackbar } from "notistack";
import { result } from "lodash";
import { useAppContext } from "src/hooks";
import PaymentWrapper from "../PaymentWrapper/PaymentWrapper";

function PlanDetail() {
  const { state } = useLocation();
  const stripe = useStripe();
  const elements = useElements();
  console.log(state, "stateeeeeeeeeeeeeee");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [activePlanDetail, setActivePlanDetail] = useState({});
  console.log(activePlanDetail, "plaannssDetail");
  const [openModal, setOpenModal] = useState(false);
  const { dispatch_get_user_profile, dispatch_set_user_profile } =
    useAppContext();
  const user = dispatch_get_user_profile();
  console.log(user, "uuserrDetailllllllllllll");

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // useEffect(() => {
  //   console.log("Stripeeeee", stripe);
  //   console.log("Elementssssssss", elements);
  // }, [stripe, elements]);

  const getPlanDetail = async () => {
    setIsLoading(true);
    try {
      const response = await _get_active_plans_detail(state);
      if (response.code === 200) {
        setActivePlanDetail(response?.subscription_plan);
        setIsLoading(false);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.error("Error fetching plan details:", error);
      enqueueSnackbar("Failed to fetch plan details", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (elements == null) {
      return;
    }
    setIsLoadingBtn(true);
    const cardElement = elements.getElement(CardElement);
    console.log(cardElement, stripe, "elements");
    stripe
      .createToken(cardElement)
      .then((payload) => {
        console.log("[token]", payload);
        if (payload.error) {
          enqueueSnackbar(payload.error.message, { variant: "error" });
        }
        console.log(payload, "stripetoken");
        planPayment(payload.token.id, cardElement);
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingBtn(false);
      });
  };

  const payNowFromSavedCard = async (e) => {
    e.preventDefault();
    if (elements == null) {
      return;
    }
    setIsLoadingBtn(true);
    const id = activePlanDetail?._id;
    console.log(id, "idddddddddd");
    const reqObj = {
      plan_id: activePlanDetail?._id,
    };
    console.log(reqObj, "postdatatata");
    const response = await _stripe_payment(reqObj);
    if (response.code === 200) {
      let user_data = {
        ...response.customer,
        subscription_plan_id: response.payment_plan,
      };
      console.log(user_data, "user_data");
      dispatch_set_user_profile(user_data);
      setIsLoadingBtn(false);
      if (response.payment_plan.payment_access == "one_time") {
        const data = {
          plan_id: response?.payment_plan?._id,
          user_id: response?.customer?.user_id,
        };
        confirmOneTimePayment(data);
      } else {
        enqueueSnackbar("Payment succeeded successfully.", {
          variant: "success",
        });
        navigate("/dashboard");
      }
    } else if (response.code === 201) {
      const cardElement = elements.getElement(CardElement);
      handleSecureCard(
        response.client_secret,
        cardElement,
        response?.customer?.subscription_id
      );
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoadingBtn(false);
    }
  };

  // const handleSubmitFreeWithNoCard = async (event) => {
  //   console.log("freeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
  //   event.preventDefault();
  //   setIsLoadingBtn(true);
  //   const id = activePlanDetail?._id;
  //   console.log(id, "idddddddddd");
  //   const reqObj = {
  //     plan_id: activePlanDetail?._id,
  //   };
  //   console.log(reqObj, "postdatatata");
  //   const response = await _stripe_payment(reqObj);
  //   if (response.code === 200) {
  //     setIsLoadingBtn(false);
  //     enqueueSnackbar(response.message, {
  //       variant: "success",
  //     });
  //     navigate("/dashboard");
  //     setIsLoading(false);
  //   } else {
  //     enqueueSnackbar(response.message, { variant: "error" });
  //     setIsLoadingBtn(false);
  //   }
  // };

  const planPayment = async (token, cardElement) => {
    const id = activePlanDetail?._id;
    console.log(id, "idddddddddd");
    const reqObj = {
      plan_id: activePlanDetail?._id,
      token: token,
    };
    console.log(reqObj, "postdatatata");
    const response = await _stripe_payment(reqObj);
    console.log(response, "clienttsecretekey");
    if (response.code === 200) {
      let user_data = {
        ...response.customer,
        subscription_plan_id: response.payment_plan,
      };
      console.log(user_data, "user_data");
      dispatch_set_user_profile(user_data);
      setIsLoadingBtn(false);
      if (response.payment_plan.payment_access == "one_time") {
        const data = {
          plan_id: response?.payment_plan?._id,
          user_id: response?.customer?.user_id,
        };
        confirmOneTimePayment(data);
      } else {
        enqueueSnackbar("Payment succeeded successfully.", {
          variant: "success",
        });
      }
      navigate("/dashboard");
    } else if (response.code === 201) {
      handleSecureCard(
        response.client_secret,
        cardElement,
        response?.customer?.subscription_id
      );
    } else {
      setIsLoadingBtn(false);
      enqueueSnackbar(response.message, { variant: "error" });
      console.log(`Error add subscription api: ${response.message}`);
    }
  };

  const confirmOneTimePayment = async (postData) => {
    let resp = await _confirm_onetime_payment(postData);
    if (resp.code === 200) {
      enqueueSnackbar("Payment succeeded successfully", {
        variant: "success",
      });
      navigate("/dashboard");
      setIsLoadingBtn(false);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
      setIsLoadingBtn(false);
    }
  };

  const handleSecureCard = (client_secret, postData, activePlanDetail) => {
    enqueueSnackbar("Processing card...", {
      variant: "info",
    });
    stripe
      .confirmCardPayment(client_secret)
      .then(function (result) {
        if (result.error) {
          setIsLoadingBtn(false);
          enqueueSnackbar(result.error.message, { variant: "error" });
          return;
        }
        if (activePlanDetail?.payment_access === "one_time") {
          confirmOneTimePayment(postData);
        } else {
          enqueueSnackbar("Payment succeeded successfully", {
            variant: "success",
          });
          setIsLoadingBtn(false);
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
        setIsLoadingBtn(false);
      });
  };

  useEffect(() => {
    getPlanDetail();
  }, []);

  if (isLoading) {
    return (
      <div
        style={{ minHeight: "100vh" }}
        className="d-flex justify-content-center align-items-center"
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Page title="Payment Plans">
        {/* {isLoading ? (
          <div
            style={{ minHeight: "100vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            <CircularProgress />
          </div>
        ) : ( */}
        <>
          <Container maxWidth="xl">
            <div className="d-flex align-items-center">
              <IconButton onClick={() => navigate(-1)}>
                <Iconify icon="ion:arrow-back" />
              </IconButton>
            </div>
            <div className="col-12 mt-3 mb-3">
              <PaymentBanner activePlanDetail={activePlanDetail} />
            </div>
            <div className="container">
              <div className="row">
                <div
                  className="col-12 col-lg-6"
                  style={{
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  }}
                >
                  <div className="plan-list-item p-3">
                    <div className=" pb-2">
                      <strong>Whats Included:</strong>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: activePlanDetail?.detailed_description,
                      }}
                    ></div>
                  </div>
                </div>
                <div
                  className="col-12 mt-5 mt-lg-0 col-lg-6"
                  id="payment-form-wrapper"
                >
                  <div className="plan-list-item p-3 pb-0 mb-0">
                    <div
                      className={`d-flex justify-content-between align-items-center active`}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        {activePlanDetail?.name}
                      </span>
                      <div>
                        <span style={{ fontWeight: "bold" }}>
                          {activePlanDetail?.is_plan_free ? (
                            <>FREE</>
                          ) : (
                            <>
                              <span
                                style={{ fontWeight: "bold", color: "gray" }}
                              >
                                {`${
                                  activePlanDetail?.is_plan_free === false
                                    ? activePlanDetail?.currency_symbol
                                    : `${activePlanDetail?.currency_symbol}`
                                }${
                                  activePlanDetail?.is_plan_free === false
                                    ? activePlanDetail?.price
                                    : "0"
                                }/${activePlanDetail?.plan_type}`}
                              </span>
                            </>
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="plan-list-body">
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            // borderBottom: "1px solid gray",
                          }}
                        >
                          <div style={{ fontWeight: "bold" }}>Plan Type:</div>
                          <div>
                            <span style={{ fontWeight: "bold", color: "gray" }}>
                              {activePlanDetail?.plan_type}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        {!!activePlanDetail?.trial_period_days && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              // borderBottom: "1px solid gray",
                            }}
                          >
                            <div style={{ fontWeight: "bold" }}>
                              Free Trial:
                            </div>
                            <div>
                              <span
                                style={{ fontWeight: "bold", color: "gray" }}
                              >
                                {activePlanDetail?.trial_period_days} Days
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="px-3">
                    {user?.card_info &&
                    user?.card_info?.is_card_available === true ? (
                      <>
                        <form onSubmit={payNowFromSavedCard}>
                          <div className="row justify-content-center">
                            <div className="col-12 mt-3">
                              <div className="d-flex flex-column align-items-end w-100">
                                <Card
                                  className="m-0 w-100 mb-2 p-2 px-3"
                                  sx={{
                                    borderRadius: "2px",
                                    boxShadow:
                                      "0px 1px 3px 2px rgba(90, 114, 123, 0.1)",
                                  }}
                                >
                                  <div className="d-flex justify-content-between m-1">
                                    <div>
                                      <Icon
                                        icon={
                                          user?.card_info?.card_brand === "Visa"
                                            ? "logos:visa"
                                            : user?.card_info?.card_brand ===
                                              "MasterCard"
                                            ? "logos:mastercard"
                                            : user?.card_info?.card_brand ===
                                              "Discover"
                                            ? "logos:discover"
                                            : user?.card_info?.card_brand ===
                                              "UnionPay"
                                            ? "logos:unionpay"
                                            : user?.card_info?.card_brand ===
                                              "Diners Club"
                                            ? "fa-brands:cc-diners-club"
                                            : user?.card_info?.card_brand ===
                                              "American Express"
                                            ? "fontisto:american-express"
                                            : user?.card_info?.card_brand ===
                                              "JCB"
                                            ? "logos:jcb"
                                            : "fluent-mdl2:payment-card"
                                        }
                                        width={50}
                                        height={24}
                                      />
                                    </div>
                                    <div>
                                      **** **** ****{" "}
                                      {user?.card_info?.card_number}
                                    </div>
                                  </div>
                                </Card>
                                <div className="float-right mt-2">
                                  <span
                                    onClick={handleOpenModal}
                                    className="text-button"
                                    style={{
                                      fontSize: "11px",
                                      color: "#00AB55",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Change Card
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <LoadingButton
                                loading={isLoadingBtn}
                                variant="contained"
                                type="submit"
                                fullWidth
                                className="my-3"
                              >
                                Confirm Payment
                              </LoadingButton>
                            </div>
                          </div>
                        </form>
                      </>
                    ) : (
                      <>
                        <form onSubmit={handleSubmit}>
                          <div className="row justify-content-center">
                            <div className="col-12 mt-3">
                              <div className="payment-card-border p-3">
                                <CardElement
                                  options={{
                                    hidePostalCode: true,
                                    style: {
                                      base: {
                                        iconColor: "#000",
                                        color: "#000",
                                        borderColor: "#000",
                                        "::placeholder": {
                                          color: "#7e8080",
                                          textTransform: "capitalize",
                                        },
                                      },
                                    },
                                  }}
                                />
                              </div>
                              <LoadingButton
                                loading={isLoadingBtn}
                                variant="contained"
                                type="submit"
                                fullWidth
                                className="my-3"
                              >
                                Confirm Payment
                              </LoadingButton>
                            </div>
                          </div>
                        </form>
                      </>
                    )}
                    <div className="my-1">
                      <Icon icon="uis:lock" color="#00c292" className="me-2" />
                      <span>Encrypted and secure payments</span>
                    </div>
                    <div style={{ color: "#72777c" }} className="my-3">
                      <span>
                        By continuing you agree with our Terms of Service. We
                        will process your personal data for the fulfillment of
                        your order and other purposes as per our Privacy Policy.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </>
        <PaymentWrapper
          openModal={openModal}
          handleCloseModal={handleCloseModal}
        />
        {/* )} */}
      </Page>
    </>
  );
}

export default PlanDetail;
