import { useSnackbar } from "notistack";
import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useTransition,
} from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  CircularLoader,
  Iconify,
  MuiAutoComplete,
  MuiDialog,
  MuiDrawer,
  MuiMultiAutocomplete,
  MuiSelect,
  NavBackHeader,
  NoData,
  Page,
  SearchBar,
} from "src/components";
import {
  _cancel_workspace_invitation,
  _delete_workspace_project,
  _favourite_project,
  _remove_user_from_workspace,
  _resend_workspace_invitation,
  _update_project_team,
  _update_workspace_member,
  _user_workspace_detail,
  _workspace_favorite_projects_listing,
  _workspace_projects_listing,
  _workspace_team,
} from "src/DAL";
import {
  Container,
  Stack,
  IconButton,
  Skeleton,
  Typography,
  Grid,
  TextField,
  Button,
  Tooltip,
  Box,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tab,
  Divider,
  FormControl,
  FormLabel,
  Tabs,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import UserCard from "./components/UserCard";
import ProjectCard from "./components/ProjectCard";
import {
  get_from_localStorage,
  handle_localSearch,
  set_to_localStorage,
} from "src/utils";
import { useAppContext } from "src/hooks";
import ProjectUserCard from "./components/ProjectUserCard";
import { LoadingButton, TabContext, TabList } from "@mui/lab";
import { capitalCase, sentenceCase } from "change-case";
import AddNewCard from "src/components/AddNewCard";
import InviteMembersDialog from "src/components/InviteMembersDialog";
import InvitationCard from "./components/InvitationCard";
// ---------------------------------------------------------
const BUTTON_SIZE = { width: "9.8rem" };
const MENU_OPTIONS = [
  {
    label: "Edit",
    roles: ["admin", "manager"],
    action: "edit",
  },
  {
    label: "Delete",
    roles: ["admin", "manager"],
    action: "delete",
  },
  {
    label: "Add team",
    roles: ["admin", "manager"],
    action: "add_team",
  },
  {
    label: "Leave",
    roles: ["client", "team", "manager", "client_team"],
    action: "leave",
  },
];
// ---------------------------------------------------------
const getModified = (data) => {
  let modified = data.map((item) => {
    return {
      ...item,
      ["query_field"]: item.first_name + " " + item.last_name,
      // ["label"]: item.first_name + " " + item.last_name,
      // ["value"]: item.user_id,
    };
  });
  console.log(modified, "modified");
  return modified;
};
///=========================================================
const WorkspaceDetail = () => {
  const { state } = useLocation();
  const matches_breakpoint = useMediaQuery("(max-width:785px)");
  const matches_ipad = useMediaQuery("(max-width:768px)");
  const params = useParams();
  const navigate = useNavigate();
  console.log(state, "state");
  const slug = params.id;
  const [isPending, startTransition] = useTransition();
  const {
    dispatch_get_current_selected_item,
    dispatch_set_current_selected_item,
    slugProjectsData,
    setSlugProjectsData,
    dispatch_get_user_profile,
    socketEmit,
  } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [teamSearch, setTeamSearch] = useState("");
  const [loadingProjects, setLoadingProjects] = useState(
    // get_from_localStorage("projects") ? false :
    true
  );
  const [slugDetail, setSlugDetail] = useState({
    ...state?.workspace_data,
    team: state?.workspace_data?.team
      ? getModified(state?.workspace_data.team)
      : [],
  });
  useEffect(() => {
    console.log(slugDetail, "AScasjkchasjckashc");
  }, [slugDetail]);

  const matches_mobile = useMediaQuery("(max-width:500px)");

  const [slugTeamData, setSlugTeamData] = useState({
    profiles: [],
    loadMoreUrl: "",
    totalMembers: 0,
  });
  const [teamOpen, setTeamOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false);
  const [currentDetailsItem, setCurrentDetailsItem] = useState();
  const [projectType, setProjectType] = useState("all");
  const [projectTeamSearch, setProjectTeamSearch] = useState("");
  const [processing, setProcessing] = useState(false);
  const [delDialogOpen, setDelDialogOpen] = useState(false);
  const [delUserDialogOpen, setDelUserDialogOpen] = useState(false);
  const [projectTeamOpen, setProjectTeamOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState();
  const [delProjUserDialogOpen, setDelProjUserDialogOpen] = useState(false);
  const [removeWorkspaceUserType, setRemoveWorkspaceUserType] =
    useState("remove");
  const [removeProjectUserType, setRemoveProjectUserType] = useState("remove");
  const [autoCompleteValue, setAutoCompleteValue] = useState();
  const [memberSettingDrawerOpen, setMemberSettingDrawerOpen] = useState(false);
  const [memberSettingButtonLoading, setMemberSettingButtonLoading] =
    useState(false);
  const [memberSettings, setMemberSettings] = useState();
  const [allowedMembersForClient, setAllowedMembersForClient] = useState([]);
  const [selectedAllowedMembers, setSelectedAllowedMembers] = useState([]);
  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("");
  //////=====Invitation new flow===========///
  const [inviteTeamFor, setInviteTeamFor] = useState("");
  const [invitationDialogOpen, setInvitationDialogOpen] = useState(false);
  const [currentTargetItem, setCurrentTargetItem] = useState();
  const [cancelInvOpen, setCancelInvOpen] = useState(false);
  const [resendInvOpen, setResendInvOpen] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const openCancelInvDialog = () => {
    setCancelInvOpen(true);
  };
  const closeCancelInvDialog = () => {
    setCancelInvOpen(false);
    setCurrentTargetItem();
  };
  const openResendInvDialog = () => {
    setResendInvOpen(true);
  };
  const closeResendInvDialog = () => {
    setResendInvOpen(false);
    setCurrentTargetItem();
  };
  const openInvitationDialog = () => {
    setInvitationDialogOpen(true);
  };
  const closeInvitationDialog = () => {
    setInvitationDialogOpen(false);
    if (!projectTeamOpen) {
      setCurrentProject();
    }
  };
  const onCancelWorkspaceInvite = (_data) => {
    console.log(_data, "on-cancel-inv");
    setCurrentTargetItem(_data);
    openCancelInvDialog();
  };
  const onResendWorkspaceInvite = (_data) => {
    console.log(_data, "on-cancel-inv");
    setCurrentTargetItem(_data);
    openResendInvDialog();
  };
  const handleResendInvitation = async () => {
    try {
      setResendLoading(true);
      const result = await _resend_workspace_invitation(currentTargetItem._id);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        closeResendInvDialog();
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while resending invitation");
    } finally {
      setResendLoading(false);
    }
  };
  const handleCancelWorkspaceInvite = async () => {
    const id = currentTargetItem._id;
    setProcessing(true);
    const result = await _cancel_workspace_invitation(null, id);
    if (result.code === 200) {
      setSlugDetail((prev) => {
        return {
          ...prev,
          invitations: prev.invitations.filter(
            (inv) => inv._id !== currentTargetItem._id
          ),
        };
      });
      enqueueSnackbar("Invitation cancelled successfully", {
        variant: "success",
      });
      setProcessing(false);
      closeCancelInvDialog();

      // closeInvitationDrawer();
      /* filter current row if no workspaces & invitations left  for better ux, */
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
      closeCancelInvDialog();
      // closeInvitationDrawer();
    }
  };
  ////////////////////////////////////////////
  // ---------------------------------------------------------
  const handleNav = () => {
    navigate("/workspaces", { replace: true });
  };
  const getWorkspaceDetail = async () => {
    const result = await _user_workspace_detail(null, slug);
    if (result.code === 200) {
      console.log(result, "wps-detail", state?.workspace_data);

      setLoadingDetail(false);
      setSlugDetail({
        ...result.workspace,
        team: getModified(result.workspace.team),
      });
      let tempTeam = [
        {
          ...result.workspace.admin,
          label:
            result.workspace.admin.first_name +
            " " +
            result.workspace.admin.last_name +
            " (admin)",
          value: { ...result.workspace.admin },
        },
        ...getModified(result.workspace.team).map((member) => {
          return {
            ...member,
            label:
              member.first_name +
              " " +
              member.last_name +
              " (" +
              member.role +
              ")",
            value: { ...member },
          };
        }),
      ];
      tempTeam = tempTeam.filter(
        (member) => member.role !== "client" && member.role !== "client_team"
      );
      setAllowedMembersForClient(tempTeam);
    } else if (result.code == 400) {
      navigate("/workspaces", { replace: true });
    } else {
      setLoadingDetail(false);

      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getModifiedProjects = (data) => {
    let modified = data.map((item) => {
      return {
        ...item,
        ["query_field"]: item.title,
        ["role"]: slugDetail.role,
      };
    });
    console.log(modified, "AScacascas");
    return modified;
  };
  const getWorkspaceProjects = async () => {
    let result;
    if (projectType == "all") {
      result = await _workspace_projects_listing(null, slug);
    } else {
      result = await _workspace_favorite_projects_listing(slug);
    }
    if (result.code === 200) {
      console.log(result, "wps-projs");
      const { load_more_url, project_lists, total_projects } =
        result.project_list;
      setLoadingProjects(false);
      setSlugProjectsData({
        projects: getModifiedProjects(project_lists),
        loadMoreUrl: load_more_url,
        count: total_projects,
      });
      set_to_localStorage("projects", {
        projects: getModifiedProjects(project_lists),
        loadMoreUrl: load_more_url,
        count: total_projects,
      });
    } else {
      setLoadingProjects(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getWorkspaceTeam = async () => {
    const result = await _workspace_team(null, slug);

    if (result.code === 200) {
      console.log(result, "wps-team");
      const { team_list } = result;
      setSlugTeamData({
        profiles: getModified(team_list.customer_profile),
        loadMoreUrl: team_list.load_more_url,
        totalMembers: team_list.total_team_member,
      });
      team_list.customer_profile.map((item) => {
        //
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getTeamMembers = () => {
    const members = slugDetail.team.map((member) => {
      return {
        label: member.first_name + " " + member.last_name,
        value: member.user_id,
      };
    });
    members.push({
      label: slugDetail.admin.first_name + " " + slugDetail.admin.last_name,
      value: slugDetail.admin.user_id,
    });
    return members.filter(
      (member) => member.value !== dispatch_get_current_selected_item().user_id
    );
  };
  const getTeamMembersForLeaveProject = () => {
    const members = slugDetail.team.map((member) => {
      return {
        label: member.first_name + " " + member.last_name,
        value: member.user_id,
      };
    });
    members.push({
      label: slugDetail.admin.first_name + " " + slugDetail.admin.last_name,
      value: slugDetail.admin.user_id,
    });
    return members.filter(
      (member) => member.value !== dispatch_get_user_profile().user_id
    );
  };
  const getProjectTeamMembers = () => {
    const members = dispatch_get_current_selected_item().project.team.map(
      (member) => {
        return {
          label: member.first_name + " " + member.last_name,
          value: member.user_id,
        };
      }
    );
    members.push({
      label:
        state?.workspace_data?.admin.first_name +
        " " +
        state?.workspace_data?.admin.last_name,
      value: state?.workspace_data?.admin.user_id,
    });
    return members.filter(
      (member) =>
        member.value !==
        dispatch_get_current_selected_item().target_user?.user_id
    );
  };
  const openTeam = () => {
    setTeamOpen(true);
  };
  const closeTeam = () => {
    setInviteTeamFor("");
    setTeamOpen(false);
    setCurrentTab("");
    setTeamSearch("");
  };
  /*  */
  const openProjectTeam = () => {
    setCurrentTab("added");
    setInviteTeamFor("project");
    getProjectTeam();
    setProjectTeamOpen(true);
  };
  const closeProjectTeam = () => {
    setProjectTeamOpen(false);
    setCurrentProject();
    setInviteTeamFor("");
    setCurrentTab("");
    setProjectTeamSearch("");
  };
  const closeDetailsDrawer = () => {
    setOpenDetailsDrawer(false);
    setCurrentDetailsItem();
  };
  /*  */
  const handleShowTeam = () => {
    setCurrentTab("current");
    openTeam();
  };
  const canCreateProj = () => {
    if (!state) {
      return false;
    }
    let { role, allow_add_project } = slugDetail;
    if (!role) {
      return false;
    }
    if (role === "manager") {
      return true;
    }
    if (role === "admin") {
      return true;
    }

    if (role === "team" || role === "client" || role === "client_team") {
      if (allow_add_project) {
        return true;
      }
    }
    return false;
  };
  const handleCreate = () => {
    const { role, team_id } = state?.workspace_data;
    navigate(`/workspaces/create-project`, {
      state: {
        slug: slug,
        role: role,
        team_id: team_id,
        ...state?.workspace_data,
        ...slugDetail,
      },
    });
  };
  const getFilteredList = (data, type) => {
    let filtered = handle_localSearch(searchValue, data);
    if (type == "fav") {
      return filtered.filter((item) => item.is_favorite);
    } else {
      return filtered.filter((item) => !item.is_favorite);
    }
  };
  const openDelDialog = (data) => {
    setDelDialogOpen(true);
  };
  const closeDelDialog = (data) => {
    setDelDialogOpen(false);
  };
  const handleProjectMenuItemClick = (_option, _index) => {
    const { action } = _option;
    const current_item = dispatch_get_current_selected_item();
    console.log(_option, _index);
    console.log(current_item, "current_item");
    if (action === "delete") {
      openDelDialog();
    } else if (action === "edit") {
      navigate(`/workspaces/edit-project/${current_item._id}`, {
        state: { workspace_data: slugDetail, project: current_item },
      });
    } else if (action === "leave") {
      setLeaveDialogOpen(true);
    } else if (action === "add_team") {
      openProjectTeam();
    }
  };
  const handleFavouriteClick = async (item) => {
    let prevState = { ...slugProjectsData };
    setSlugProjectsData((prev) => {
      return {
        ...prev,
        projects: prev.projects.map((project) => {
          if (project._id == item._id) {
            return {
              ...project,
              is_favorite: !project.is_favorite,
            };
          } else {
            return {
              ...project,
            };
          }
        }),
      };
    });
    const result = await _favourite_project(item._id);
    if (result.code == 200) {
      if (projectType == "fav") {
        setSlugProjectsData((prev) => {
          return {
            ...prev,
            projects: prev.projects.filter(
              (project) => project._id !== item._id
            ),
          };
        });
      }
      if (item.is_favorite) {
        enqueueSnackbar("Project removed from favorites", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Project added to favorites", {
          variant: "success",
        });
      }
    } else {
      enqueueSnackbar(result.message, { varaint: "error" });
      setSlugProjectsData({ ...prevState });
    }
  };
  const getFilteredOptions = (_options, _item) => {
    let filtered = [];
    // const { project_creator } = _item;
    // if (project_creator === true) {
    //   return _options;
    // }
    _options.map((option) => {
      option.roles.map((role) => {
        if (role === _item.role) {
          filtered.push(option);
        }
      });
    });
    return filtered;
  };
  const handleDeleteProject = async () => {
    const current_item = dispatch_get_current_selected_item();
    console.log(current_item, "current_item");
    setProcessing(true);
    const result = await _delete_workspace_project(null, current_item._id);
    if (result.code === 200) {
      console.log(result, "del-res");
      const getFiltered = (data) => {
        return data.filter(
          (item) => String(item._id) !== String(current_item._id)
        );
      };
      // set_to_localStorage("projects", {
      //   ...slugProjectsData,
      //   ["projects"]: getFiltered(slugProjectsData.projects),
      // });
      setSlugProjectsData({
        ...slugProjectsData,
        ["projects"]: getFiltered(slugProjectsData.projects),
      });
      setProcessing(false);
      enqueueSnackbar("Project Deleted Successfully", { variant: "success" });
      closeDelDialog();
      dispatch_set_plan_limits({
        ...dispatch_get_plan_limits(),
        project_count: dispatch_get_plan_limits().project_count - 1,
      });
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
    //
  };
  const getFilteredTeam = (data) => {
    let filtered = handle_localSearch(teamSearch, data);
    return filtered;
  };

  const closeDelUserDialog = () => {
    setDelUserDialogOpen(false);
    setRemoveWorkspaceUserType("remove");
    // setAutoCompleteValue();
  };
  const openDelUserDialog = () => {
    setDelUserDialogOpen(true);
  };
  /*  */
  const openDelProjUserDialog = () => {
    setDelProjUserDialogOpen(true);
  };
  const closeDelProjUserDialog = () => {
    setDelProjUserDialogOpen(false);
    setRemoveProjectUserType("remove");
    // setAutoCompleteValue();
  };
  /*  */
  const onClickRemoveUser = (data) => {
    openDelUserDialog();
  };
  const handleRemoveUser = async () => {
    const selected_item = dispatch_get_current_selected_item();
    if (!selected_item) {
      return;
    }
    if (removeWorkspaceUserType == "assign" && !autoCompleteValue) {
      enqueueSnackbar("Please select the user to assign the tasks", {
        variant: "error",
      });
      return;
    }
    const data = {
      workspace: [slug],
      user_id: selected_item.user_id,
      member_id: autoCompleteValue?.value,
    };
    setProcessing(true);
    const result = await _remove_user_from_workspace(data);
    if (result.code === 200) {
      console.log(result, "del-user-res");
      setProcessing(false);
      enqueueSnackbar("User Removed Successfully", { variant: "success" });
      const getFiltered = (data) => {
        let filtered = data.filter((item) => {
          if (String(item._id) !== String(selected_item._id)) {
            return item;
          }
        });
        return filtered;
      };
      // setSlugTeamData({
      //   ...slugTeamData,
      //   profiles: getFiltered(slugTeamData.profiles),
      //   totalMembers: Number(slugTeamData.totalMembers) - 1,
      // });
      setSlugDetail((prev) => {
        return { ...prev, team: getFiltered(prev.team) };
      });
      console.log(selected_item, "to-remove");
      let temp = slugProjectsData.projects;
      let tempProjects = temp.map((project) => {
        return {
          ...project,
          team: getFiltered(project.team),
        };
      });
      setSlugProjectsData((prev) => {
        return {
          ...prev,
          projects: tempProjects,
        };
      });
      closeDelUserDialog();
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getProjectTeam = async () => {
    const current_item = dispatch_get_current_selected_item();
    // console.log(current_item, "curr");
  };
  const getFilteredProjectTeam = () => {
    const current_item = dispatch_get_current_selected_item();
    console.log(current_item, "curr");
    if (!current_item) {
      return {
        added: [],
        toAdd: [],
      };
    }
    let proj_team = current_item.project?.team
      ? current_item.project?.team
      : current_item?.team;

    if (!proj_team) {
      return {
        added: [],
        toAdd: [],
      };
    }
    if (proj_team) {
      const filtered_slug_team = [];
      const isAddedToProj = (_id) => {
        return proj_team.some((user) => user.user_id == _id);
      };
      slugDetail.team.map((item) => {
        let [found] = proj_team.filter(
          (_item) => String(_item.user_id) === String(item.user_id)
        );
        if (!found) {
          filtered_slug_team.push(item);
        }
      });
      const modifyTeam = (team) => {
        return team.map((item) => {
          return {
            ...item,
            ["query_field"]: item.first_name + " " + item.last_name,
            ["is_added"]: isAddedToProj(item.user_id),
          };
        });
      };
      let merged = [];
      if (slugDetail.role == "admin" || slugDetail.role == "manager") {
        merged = modifyTeam([...proj_team, ...filtered_slug_team]);
        // merged = [...filtered_slug_team];
      } else {
        merged = [...modifyTeam(proj_team)];
      }
      let filtered = handle_localSearch(projectTeamSearch, merged);
      return {
        added: filtered.filter((user) => user.is_added),
        toAdd: filtered.filter((user) => !user.is_added),
      };
    }
  };
  const getFilteredInvitations = (invs, search) => {
    if (!search) {
      return invs;
    } else {
      return invs.filter((inv) =>
        inv?.user_data
          ? (inv.user_data.first_name + " " + inv.user_data.last_name)
              .toLowerCase()
              .includes(search.toLowerCase())
          : //    ||
            // inv.email.toLowerCase().includes(search.toLowerCase())
            inv.email.toLowerCase().includes(search.toLowerCase())
      );
    }
  };
  const onClickRemoveProjectUser = () => {
    openDelProjUserDialog();
  };
  const [notificationTeam, setNotificationTeam] = useState([]);
  const handleDelProjUser = () => {
    const current_item = dispatch_get_current_selected_item();
    console.log(current_item, "to-remove");
    /*  */
    if (!current_item) {
      return;
    }
    setNotificationTeam((prev) => {
      return prev.filter((id) => id !== current_item.target_user.user_id);
    });
    let proj_team = current_item.project?.team;

    if (!proj_team) {
      return;
    }
    if (proj_team) {
      const filtered_slug_team = [];
      let updated_proj_team = [];
      updated_proj_team = proj_team.filter(
        (member) => String(member._id) !== String(current_item.target_user._id)
      );
      console.log(updated_proj_team, "final");
      dispatch_set_current_selected_item({
        project: { ...current_item.project, ["team"]: updated_proj_team },
        target_user: null,
      });
      closeDelProjUserDialog();
      /* no need to anything, just add mdif with is_added key in add user step */
    }
    setTimeout(() => {
      document.getElementById("update-team-button").click();
    }, 1);
  };
  const onClickAddProjectUser = (target_user) => {
    console.log(target_user, "tgt");
    const current_item = dispatch_get_current_selected_item();
    console.log(current_item, "to-add");
    /*  */
    if (!current_item) {
      return;
    }
    setNotificationTeam((prev) => {
      return prev.concat([target_user.user_id]);
    });
    let proj_team = current_item.project
      ? current_item.project?.team
      : current_item?.team;

    if (!proj_team) {
      return;
    }
    if (proj_team) {
      let updated_proj_team = Array.from(proj_team);
      updated_proj_team.push(target_user);
      console.log(updated_proj_team, "final");
      if (current_item.project) {
        dispatch_set_current_selected_item({
          project: { ...current_item.project, ["team"]: updated_proj_team },
          target_user: null,
        });
      } else {
        dispatch_set_current_selected_item({
          project: { ...current_item, ["team"]: updated_proj_team },
          target_user: null,
        });
      }

      closeDelProjUserDialog();
      setTimeout(() => {
        document.getElementById("update-team-button").click();
      }, 1);
    }
  };
  console.log(dispatch_get_current_selected_item(), "aiovaivavoajvav");
  const handleUpdateProjTeam = async () => {
    const current_item = dispatch_get_current_selected_item();
    console.log(current_item, "to-update");
    const { team } = current_item.project;
    console.log(team, "team-to-update");
    console.log(slugDetail.role, "current-user-role");
    if (!team) {
      return;
    }
    // let updated_team = team.map((user) => (user);
    const data = { team, member_id: autoCompleteValue?.value };

    setProcessing(true);
    const result = await _update_project_team(data, current_item.project._id);
    if (result.code === 200) {
      console.log(result, "res");
      setProcessing(false);
      // enqueueSnackbar("Project Team Updated Successfully", {
      //   variant: "success",
      // });
      let receiver = [...notificationTeam];
      // result.Project.team.map((member) => {
      //   if (member.user_id !== dispatch_get_user_profile().user_id) {
      //     receiver.push(member.user_id);
      //   }
      // });
      socketEmit("send_notification_to_all", {
        receiver,
        data: result.Project,
        message: `${dispatch_get_user_profile().first_name} ${
          dispatch_get_user_profile().last_name
        } updated a Project`,
        link: `${window.location.origin}/workspaces/projects/${result.Project._id}`,
      });
      setNotificationTeam([]);
      getWorkspaceProjects();
      setAutoCompleteValue();
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleLeaveProject = async () => {
    const current_item = dispatch_get_current_selected_item();
    console.log(current_item, "to-update");
    const { team_with_admin } = current_item;
    // console.log(team, "team-to-update");
    // console.log(slugDetail.role, "current-user-role");
    // if (!team) {
    //   return;
    // }
    let updated_team = team_with_admin.filter(
      (user) => user.user_id !== dispatch_get_user_profile().user_id
    );
    const data = { team: updated_team, member_id: autoCompleteValue?.value };
    console.log(updated_team, "aosiouaiocuaiouaoias");
    setProcessing(true);
    const result = await _update_project_team(data, current_item._id);
    if (result.code === 200) {
      console.log(result, "res");
      setProcessing(false);
      enqueueSnackbar("Project left successfully", {
        variant: "success",
      });
      let receiver = [...updated_team].map((user) => user.user_id);
      // result.Project.team.map((member) => {
      //   if (member.user_id !== dispatch_get_user_profile().user_id) {
      //     receiver.push(member.user_id);
      //   }
      // });
      socketEmit("send_notification_to_all", {
        receiver,
        data: result.Project,
        message: `${dispatch_get_user_profile().first_name} ${
          dispatch_get_user_profile().last_name
        } left project ${current_item.title}`,
        link: `${window.location.origin}/workspaces/projects/${result.Project._id}`,
      });
      setNotificationTeam([]);
      setLeaveDialogOpen(false);
      getWorkspaceProjects();
      setAutoCompleteValue();
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const canUpdateTeam = () => {
    const { role } = slugDetail;
    console.log(role, "cur-user-role");
    if (
      String(role) === "team" ||
      String(role) === "client" ||
      String(role) === "client_team"
    ) {
      return false;
    }
    if (String(role) === "admin") {
      return true;
    }
    if (String(role) === "manager") {
      return true;
    }
    return false;
  };

  ///============Settings////////////======================
  const onClickUserSettings = () => {
    setMemberSettingDrawerOpen(true);
  };
  const handleChangeMemberSettings = (e) => {
    setMemberSettings((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleUpdateMember = async () => {
    try {
      setMemberSettingButtonLoading(true);
      const req_obj = {
        allow_drag_drop:
          memberSettings.allow_drag_drop == "true" ? true : false,
        allow_add_project:
          memberSettings.allow_add_project == "true" ? true : false,
        role: memberSettings.role,
        workspace_id: slugDetail._id,
        allow_users:
          memberSettings.role == "client" ||
          memberSettings.role == "client_team"
            ? selectedAllowedMembers.map((member) => member.user_id)
            : [],
      };
      const result = await _update_workspace_member(
        req_obj,
        memberSettings.user_id
      );
      if (result.code == 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        // setMemberSettingDrawerOpen(false);
        delete req_obj.workspace_id;
        setSlugDetail((prev) => {
          return {
            ...prev,
            team: prev.team.map((member) => {
              if (member.user_id == memberSettings.user_id) {
                return {
                  ...member,
                  ...req_obj,
                };
              } else {
                return { ...member };
              }
            }),
          };
        });
        handleCloseMemberSettingDrawer();
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while updating the member");
    } finally {
      setMemberSettingButtonLoading(false);
    }
  };
  useEffect(() => {
    if (memberSettingDrawerOpen) {
      allowedMembersForClient.map((member) => {
        let index = dispatch_get_current_selected_item().allow_users.indexOf(
          member.user_id
        );
        if (index !== -1) {
          setSelectedAllowedMembers((prev) => [...prev, member]);
        }
      });
    }
  }, [memberSettingDrawerOpen]);
  useEffect(() => {
    dispatch_set_current_selected_item(memberSettings);
  }, [memberSettings]);

  const handleCloseMemberSettingDrawer = () => {
    setMemberSettingDrawerOpen(false);
    setMemberSettings();
    dispatch_set_current_selected_item();
    // setAllowedMembersForClient([]);
    setSelectedAllowedMembers([]);
  };
  ///=======================================================
  const handleOpenDetails = (item) => {
    setCurrentDetailsItem(item);
    setOpenDetailsDrawer(true);
  };
  // ---------------------------------------------------------
  useLayoutEffect(() => {
    // getWorkspaceTeam();
    getWorkspaceDetail();
  }, []);
  useLayoutEffect(() => {
    setLoadingProjects(true);
    getWorkspaceProjects();
    return () => {
      setSlugProjectsData({
        loadMoreUrl: "",
        projects: [],
        count: 0,
      });
    };
  }, [projectType]);
  useEffect(() => {
    /* refreshing */
    if (teamOpen) getWorkspaceTeam();
  }, [teamOpen]);
  // ---------------------------------------------------------
  if (!state) {
    return <Navigate to="/workspaces" replace={true} />;
  }
  return (
    <Page title="Workspace Detail">
      <Container maxWidth="xl">
        <div className="mb-4">
          <Grid
            rowSpacing={matches_breakpoint ? 2 : 0}
            columnSpacing={2}
            container
            alignItems="center"
          >
            <Grid item xs={12} sm={7} md={7} lg={8} xl={8}>
              <Stack direction="row" alignItems="center">
                <IconButton onClick={handleNav} className="me-1">
                  <Iconify icon="ep:back" />
                </IconButton>
                {matches_mobile ? (
                  <Typography variant="h4">
                    {loadingDetail ? (
                      <Skeleton sx={{ width: "8rem" }} variant="text" />
                    ) : (
                      capitalCase(slugDetail.name)
                    )}
                  </Typography>
                ) : (
                  <div>
                    {loadingDetail ? (
                      <Typography noWrap variant="h4">
                        <Skeleton
                          sx={{
                            width: "8rem",
                          }}
                          variant="text"
                        />
                      </Typography>
                    ) : (
                      <div className="d-flex align-items-center">
                        <Typography
                          variant="h6"
                          onClick={() => navigate("/workspaces")}
                          sx={{
                            // textDecoration: "underline",
                            cursor: "pointer",
                            color: "#00AB55",
                          }}
                        >
                          Workspaces
                        </Typography>
                        <Iconify icon="grommet-icons:next" sx={{ m: 1 }} />
                        <Typography variant="h6">
                          {capitalCase(slugDetail.name)}
                        </Typography>
                      </div>
                    )}
                  </div>
                )}
              </Stack>
            </Grid>
            <Grid
              xs={12}
              sm={5}
              md={5}
              lg={4}
              xl={4}
              item
              sx={{ marginLeft: { xs: "initial", sm: "auto" } }}
            >
              <Stack
                alignItems="center"
                justifyContent="flex-end"
                direction="row"
                columnGap={2}
              >
                {/* <Button
                  size={`${matches_ipad ? "small" : "medium"}`}
                  onClick={() => {
                    setInviteTeamFor("workspace");
                    handleShowTeam();
                  }}
                  variant="contained"
                >
                  Workspace Team
                </Button> */}
                {/* use loading buttons here */}
                {canCreateProj() ? (
                  <Button
                    size={`${matches_ipad ? "small" : "medium"}`}
                    onClick={handleCreate}
                    variant="contained"
                  >
                    New Project
                  </Button>
                ) : (
                  <Tooltip title="Not allowed">
                    <div>
                      <Button
                        size={`${matches_ipad ? "small" : "medium"}`}
                        disabled
                        variant="contained"
                      >
                        New Project
                      </Button>
                    </div>
                  </Tooltip>
                )}
              </Stack>
            </Grid>
          </Grid>
          <div className="mt-4">
            <Grid container>
              <Grid sx={{ mb: matches_mobile ? 2 : 0 }} item xs={12} sm={3}>
                {/* <TabContext value={projectType}>
                  <TabList onChange={(e, val) => setProjectType(val)}>
                    <Tab label="All Projects" value="all" />
                    <Tab label="Favorites" value="fav" />
                  </TabList>
                </TabContext> */}
                <Typography variant="h5">Projects</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Stack direction="row" justifyContent="flex-end">
                  <SearchBar
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </Stack>
              </Grid>
            </Grid>
          </div>
        </div>
        {getFilteredList(slugProjectsData.projects, "fav") != 0 && (
          <Grid container spacing={3}>
            {getFilteredList(slugProjectsData.projects, "fav").map(
              (item, index) => {
                return (
                  <Grid
                    sx={{ position: "relative" }}
                    key={index}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    xl={2}
                  >
                    <ProjectCard
                      onFavouriteClick={handleFavouriteClick}
                      onClickMenuItem={(option, index, item) => {
                        setCurrentProject(item);
                        handleProjectMenuItemClick(option, index);
                      }}
                      onDeleteButtonClick={() => {
                        dispatch_set_current_selected_item(item);
                        openDelDialog();
                      }}
                      menuOptions={getFilteredOptions(MENU_OPTIONS, item)}
                      item={item}
                      onClickTeam={(item) => {
                        setCurrentProject(item);
                        openProjectTeam();
                      }}
                      handleOpenDetails={handleOpenDetails}
                      slugDetail={slugDetail}
                    />
                  </Grid>
                );
              }
            )}
            {!loadingProjects &&
              slugProjectsData.projects.length == 0 &&
              canCreateProj() && (
                <AddNewCard title="project" handleClick={handleCreate} />
              )}
            {/* <NoData
            dataLength={getFilteredList(slugProjectsData.projects).length}
            loading={loadingProjects}
            title="No Projects Found !"
          /> */}
          </Grid>
        )}
        {getFilteredList(slugProjectsData.projects, "all") != 0 &&
          getFilteredList(slugProjectsData.projects, "fav") != 0 && (
            <Divider sx={{ borderBottom: "2px solid lightgrey", my: 1 }} />
          )}
        <Grid container spacing={3}>
          {getFilteredList(slugProjectsData.projects, "all").map(
            (item, index) => {
              return (
                <Grid
                  sx={{ position: "relative" }}
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  xl={2}
                >
                  <ProjectCard
                    onFavouriteClick={handleFavouriteClick}
                    onClickMenuItem={(option, index, item) => {
                      setCurrentProject(item);
                      handleProjectMenuItemClick(option, index);
                    }}
                    menuOptions={getFilteredOptions(MENU_OPTIONS, item)}
                    item={item}
                    onClickTeam={(item) => {
                      setCurrentProject(item);
                      openProjectTeam();
                    }}
                    onDeleteButtonClick={() => {
                      dispatch_set_current_selected_item(item);
                      openDelDialog();
                    }}
                    handleOpenDetails={handleOpenDetails}
                    slugDetail={slugDetail}
                  />
                </Grid>
              );
            }
          )}
          {!loadingProjects &&
            slugProjectsData?.projects?.length == 0 &&
            canCreateProj() && (
              <AddNewCard title="project" handleClick={handleCreate} />
            )}
          {!loadingProjects &&
            slugProjectsData?.projects?.length == 0 &&
            !canCreateProj() && (
              <NoData
                dataLength={getFilteredList(slugProjectsData.projects).length}
                loading={loadingProjects}
                title="No Projects Found !"
              />
            )}
        </Grid>
        {loadingProjects && <CircularLoader />}
      </Container>
      <MuiDrawer
        onClose={closeTeam}
        isOpen={teamOpen}
        title="Workspace Team"
        titleButton={
          slugDetail.role !== "team" &&
          slugDetail.role !== "client_team" && (
            <Button
              variant="contained"
              size="small"
              onClick={openInvitationDialog}
            >
              Invite members
            </Button>
          )
        }
      >
        <Tabs
          sx={{ pt: 0 }}
          value={currentTab}
          onChange={(e, val) => {
            setTeamSearch("");
            setCurrentTab(val);
          }}
        >
          <Tab
            sx={{ pt: 0, width: "50%" }}
            value={"current"}
            label="Current Team"
          />
          <Tab
            value="invited"
            label="Invited Team"
            sx={{ width: "50%", pt: 0 }}
          />
        </Tabs>
        <Paper sx={{ mb: 2 }} elevation={0} className="search-bar">
          <SearchBar
            notransition="true"
            sx={{ width: "100%" }}
            value={teamSearch}
            onChange={(e) => setTeamSearch(e.target.value)}
          />
        </Paper>
        {currentTab == "current" && (
          <>
            <Stack spacing={2}>
              {getFilteredTeam(slugDetail.team).map((item, index) => (
                <UserCard
                  onRemoveUser={onClickRemoveUser}
                  onSettings={onClickUserSettings}
                  setMemberSettings={setMemberSettings}
                  userRole={slugDetail.role}
                  key={item._id}
                  item={item}
                  allowedMembersForClient={allowedMembersForClient}
                />
              ))}
            </Stack>
            {slugDetail.team.length === 0 && (
              <Typography
                color={(theme) => theme.palette.text.disabled}
                sx={{ mt: 20 }}
                textAlign="center"
              >
                No team members found
              </Typography>
            )}
          </>
        )}
        {currentTab == "invited" && (
          <>
            <Stack spacing={2}>
              {getFilteredInvitations(slugDetail.invitations, teamSearch).map(
                (item, index) => (
                  <InvitationCard
                    onCancel={onCancelWorkspaceInvite}
                    onResend={onResendWorkspaceInvite}
                    key={item._id}
                    item={item}
                  />
                )
              )}
            </Stack>
            {slugDetail.invitations.length === 0 && (
              <Typography
                color={(theme) => theme.palette.text.disabled}
                sx={{ mt: 20 }}
                textAlign="center"
              >
                No Invitations found
              </Typography>
            )}
          </>
        )}
      </MuiDrawer>

      {/*  */}
      <MuiDrawer
        paperProps={{ width: { xs: 1, sm: 540, md: 490 } }}
        bottomComponent={
          canUpdateTeam() && (
            <>
              {/* {getFilteredProjectTeam().added.length !== 0 && ( */}
              <div className="centered-row project-team-update-btn">
                <LoadingButton
                  id="update-team-button"
                  className="d-none"
                  variant="contained"
                  onClick={handleUpdateProjTeam}
                  loading={processing}
                >
                  Update
                </LoadingButton>
              </div>
              {/* )} */}
            </>
          )
        }
        onClose={closeProjectTeam}
        isOpen={projectTeamOpen}
        title="Project Team"
        titleButton={
          slugDetail?.role !== "team" &&
          slugDetail?.role !== "client_team" && (
            <Button
              variant="contained"
              size="small"
              onClick={openInvitationDialog}
            >
              invite members
            </Button>
          )
        }
      >
        {slugDetail?.role !== "team" && (
          <Tabs
            sx={{ pt: 0 }}
            value={currentTab}
            onChange={(e, val) => {
              setProjectTeamSearch("");
              setCurrentTab(val);
            }}
          >
            <Tab
              sx={{ pt: 0, width: "33%" }}
              value={"added"}
              label="Project Team"
            />
            <Tab
              value="toAdd"
              label="Workspace Team"
              sx={{ width: "34%", pt: 0 }}
            />
            <Tab
              value="projectInvitations"
              label="Invited Team"
              sx={{ width: "33%", pt: 0 }}
            />
          </Tabs>
        )}
        {currentTab == "added" && (
          <>
            <Paper sx={{ mb: 2 }} elevation={0} className="search-bar">
              <SearchBar
                notransition="true"
                sx={{ width: "100%", mb: 2 }}
                value={projectTeamSearch}
                onChange={(e) => setProjectTeamSearch(e.target.value)}
              />
            </Paper>
            <Stack spacing={2}>
              {getFilteredProjectTeam().added.map((item, index) => (
                <ProjectUserCard
                  onRemoveUser={onClickRemoveProjectUser}
                  onAddUser={onClickAddProjectUser}
                  userRole={
                    dispatch_get_current_selected_item()?.project?.role ??
                    currentProject.role
                  }
                  key={index}
                  item={item}
                />
              ))}
            </Stack>
            {getFilteredProjectTeam().added.length === 0 && (
              <Typography
                color={(theme) => theme.palette.text.disabled}
                sx={{ mt: 20 }}
                textAlign="center"
              >
                No Project team members found
              </Typography>
            )}
          </>
        )}
        {currentTab == "toAdd" && (
          <>
            <Paper sx={{ mb: 2 }} elevation={0} className="search-bar">
              <SearchBar
                notransition="true"
                sx={{ width: "100%", mb: 2 }}
                value={projectTeamSearch}
                onChange={(e) => setProjectTeamSearch(e.target.value)}
              />
            </Paper>
            <Stack spacing={2}>
              {getFilteredProjectTeam().toAdd.map((item, index) => (
                <ProjectUserCard
                  onRemoveUser={onClickRemoveProjectUser}
                  onAddUser={onClickAddProjectUser}
                  userRole={
                    dispatch_get_current_selected_item()?.project?.role ??
                    dispatch_get_current_selected_item()?.role
                  }
                  key={index}
                  item={item}
                />
              ))}
            </Stack>
            {getFilteredProjectTeam().toAdd.length === 0 && (
              <Typography
                color={(theme) => theme.palette.text.disabled}
                sx={{ mt: 20 }}
                textAlign="center"
              >
                No team members found
              </Typography>
            )}
          </>
        )}
        {currentTab == "projectInvitations" && (
          <>
            <Paper sx={{ mb: 2 }} elevation={0} className="search-bar">
              <SearchBar
                notransition="true"
                sx={{ width: "100%", mb: 2 }}
                value={projectTeamSearch}
                onChange={(e) => setProjectTeamSearch(e.target.value)}
              />
            </Paper>
            <Stack spacing={2}>
              {getFilteredInvitations(
                slugDetail.invitations,
                // .filter((inv) =>
                //   inv.project.includes(currentProject._id)
                // )
                projectTeamSearch
              ).map((item, index) => (
                <InvitationCard
                  onCancel={onCancelWorkspaceInvite}
                  onResend={onResendWorkspaceInvite}
                  key={item._id}
                  item={item}
                />
              ))}
            </Stack>
            {getFilteredInvitations(
              slugDetail.invitations,
              // .filter((inv) =>
              //   inv.project.includes(currentProject._id)
              // )
              projectTeamSearch
            ).length === 0 && (
              <Typography
                color={(theme) => theme.palette.text.disabled}
                sx={{ mt: 20 }}
                textAlign="center"
              >
                No invitations found!
              </Typography>
            )}
          </>
        )}
      </MuiDrawer>
      {memberSettings && (
        <MuiDrawer
          title={
            sentenceCase(
              memberSettings.first_name + " " + memberSettings.last_name
            ) + " settings"
          }
          isOpen={memberSettingDrawerOpen}
          onClose={handleCloseMemberSettingDrawer}
          bottomComponent={
            canUpdateTeam() && (
              <>
                <div className="centered-row project-team-update-btn">
                  <LoadingButton
                    id="update-team-button"
                    // className="d-none"
                    variant="contained"
                    onClick={handleUpdateMember}
                    loading={memberSettingButtonLoading}
                  >
                    Update
                  </LoadingButton>
                </div>
              </>
            )
          }
        >
          <Stack spacing={3}>
            {(dispatch_get_current_selected_item().role == "team" ||
              dispatch_get_current_selected_item().role == "manager") && (
              <MuiSelect
                value={memberSettings.role}
                name="role"
                onChange={handleChangeMemberSettings}
                options={[
                  {
                    label: "Manager",
                    value: "manager",
                  },
                  {
                    label: "Team",
                    value: "team",
                  },
                ]}
                label="Choose Role"
              />
            )}
            {(dispatch_get_current_selected_item().role == "client" ||
              dispatch_get_current_selected_item().role == "client_team") && (
              <MuiMultiAutocomplete
                value={selectedAllowedMembers}
                options={allowedMembersForClient}
                onChange={(val) => setSelectedAllowedMembers(val)}
                label="Choose Members"
                fixedOptions={allowedMembersForClient.filter(
                  (member) =>
                    member.role == "admin" ||
                    member.user_id == dispatch_get_user_profile().user_id
                )}
              />
            )}
            {memberSettings.role == "team" && (
              <>
                <FormControl>
                  <FormLabel>Task Drag-Drop</FormLabel>
                  <RadioGroup
                    title="Drag-Drop"
                    row
                    disabled={memberSettingButtonLoading}
                    aria-labelledby="gender-select-radio-buttons-label"
                    name="allow_drag_drop"
                    value={memberSettings.allow_drag_drop}
                    onChange={handleChangeMemberSettings}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Allow"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="Disallow"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>Add project</FormLabel>
                  <RadioGroup
                    title="Drag-Drop"
                    row
                    disabled={memberSettingButtonLoading}
                    aria-labelledby="gender-select-radio-buttons-label"
                    name="allow_add_project"
                    value={memberSettings.allow_add_project}
                    onChange={handleChangeMemberSettings}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Allow"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="Disallow"
                    />
                  </RadioGroup>
                </FormControl>
              </>
            )}
          </Stack>
        </MuiDrawer>
      )}
      {/* <MuiDrawer
        title="Project Details"
        isOpen={openDetailsDrawer}
        onClose={closeDetailsDrawer}
        paperProps={{ width: { xs: 1, sm: 540, md: 450 } }}
      >
        {currentDetailsItem && (
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Name:</Typography>
              </Grid>
              <Grid item xs={6}>
                {currentDetailsItem.title}
              </Grid>
            </Grid>
          </Container>
        )}
      </MuiDrawer> */}
      <Dialog
        open={openDetailsDrawer}
        onClose={closeDetailsDrawer}
        scroll="paper"
        fullWidth
      >
        <DialogTitle>Project Description</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          {currentDetailsItem && (
            <DialogContentText>
              {currentDetailsItem.description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: currentDetailsItem.description,
                  }}
                />
              )}
              {!currentDetailsItem.description && (
                <Typography>No description added</Typography>
              )}
            </DialogContentText>
          )}
        </DialogContent>
      </Dialog>
      <MuiDialog
        title="Delete Project"
        message="Are you sure you want to delete this project?"
        onToggle={closeDelDialog}
        open={delDialogOpen}
        loading={processing}
        onAgree={handleDeleteProject}
      />
      {/* <MuiDialog
        title="Remove User"
        message="Are you sure you want to remove this user?"
        onToggle={closeDelUserDialog}
        open={delUserDialogOpen}
        loading={processing}
        onAgree={handleRemoveUser}
      /> */}
      <Dialog
        open={delUserDialogOpen || leaveDialogOpen}
        onClose={() => {
          delUserDialogOpen && closeDelUserDialog();
          leaveDialogOpen && setLeaveDialogOpen(false);
        }}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {delUserDialogOpen ? "Remove User" : "Leave Project"}
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            // ref={descriptionElementRef}
            tabIndex={-1}
          >
            {delUserDialogOpen
              ? "Are you sure you want to remove this user?"
              : "Are you sure you want to Leave this project?"}

            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={removeWorkspaceUserType}
              onChange={(e) => setRemoveWorkspaceUserType(e.target.value)}
            >
              <FormControlLabel
                value="remove"
                control={<Radio />}
                label="Delete all tasks created by this user and remove as task member"
              />
              <FormControlLabel
                value="assign"
                control={<Radio />}
                label="Assign the tasks created by this user to some other team member"
              />
            </RadioGroup>
            {removeWorkspaceUserType === "assign" && (
              <MuiAutoComplete
                onChange={(val) => setAutoCompleteValue(val)}
                value={autoCompleteValue}
                label="Team Members"
                options={
                  delUserDialogOpen
                    ? getTeamMembers()
                    : getTeamMembersForLeaveProject()
                }
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              delUserDialogOpen && closeDelUserDialog();
              leaveDialogOpen && setLeaveDialogOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              delUserDialogOpen && handleRemoveUser();
              leaveDialogOpen && handleLeaveProject();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <MuiDialog
        title="Remove User"
        message="Are you sure you want to remove this user from project?"
        onToggle={closeDelProjUserDialog}
        open={delProjUserDialogOpen}
        loading={processing}
        onAgree={handleDelProjUser}
        ExtraComponent={
          <>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={removeProjectUserType}
              onChange={(e) => setRemoveProjectUserType(e.target.value)}
            >
              <FormControlLabel
                value="remove"
                control={<Radio />}
                label="Delete all tasks created by this user and remove him as task member"
              />
              <FormControlLabel
                value="assign"
                control={<Radio />}
                label="Assign the tasks, this user created, to some other team member"
              />
            </RadioGroup>
            {removeProjectUserType === "assign" && (
              <MuiAutoComplete
                onChange={(val) => setAutoCompleteValue(val)}
                value={autoCompleteValue}
                label="Team Members"
                options={getProjectTeamMembers()}
              />
            )}
          </>
        }
      />
      {(inviteTeamFor == "workspace" || currentProject) && (
        <InviteMembersDialog
          open={invitationDialogOpen}
          onClose={closeInvitationDialog}
          workspace={slugDetail}
          project={currentProject}
          onSuccess={(inv) =>
            setSlugDetail((prev) => {
              return { ...prev, invitations: [...prev.invitations, inv] };
            })
          }
        />
      )}
      <MuiDialog
        open={cancelInvOpen}
        message="Are you sure you want to cancel your invite?"
        title="Cancel invite"
        onToggle={(value) => setCancelInvOpen(value)}
        onAgree={handleCancelWorkspaceInvite}
        loading={processing}
      />
      <MuiDialog
        open={resendInvOpen}
        message="Are you sure you want to re-send this invitation?"
        title="Resend invite"
        onToggle={(value) => setResendInvOpen(value)}
        onAgree={handleResendInvitation}
        loading={resendLoading}
      />
    </Page>
  );
};

export default WorkspaceDetail;
