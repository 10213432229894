import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { _current_clients_list } from "src/DAL/team";
import {
  MuiAutoComplete,
  MuiMultiAutocomplete,
  NavBackHeader,
  Page,
  RichEditor,
} from "src/components";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { _add_report, _get_tasks_for_report } from "src/DAL";
import ListHead from "src/components/ListHead";
import { useAppContext } from "src/hooks";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "title", label: "Task Title", alignRight: false },
  { id: "hours", label: "Hours", alignRight: false },
  { id: "minutes", label: "Minutes", alignRight: false },
];
function AddReport() {
  const navigate = useNavigate();
  const { dispatch_get_user_profile } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    report_title: "",
    client: null,
    projects: [],
    start_date: null,
    end_date: null,
    hours: "",
    minutes: "",
    description: "",
  });
  const [clients, setClients] = useState([]);
  const [tasksLoading, setTasksLoading] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [addReportLoading, setAddReportLoading] = useState(false);
  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const getClientsList = async () => {
    const result = await _current_clients_list();
    if (result.code === 200) {
      setClients(
        result.user_workspace.map((client) => {
          return {
            ...client,
            label: client.first_name + " " + client.last_name,
            value: { ...client },
          };
        })
      );
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const selectAllTasks = () => {
    if (selected.length == taskList.length) {
      setSelected([]);
      setInputs((prev) => {
        return { ...prev, hours: "", minutes: "" };
      });
    } else {
      let tempTime = { hours: 0, minutes: 0 };
      for (let i = 0; i < taskList.length; i++) {
        if (taskList[i].time_track.final_time) {
          tempTime.hours += parseInt(
            taskList[i].time_track.final_time.split(":")[0]
          );
          tempTime.minutes += parseInt(
            taskList[i].time_track.final_time.split(":")[1]
          );
        }
        console.log(tempTime, "afaiaiuauiaf");
      }
      // taskList.map((task) => {
      //   // temp.push(task._id);
      //   tempTime = {
      //     hours:
      //       tempTime.hours + task.time_track.final_time
      //         ? Number(task.time_track.final_time.split(":")[0])
      //         : 0,
      //     minutes:
      //       tempTime.minutes + task.time_track.final_time
      //         ? Number(task.time_track.final_time.split(":")[1])
      //         : 0,
      //   };
      // });
      setSelected([...taskList]);
      setInputs((prev) => {
        return { ...prev, hours: tempTime.hours, minutes: tempTime.minutes };
      });
    }
  };
  //======Get Task List =======================
  const getTasks = async () => {
    try {
      if (
        inputs.projects.length == 0 ||
        !inputs.start_date ||
        !inputs.client ||
        !inputs.end_date
      ) {
        return;
      }
      setTasksLoading(true);
      const req_obj = {
        client_id: inputs.client.user_id,
        start_date: inputs.start_date,
        end_date: inputs.end_date,
        project_ids: inputs.projects.map((project) => project._id),
      };
      const result = await _get_tasks_for_report(req_obj);
      setTaskList(result.task_list);
    } catch (error) {
      console.log(error, " while fetching tasks");
    } finally {
      setTasksLoading(false);
    }
  };
  ////========Save report==//////////////////////////////////////
  const getPrice = (tasks) => {
    let tempPrice = 0;
    for (let i = 0; i < tasks.length; i++) {
      let project = inputs.projects.find(
        (proj) => proj._id == tasks[0].project.id
      );
      let taskPrice = Number(
        project.team.find((member) => member.user_id == inputs.client.user_id)
          .price
          ? project.team.find(
              (member) => member.user_id == inputs.client.user_id
            ).price
          : "0"
      );
      let hoursPrice =
        taskPrice * Number(tasks[i].time_track.final_time.split(":")[0]);
      let minutesPrice =
        taskPrice * (Number(tasks[i].time_track.final_time.split(":")[1]) / 60);
      let totalTaskPrice = hoursPrice + minutesPrice;
      tempPrice += totalTaskPrice;
    }

    return String(tempPrice);
  };
  const handleSaveReport = async () => {
    if (!inputs.report_title) {
      enqueueSnackbar("Please enter report title", { variant: "error" });
      return;
    }
    try {
      setAddReportLoading(true);
      const req_obj = {
        title: inputs.report_title,
        start_date: moment(inputs.start_date).format("DD-MM-YYYY"),
        end_date: moment(inputs.end_date).format("DD-MM-YYYY"),
        total_time: `${inputs.hours ? inputs.hours : "00"}:${
          inputs.minutes ? inputs.minutes : "00"
        }`,
        client_data: {
          user_id: inputs.client.user_id,
          first_name: inputs.client.first_name,
          last_name: inputs.client.last_name,
          email: inputs.client.email,
          image: inputs.client.image,
        },
        report_creater_data: {
          user_id: dispatch_get_user_profile().user_id,
          first_name: dispatch_get_user_profile().first_name,
          last_name: dispatch_get_user_profile().last_name,
          email: dispatch_get_user_profile().email,
          image: dispatch_get_user_profile().image,
        },
        tasks: [...selected],
        price: getPrice(selected),
        projects: inputs.projects.map((project) => project.value),
      };
      const result = await _add_report(req_obj);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        navigate(-1);
      }
    } catch (error) {
      console.log(error, " fetched while adding report");
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    } finally {
      setAddReportLoading(false);
    }
  };
  const handleChangeTaskTime = (event, index, task, type) => {
    // if (event.target.value <= 0) {
    //   return;
    // }
    if (!/^\d+$/.test(event.target.value)) {
      return;
    }
    let tempTask = {
      ...task,
    };
    let tempTime = tempTask.time_track.final_time;
    let tempinputs = { ...inputs };
    if (tempTime) {
      tempinputs.hours = inputs.hours - Number(tempTime.split(":")[0]);
      tempinputs.minutes = inputs.minutes - Number(tempTime.split(":")[1]);
      console.log({ ...inputs }, "firstfirstfirstfirstfirst1111111111", {
        ...tempinputs,
      });
      if (type == "hours") {
        tempTime =
          (event.target.value != 0
            ? event.target.value.slice(-2).padStart(2, "0")
            : "00") +
          ":" +
          tempTime.split(":")[1];
        console.log(tempTime, "acacauiahvas");
      } else if (type == "minutes") {
        tempTime =
          tempTime.split(":")[0] +
          ":" +
          (event.target.value != 0
            ? event.target.value.slice(-2).padStart(2, "0")
            : "00");
      }
    } else {
      tempinputs.hours = 0;
      tempinputs.minutes = 0;
      if (type == "hours") {
        tempTime =
          (event.target.value != 0
            ? event.target.value.slice(-2).padStart(2, "0")
            : "00") + ":00";
      } else if (type == "minutes") {
        tempTime =
          "00:" +
          (event.target.value != 0
            ? event.target.value.slice(-2).padStart(2, "0")
            : "00");
      }
    }
    tempTask = {
      ...tempTask,
      time_track: {
        ...tempTask.time_track,
        final_time: tempTime,
      },
    };
    tempinputs.hours += Number(tempTime.split(":")[0]);
    tempinputs.minutes += Number(tempTime.split(":")[1]);
    setTaskList((prev) =>
      prev.map((task) => {
        if (task._id == tempTask._id) {
          return { ...tempTask };
        } else {
          return { ...task };
        }
      })
    );
    let found = selected.find((sel) => sel._id == tempTask._id);
    if (found) {
      console.log("firstfirstfirstfirstfirst final", { ...tempinputs });
      setInputs({ ...tempinputs });
      setSelected((prev) =>
        prev.map((task) => {
          if (task._id == tempTask._id) {
            return { ...tempTask };
          } else {
            return { ...task };
          }
        })
      );
    }
  };
  ////////////////////////////////////////////////////////////////
  useEffect(() => {
    getTasks();
  }, [inputs.client, inputs.projects, inputs.start_date, inputs.end_date]);
  useEffect(() => {
    setInputs((prev) => {
      return {
        ...prev,
        projects: [],
      };
    });
  }, [inputs.client]);
  useEffect(() => {
    let tempInputs = { ...inputs };
    if (tempInputs.minutes > 60) {
      tempInputs = {
        ...tempInputs,
        hours: tempInputs.hours + Math.floor(tempInputs.minutes / 60),
        minutes: tempInputs.minutes % 60,
      };
    } else if (tempInputs.minutes < 0) {
      tempInputs = {
        ...tempInputs,
        hours: tempInputs.hours + Math.floor(tempInputs.minutes / 60) * -1,
        minutes: (tempInputs.minutes % 60) * -1,
      };
    }
    if (tempInputs.hours !== "" && typeof tempInputs.hours == "string") {
      tempInputs = {
        ...tempInputs,
        hours: Number(tempInputs.hours),
      };
    }
    if (tempInputs.minutes !== "" && typeof tempInputs.minutes == "string") {
      tempInputs = {
        ...tempInputs,
        minutes: Number(tempInputs.minutes),
      };
    }
    setInputs({ ...tempInputs });
  }, [inputs.minutes, inputs.hours]);
  useEffect(() => {
    getClientsList();
  }, []);
  return (
    <Page title="Add Report">
      <Container maxWidth="xl">
        <div className="mb-4">
          <NavBackHeader heading="Create Task Report" />
        </div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                label="Report Title"
                required
                name="report_title"
                value={inputs.report_title}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <MuiAutoComplete
                required={true}
                label="Client"
                options={clients}
                value={inputs.client}
                onChange={(val) => {
                  setInputs((prev) => {
                    return { ...prev, client: val };
                  });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <MuiMultiAutocomplete
                required={true}
                label="Projects"
                options={
                  inputs?.client?.project.map((proj) => {
                    return { ...proj, label: proj.title, value: { ...proj } };
                  }) ?? []
                }
                value={inputs.projects}
                onChange={(val) => {
                  setInputs((prev) => {
                    return { ...prev, projects: val };
                  });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={12}></Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Start Date"
                  inputFormat="DD-MMM-YYYY"
                  rifmFormatter={(date) => moment(date).format("DD-MMM-YYYY")}
                  value={inputs.start_date}
                  onChange={(newValue) =>
                    setInputs({
                      ...inputs,
                      start_date: newValue
                        ? moment(newValue).format("DD-MMM-YYYY")
                        : null,
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      required
                      fullWidth
                      name="start_date"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD-MMM-YYYY",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="End Date"
                  inputFormat="DD-MMM-YYYY"
                  rifmFormatter={(date) => moment(date).format("DD-MMM-YYYY")}
                  value={inputs.end_date}
                  onChange={(newValue) =>
                    setInputs({
                      ...inputs,
                      end_date: newValue
                        ? moment(newValue).format("DD-MMM-YYYY")
                        : null,
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      required
                      name="end_date"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD-MMM-YYYY",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {tasksLoading && (
              <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ minHeight: "50px" }}
              >
                <CircularProgress />
              </div>
            )}
            {!tasksLoading && (
              <>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <ListHead
                      headLabel={TABLE_HEAD}
                      numSelected={selected.length}
                      rowCount={taskList.length}
                      onSelectAllClick={selectAllTasks}
                      checkbox={true}
                    />
                    <TableBody>
                      {taskList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          // row1.team.map((row) => {
                          const { task_title, _id, time_track } = row;
                          const isItemSelected = Boolean(
                            selected.find((item) => item._id == _id)
                          );
                          // console.log(
                          //   time_track.final_time,
                          //   "iuauihuiacuiahsuciac"
                          // );
                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={() => {
                                    if (isItemSelected) {
                                      setSelected(
                                        selected.filter((i) => i._id !== _id)
                                      );
                                      setInputs((prev) => {
                                        return {
                                          ...prev,
                                          hours:
                                            prev.hours -
                                            Number(
                                              time_track.final_time.split(
                                                ":"
                                              )[0]
                                            ),
                                          minutes:
                                            prev.minutes -
                                            Number(
                                              time_track.final_time.split(
                                                ":"
                                              )[1]
                                            ),
                                        };
                                      });
                                    } else {
                                      setSelected([...selected, row]);
                                      setInputs((prev) => {
                                        return {
                                          ...prev,
                                          hours:
                                            prev.hours === ""
                                              ? Number(
                                                  time_track.final_time
                                                    ? time_track.final_time.split(
                                                        ":"
                                                      )[0]
                                                    : 0
                                                )
                                              : prev.hours +
                                                Number(
                                                  time_track.final_time
                                                    ? time_track.final_time.split(
                                                        ":"
                                                      )[0]
                                                    : 0
                                                ),
                                          minutes:
                                            prev.minutes === ""
                                              ? Number(
                                                  time_track.final_time
                                                    ? time_track.final_time.split(
                                                        ":"
                                                      )[1]
                                                    : 0
                                                )
                                              : prev.minutes +
                                                Number(
                                                  time_track.final_time
                                                    ? time_track.final_time.split(
                                                        ":"
                                                      )[1]
                                                    : 0
                                                ),
                                        };
                                      });
                                    }
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <Typography variant="subtitle2">
                                  {rowsPerPage * page + (index + 1)}
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Typography variant="subtitle2" noWrap>
                                    {task_title}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                <TextField
                                  size="small"
                                  onChange={(e) =>
                                    handleChangeTaskTime(e, index, row, "hours")
                                  }
                                  label="Hours"
                                  value={
                                    row.time_track.final_time
                                      ? row.time_track.final_time.split(":")[0]
                                      : ""
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  size="small"
                                  onChange={(e) =>
                                    handleChangeTaskTime(
                                      e,
                                      index,
                                      row,
                                      "minutes"
                                    )
                                  }
                                  label="Minutes"
                                  value={
                                    row.time_track.final_time
                                      ? row.time_track.final_time.split(":")[1]
                                      : ""
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          );
                          // });
                        })}
                      {taskList.length == 0 && (
                        <TableRow style={{ height: 53 }}>
                          <TableCell colSpan={6} align="center">
                            No tasks found!
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={taskList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                value={inputs.hours}
                label="Hours"
                name="hours"
                onChange={handleChange}
                // inputProps={{ readOnly: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                value={inputs.minutes}
                label="Minutes"
                name="minutes"
                onChange={handleChange}
                // inputProps={{ readOnly: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <RichEditor
              placeHolder="Add Report Description"
              value={inputs.description}
              setValue={(val) =>
                setInputs((prev) => {
                  return { ...prev, description: val };
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={addReportLoading}
              variant="contained"
              sx={{ mb: 2 }}
              onClick={handleSaveReport}
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default AddReport;
