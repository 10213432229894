import {
  Container,
  Typography,
  Stack,
  Avatar,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Tooltip,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { NavBackHeader, Page, RichEditor } from "src/components";
import { useAppContext } from "src/hooks";
import { Iconify } from "src/components";
import { useSnackbar } from "notistack";
import { is_number_with_special_char, set_to_localStorage } from "src/utils";
import { ALLOWED_IMAGE_TYPES } from "src/constants";
import { _update_user_profile, _upload_file } from "src/DAL";
import { s3baseUrl } from "src/config/config";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
const EditProfile = () => {
  const { dispatch_get_user_profile, dispatch_set_user_profile } =
    useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const user = dispatch_get_user_profile();
  const navigate = useNavigate();
  const [formInputs, setFormInputs] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    bio: "",
    email: "",
    gender: "",
    image: "",
  });
  const [file, setTFile] = useState("");
  const [loading, setLoading] = useState(false);
  const EditSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "First Name Too Short!")
      .max(16, "First Name Too Long!")
      .required("First name required"),
    lastName: Yup.string()
      .min(2, "Last Name too Short!")
      .max(16, "Last Name Too Long!")
      .required("Last name required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    phone: Yup.string()
      .min(5, "Please enter valid phone number!")
      .max(15, "Please enter valid phone number!")
      .required("Please enter phone number"),
    gender: Yup.string().required("Choose gender"),
  });
  // console.log(user);

  // -------------------------------------------------
  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handlePhoneChange = (e) => {
    const { target } = e;
    let txt = target.value;
    let result = is_number_with_special_char(txt);
    if (result === true) {
      setFormInputs({ ...formInputs, [target.name]: target.value });
    }
  };
  const handleFileChange = async (e) => {
    if (loading) {
      return;
    }
    let file = e.target.files[0];
    const { type } = file;
    let valid_type = false;
    console.log(file, "target-file");
    ALLOWED_IMAGE_TYPES.map((item, i) => {
      if (item === String(type)) {
        valid_type = true;
      }
    });
    if (!valid_type) {
      enqueueSnackbar("Please choose an image", { variant: "error" });
      return;
    }
    setTFile(file);
    setFormInputs({ ...formInputs, ["image"]: URL.createObjectURL(file) });
    //
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let image_path = user.image;
    let invalid = false;
    await EditSchema.validate(formInputs).catch((err) => {
      if (err) {
        err.errors.map((error) => {
          enqueueSnackbar(error, { variant: "error" });
          invalid = true;
        });
        return;
      }
    });
    if (invalid) {
      return;
    }
    setLoading(true);
    if (file) {
      const data = new FormData();
      data.append("directory", "images/");
      data.append("upload_file", file);
      const result = await _upload_file(data);
      if (result.code === 200) {
        const { path } = result;
        image_path = path;
      } else {
        setLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
        return;
      }
    }
    let user_profile = {
      ...user,
      ["first_name"]: formInputs.firstName,
      ["last_name"]: formInputs.lastName,
      ["email"]: formInputs.email,
      ["gender"]: formInputs.gender,
      ["biography"]: formInputs.bio,
      ["contact_number"]: formInputs.phone,
      ["image"]: image_path,
    };
    let payload = {
      first_name: formInputs.firstName,
      last_name: formInputs.lastName,
      contact_number: formInputs.phone,
      image: image_path,
      gender: formInputs.gender,
      biography: formInputs.bio,
    };
    const update_user_result = await _update_user_profile(
      payload,
      user.user_id
    );
    if (update_user_result.code === 200) {
      setLoading(false);
      dispatch_set_user_profile(user_profile);
      set_to_localStorage("user", user_profile);
      enqueueSnackbar("Profile Updated Successfully", { variant: "success" });
      navigate(-1);
    } else {
      setLoading(false);
    }
  };
  const getImgSrc = (path) => {
    if (!path) {
      return path;
    }
    if (path.includes("blob:")) {
      return path;
    }
    return s3baseUrl + path;
  };
  // -------------------------------------------------
  useEffect(() => {
    setFormInputs({
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      bio: user.biography,
      phone: user.contact_number,
      gender: user.gender,
      image: user.image,
    });
  }, [user]);
  // -------------------------------------------------
  return (
    <Page title="Edit Profile">
      <Container maxWidth="xl">
        <div className="mb-4">
          <NavBackHeader heading="Edit Profile" />
        </div>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Container maxWidth="sm">
            <Stack direction="column" className="ms-auto" spacing={3}>
              <div className="centered-col">
                <div className="relative">
                  <Avatar
                    sx={{ width: "7rem", height: "7rem" }}
                    src={getImgSrc(formInputs.image)}
                    alt="user-image"
                  />
                  <div role="button" className="img-upload-btn">
                    <Tooltip
                      placement="top"
                      title={
                        formInputs.image == ""
                          ? "Add picture"
                          : "Update picture"
                      }
                    >
                      <label htmlFor="icon-button-file">
                        <input
                          className="d-none"
                          accept="image/png,image/jpg,image/jpeg"
                          id="icon-button-file"
                          type="file"
                          value=""
                          onChange={handleFileChange}
                        />
                        <IconButton
                          disabled={loading}
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <Iconify
                            sx={{ width: "1rem", height: "1rem" }}
                            icon="fluent:camera-add-24-filled"
                          />
                        </IconButton>
                      </label>
                    </Tooltip>
                  </div>
                </div>
                <Typography sx={{ marginTop: "3px" }} fontWeight="bold">
                  {user.first_name + " " + user.last_name}{" "}
                </Typography>
                <Typography> {user.email} </Typography>
              </div>
              <div className="centered-col">
                {/* <Typography fontWeight='bold'>Bio</Typography> */}
                <TextField
                  label="Bio"
                  variant="outlined"
                  value={formInputs.bio}
                  onChange={handleChange}
                  fullWidth
                  name="bio"
                  multiline
                  maxRows={3}
                />
              </div>
              <div className="centered-row">
                <TextField
                  value={formInputs.firstName}
                  variant="outlined"
                  label="First name"
                  type="text"
                  name="firstName"
                  required
                  fullWidth
                  onChange={handleChange}
                />
              </div>
              <div className="centered-row">
                <TextField
                  value={formInputs.lastName}
                  variant="outlined"
                  label="Last name"
                  required
                  type="text"
                  name="lastName"
                  fullWidth
                  onChange={handleChange}
                />
              </div>
              <div className="centered-row">
                <TextField
                  label="Phone number"
                  name="phone"
                  required
                  value={formInputs.phone}
                  fullWidth
                  onChange={handlePhoneChange}
                  type="text"
                />
                {/* <NumberFormat
                  label='Phone number'
                  name='phone'
                  customInput={TextField}
                  value={formInputs.phone}
                  fullWidth
                  onChange={handleChange}
                  type='tel'
                  required
                  allowNegative={false}
                  // isAllowed={(v, s) => console.log(v, s)}
                /> */}
              </div>

              <div className="centered-row">
                <FormControl>
                  <FormLabel
                    sx={{ width: "80vw" }}
                    id="gender-select-radio-buttons-label"
                  >
                    Gender
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="gender-select-radio-buttons-label"
                    name="row-radio-buttons-group"
                    value={formInputs.gender}
                    onChange={(e) =>
                      setFormInputs({ ...formInputs, gender: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value="male"
                      control={<Radio value="male" />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="female"
                      control={<Radio value="female" />}
                      label="Female"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="centered-row">
                <LoadingButton
                  sx={{ mb: 3 }}
                  loading={loading}
                  type="submit"
                  variant="contained"
                >
                  Save
                </LoadingButton>
              </div>
            </Stack>
          </Container>
        </form>
      </Container>
    </Page>
  );
};

export default EditProfile;
