import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Button,
  Checkbox,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { filter } from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useState } from "react";
import { _editGroupChat, _getChatTeamList } from "src/DAL";
import { MuiDrawer, Scrollbar } from "src/components";
import ImageSelector from "src/components/ImageSelector";
import StyledChatSearch from "src/components/search/StyledChatSearch";
import { s3baseUrl } from "src/config";
import { useAppContext } from "src/hooks";
import { upload_image } from "src/utils";

function GroupInfoDrawer({ isInfoDrawerOpen, setIsInfoDrawerOpen }) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    currentChat,
    dispatch_get_user_profile,
    getModifiedChatItem,
    setCurrentChat,
    setChatList,
    socketEmit,
  } = useAppContext();
  const [groupName, setGroupName] = useState(currentChat.label);
  const [oldImage, setOldImage] = useState(currentChat.image);
  const [checked, setChecked] = useState({});
  const [groupImage, setGroupImage] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [teamList, setTeamList] = useState([]);
  const [teamLoading, setTeamLoading] = useState(false);
  useEffect(() => {
    if (currentChat) {
      // setGroupImage(currentChat.image);
      setGroupName(currentChat.label);
    }
  }, [currentChat]);
  const fetchTeam = async () => {
    setTeamLoading(true);
    try {
      const result = await _getChatTeamList();
      if (result.code === 200) {
        setTeamList(
          result.chat_team_list
          // .map((member) => {
          //   return {
          //     email: member.email,
          //     first_name: member.first_name,
          //     image: member.image,
          //     last_name: member.last_name,
          //     role: member.role,
          //     status: member.status,
          //     user_id: member.user_id,
          //     user_status: member.user_status,
          //   };
          // })
        );
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " error catched while getting the team");
    } finally {
      setTeamLoading(false);
    }
  };
  const getFilteredList = (data) => {
    if (!data) {
      return [];
    }
    if (searchValue === "") {
      return data;
    }
    if (searchValue !== "") {
      let filtered = handleLocalChatSearch(searchValue, data);
      return filtered;
    }
  };
  const handleLocalChatSearch = (input, array) => {
    const get_merged = (item) => {
      return `${item.first_name} ${item.last_name}`;
    };
    let filtered = filter(
      array,
      (item) =>
        get_merged(item).toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
    return filtered;
  };
  const handleToggle = (item) => () => {
    if (!checked[item.user_id]) {
      let temp = item;
      delete temp.allow_add_project;
      delete temp.allow_drag_drop;
      delete temp._id;
      delete temp.leave_reason;
      setChecked((prev) => {
        return {
          ...prev,
          [item.user_id]: temp,
        };
      });
    } else {
      let temp = checked;
      delete temp[item.user_id];
      setChecked({ ...temp });
    }
  };
  /////////////////Update Group//////////////////////////////////
  const [updateLoading, setUpdateLoading] = useState(false);
  const handleUpdateGroup = async (e) => {
    e.preventDefault();
    setUpdateLoading(true);
    try {
      if (Object.keys(checked).length == 0) {
        enqueueSnackbar("Please select atleast one member", {
          variant: "error",
        });
        return;
      }
      if (groupName.trim() == "") {
        enqueueSnackbar("Please provide valid group name", {
          variant: "error",
        });
        return;
      }
      let image = oldImage;
      if (groupImage) {
        const imageResult = await upload_image(groupImage);
        if (imageResult.code == 200) {
          image = imageResult.path;
        }
      }
      const req_obj = {
        group_name: groupName,
        group_image: image,
        start_time: "",
        end_time: "",
        team: Object.values(checked),
      };
      const result = await _editGroupChat(currentChat._id, req_obj);
      if (result.code == 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        setCurrentChat(getModifiedChatItem(result.group));
        setChatList((prev) => {
          return prev.map((chat) => {
            if (chat._id == result.group._id) {
              return { ...getModifiedChatItem(result.group) };
            } else {
              return { ...chat };
            }
          });
        });
        setIsInfoDrawerOpen(false);
        socketEmit("updateNewChat", result.group);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " error catched while updating the group");
    } finally {
      setUpdateLoading(false);
    }
  };

  ///////////////////////////////////////////////////////////////

  useEffect(() => {
    if (isInfoDrawerOpen) {
      fetchTeam();
      currentChat.user.map((member) => {
        delete member._id;
        setChecked((prev) => {
          return {
            ...prev,
            [member.user_id]: member,
          };
        });
      });
    }
  }, [isInfoDrawerOpen]);
  return (
    <MuiDrawer
      isOpen={isInfoDrawerOpen}
      paperProps={{ width: { xs: 1, sm: 400 } }}
      onClose={() => setIsInfoDrawerOpen(false)}
      title="Group Info"
      bottomComponent={
        currentChat?.creator_id == dispatch_get_user_profile().user_id && (
          <div
            className="d-flex justify-content-center"
            style={{ paddingBottom: "10px" }}
          >
            <LoadingButton
              variant="contained"
              loading={updateLoading}
              onClick={handleUpdateGroup}
            >
              Update
            </LoadingButton>
          </div>
        )
      }
    >
      {currentChat?.creator_id == dispatch_get_user_profile().user_id && (
        <div>
          <ImageSelector
            file={groupImage}
            setFile={setGroupImage}
            currentImage={oldImage}
            removeCurrentImage={() => setOldImage("")}
          />
          <TextField
            label="Group Name"
            fullWidth
            required
            name="name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            type="text"
            sx={{ my: 2 }}
          />
          <div className="search-user-list row  mb-2">
            <span
              className="col-12"
              style={{ paddingLeft: "30px", paddingRight: "30px" }}
            >
              <StyledChatSearch
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeHolder="Search Members..."
              />
            </span>
          </div>
          {!teamLoading && (
            <Scrollbar sx={{ height: "200px" }}>
              <List
                dense
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                {getFilteredList(teamList).map((item) => {
                  return (
                    <ListItem
                      key={item._id}
                      onClick={handleToggle(item)}
                      secondaryAction={
                        <Checkbox
                          edge="end"
                          checked={checked[item.user_id] ? true : false}
                        />
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemAvatar>
                          {item.image !== "" ? (
                            <Tooltip
                              arrow
                              title={`${item.first_name} ${item.last_name}`}
                            >
                              <Avatar
                                alt="Remy Sharp"
                                src={s3baseUrl + item.image}
                                className="chat-avatar"
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              arrow
                              title={`${item.first_name} ${item.last_name}`}
                            >
                              <Avatar className="chat-avatar">
                                {item.first_name.substring(0, 1)}
                              </Avatar>
                            </Tooltip>
                          )}
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography>
                              {item.first_name + " " + item.last_name}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Scrollbar>
          )}
        </div>
      )}
      {currentChat.creator_id !== dispatch_get_user_profile().user_id && (
        <div>
          <Typography variant="h4" sx={{ textAlign: "center", mb: 2 }}>
            {currentChat.label}
          </Typography>
          {oldImage && (
            <div className="d-flex justify-content-center mb-2">
              <img
                src={oldImage}
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "contain",
                }}
              />
            </div>
          )}
          <div className="search-user-list row  mb-2">
            <span
              className="col-12"
              style={{ paddingLeft: "30px", paddingRight: "30px" }}
            >
              <StyledChatSearch
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeHolder="Search Members..."
              />
            </span>
          </div>
          <Scrollbar sx={{ height: "300px" }}>
            <List
              dense
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
              }}
            >
              {getFilteredList(currentChat.user).map((item) => {
                return (
                  <ListItem key={item._id} disablePadding>
                    <ListItemButton>
                      <ListItemAvatar>
                        {item.image !== "" ? (
                          <Tooltip
                            arrow
                            title={`${item.first_name} ${item.last_name}`}
                          >
                            <Avatar
                              alt="Remy Sharp"
                              src={s3baseUrl + item.image}
                              className="chat-avatar"
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip
                            arrow
                            title={`${item.first_name} ${item.last_name}`}
                          >
                            <Avatar alt="Remy Sharp" className="chat-avatar">
                              {item.first_name.substring(0, 1)}
                            </Avatar>
                          </Tooltip>
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography>
                            {item.first_name + " " + item.last_name}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Scrollbar>
        </div>
      )}
    </MuiDrawer>
  );
}

export default GroupInfoDrawer;
