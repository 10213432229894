import React, { useState } from "react";
import { s3baseUrl } from "src/config";
import {
  AvatarGroup,
  Avatar,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Stack,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import { Iconify, UserAvatar } from "src/components";
// ---------------------------------------------------------
const avatar_size = { height: 33, width: 33 };
const menu_item_size = { height: "1.1rem", width: "1.1rem" };
// ---------------------------------------------------------
const MembersButton = ({
  projectTeam = [],
  taskTeam = [],
  allowOpen = true,
  onChange = () => {},
  onOpen = () => {},
  loading = false,
  canEditTask,
}) => {
  const [open, setOpen] = useState(null);
  // ---------------------------------------------------------
  const handleClose = () => {
    setOpen(null);
  };
  const handleOpen = (e) => {
    if (!allowOpen) {
      console.log("not allowed");
      return;
    }
    if (onOpen) {
      onOpen();
    }
    if (canEditTask) {
      setOpen(e.currentTarget);
    }
  };
  const handleClick = (e, _user, is_selected, _action) => {
    e.stopPropagation();
    console.log(taskTeam, "on click taskTeam");
    console.log(_user, "on click user");
    onChange(_user, taskTeam, is_selected, _action);
  };
  const isUserSelected = (_user) => {
    let found = false;
    taskTeam.map((member) => {
      if (member._id === _user._id) {
        found = true;
      }
    });
    return found;
  };
  const onRemoveUser = (_user) => {
    console.log(_user, "on remove");
  };
  // ---------------------------------------------------------
  const getAvatarUserProps = (user) => {
    let def_res = {
      first_name: "",
      image: "",
      last_name: "",
      email: "",
    };
    if (!user) {
      return def_res;
    } else {
      return {
        first_name: user.first_name,
        image: user.image,
        last_name: user.last_name,
        email: user.email,
      };
    }
  };
  // ---------------------------------------------------------
  return (
    <div>
      <div
        className="sidebar-button d-flex align-items-center"
        onClick={handleOpen}
      >
        Members
        {taskTeam.length == 0 && (
          <IconButton
            disabled={!allowOpen}
            sx={{
              bgcolor: (theme) => theme.palette.action.hover,
              color: (theme) => theme.palette.primary.main,
              width: "1.9rem",
              height: "1.9rem",
            }}
            size="small"
          >
            <Iconify
              icon="ooui:user-add-ltr"
              sx={{ width: "1rem", height: "1rem" }}
            />
          </IconButton>
        )}
        {taskTeam.length > 0 && (
          <AvatarGroup sx={{ ml: "20px" }} max={2}>
            {taskTeam?.map((user, index) => {
              const { first_name, image, last_name, email } =
                getAvatarUserProps(user);
              return (
                <UserAvatar
                  key={user._id ? user._id : index}
                  name={first_name}
                  sx={{ height: "25px", width: "25px" }}
                  image={image}
                  alt={first_name}
                />
              );
            })}
          </AvatarGroup>
        )}
      </div>
      <Menu
        disableEnforceFocus
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          p: 0.5,
          mt: 1,
        }}
      >
        {loading && (
          <MenuItem
            sx={{
              typography: "body2",
              px: 1,
              mx: 0.5,
              borderRadius: 0.5,
              mr: 0,
              marginInline: "3px",
              width: "100%",
              marginTop: "1px",
              marginBottom: "1px",
            }}
          >
            <CircularProgress size="1rem" />
          </MenuItem>
        )}
        {!loading &&
          projectTeam?.map((user, index) => {
            const { email, first_name, image, last_name, status, role } = user;
            return (
              <div key={index} className="centered-row">
                <MenuItem
                  selected={isUserSelected(user)}
                  onClick={(e) =>
                    handleClick(e, user, isUserSelected(user), "add")
                  }
                  sx={{
                    typography: "body2",
                    px: 1,
                    mx: 0.5,
                    borderRadius: 0.5,
                    mr: 0,
                    marginInline: "3px",
                    width: "100%",
                    marginTop: "1px",
                    marginBottom: "1px",
                    flexDirection: "column",
                  }}
                >
                  <Tooltip title={role}>
                    <Stack
                      alignItems="center"
                      sx={{ width: 1 }}
                      direction="row"
                      spacing={2}
                    >
                      <UserAvatar
                        image={image}
                        alt={first_name}
                        name={first_name + " " + last_name}
                        sx={{ width: 26, height: 26, mr: 0.5 }}
                      />
                      {first_name + " " + last_name}
                      {isUserSelected(user) && (
                        <span
                          onClick={(e) =>
                            handleClick(e, user, isUserSelected(user), "remove")
                          }
                          style={{ marginLeft: "auto" }}
                        >
                          <Iconify
                            sx={{
                              ml: "10px",
                              borderRadius: "50%",
                              "&:hover": {
                                bgcolor: (theme) => theme.palette.error.lighter,
                              },
                              ...menu_item_size,
                            }}
                            icon="radix-icons:cross-circled"
                          />
                        </span>
                      )}
                    </Stack>
                  </Tooltip>
                  {/* <Stack
                    direction="row"
                    spacing={0.3}
                    sx={{ width: 1, px:4 }}
                    justifyContent="flex-start"
                  >
                    <Typography variant="body2" fontWeight="bold">
                      Role:
                    </Typography>
                    <Typography variant="body2">{user.role}</Typography>
                  </Stack> */}
                </MenuItem>
              </div>
            );
          })}
        {!loading && projectTeam?.length === 0 && (
          <MenuItem
            disabled
            sx={{
              px: 1,
              mx: 0.5,
              borderRadius: 0.5,
              mr: 0,
              marginInline: "3px",
              width: "100%",
              marginTop: "1px",
              marginBottom: "1px",
            }}
          >
            <Typography variant="body2">No Team</Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default MembersButton;
MembersButton.propTypes = {
  projectTeam: PropTypes.array,
  taskTeam: PropTypes.array,
  allowOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  onOpen: PropTypes.func,
};
