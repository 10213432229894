import React, { useEffect, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularLoader } from ".";

function TaskLinkController() {
  const params = useParams();
  const navigate = useNavigate();

  const navigateToProject = () => {
    if (!params.project_id || params.project_id === "undefined") {
      navigate("/workspaces", { replace: true });
      return;
    }
    navigate(`/workspaces/projects/${params.project_id}`, {
      state: {
        scroll_to_task: params.task_id,
        expand_section_id: params.section_id,
        task_id: params.task_id,
      },
    });
  };
  useEffect(() => {
    navigateToProject();
    // navigate('/workspaces')
  }, []);
  return (
    <div style={{ minHeight: "100vh" }}>
      <CircularLoader />
    </div>
  );
}

export default TaskLinkController;
