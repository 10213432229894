import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Box,
  Card,
  Typography,
  Stack,
  Tooltip,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  IconButton,
  ButtonBase,
  AvatarGroup,
  Button,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import { baseUri, s3baseUrl } from "src/config/config";
import MenuPopover from "./MenuPopover";
import Iconify from "./Iconify";
import { useAppContext } from "src/hooks";
import Label from "./Label";
import { get_from_localStorage } from "src/utils";
import { UserAvatar } from "src/components";

// ----------------------------------------------------------------------

const ProjectImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});
const MenuContainer = styled("div")({
  top: 0,
  right: 0,
  position: "absolute",
  zIndex: 2,
});

// ----------------------------------------------------------------------

WorkspaceCard.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }),
  onClickMenuItem: PropTypes.func.isRequired,
};

export default function WorkspaceCard({
  item,
  menuOptions,
  onClickMenuItem,
  onClickTeam,
  onDeleteButtonClick,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(null);
  const {
    dispatch_set_current_selected_item,
    dispatch_get_user_profile,
    setLimitReachedDialogOpen,
  } = useAppContext();
  // ------------------------------------------------------
  const handleClose = () => {
    setOpen(null);
  };

  const handleClickItem = (option, index) => {
    onClickMenuItem(option, index);
    handleClose();
  };
  const handleTeamClick = (e) => {
    e.stopPropagation();
    console.log(item);
    // dispatch_set_current_selected_item(item);
    onClickTeam(item);
  };
  const handleOpenOptions = (e, item) => {
    console.log(item, "item");
    setOpen(e.currentTarget);
    dispatch_set_current_selected_item(item);
  };
  const handleNav = () => {
    if (item.access) {
      navigate(`/workspaces/${item._id}`, { state: { workspace_data: item } });
    } else {
      if (item.user_id == dispatch_get_user_profile().user_id) {
        setLimitReachedDialogOpen(true);
      } else {
        enqueueSnackbar(
          "Access for this workspace is temporarily blocked, Please contact the admin of this workspace",
          {
            variant: "error",
          }
        );
      }
    }
  };

  // ------------------------------------------------------
  return (
    <>
      <Card sx={{ height: "100%" }}>
        {!item.status && (
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 4,
            }}
          >
            <div className="d-flex align-items-center justify-content-center gap-2 h-100">
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() =>
                  navigate(`/workspaces/edit/${item._id}`, {
                    state: { item: item },
                  })
                }
              >
                Activate
              </Button>
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={onDeleteButtonClick}
              >
                Delete
              </Button>
            </div>
          </div>
        )}
        <MenuContainer id="menu-container">
          <IconButton
            sx={{ bgcolor: "rgba(255, 255, 255, 0.438)" }}
            ref={anchorRef}
            onClick={(e) => handleOpenOptions(e, item)}
          >
            <Iconify
              sx={{ color: "black" }}
              // icon='mi:options-horizontal'
              icon="fluent:more-vertical-16-filled"
            />
          </IconButton>
          <MenuPopover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleClose}
            sx={{
              p: 0.5,
              mt: 1.5,
              ml: 0.75,
              width: "7rem",
              "& .MuiMenuItem-root": {
                typography: "body2",
                borderRadius: 0.75,
              },
            }}
          >
            {menuOptions.map((option, index) => {
              return (
                <MenuItem
                  onClick={() => handleClickItem(option, index)}
                  key={index}
                  sx={{ width: "6.5rem" }}
                >
                  {option.label}
                </MenuItem>
              );
            })}
          </MenuPopover>
        </MenuContainer>
        <div onClick={handleNav} style={{ cursor: "pointer" }}>
          <Box sx={{ pt: "100%", position: "relative" }}>
            {item.image ? (
              <ProjectImgStyle
                alt={"workspace image"}
                src={s3baseUrl + item.image}
                className="image-hov"
              />
            ) : (
              <ProjectImgStyle
                alt={"name"}
                src="https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg"
                className="image-hov"
              />
            )}
          </Box>
          <Stack spacing={1} sx={{ p: 2, pt: 3.5 }}>
            <Tooltip title="Workspace">
              <Typography
                variant="body1"
                fontWeight={"bold"}
                noWrap
                onClick={() => null}
              >
                {item.name}
              </Typography>
            </Tooltip>
            <div className="d-flex justify-content-between">
              <Tooltip title="Projects">
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  justifyContent="start"
                  sx={{ width: "50%" }}
                >
                  {/* <Typography
                variant="body2"
                // noWrap
                fontWeight="bold"
                onClick={() => null}
                component="span"
              >
                Projects
              </Typography> */}
                  <Iconify
                    sx={{ height: 18, width: 18 }}
                    icon="carbon:ibm-cloud-projects"
                  />
                  <Typography
                    variant="body2"
                    noWrap
                    onClick={() => null}
                    component="span"
                  >
                    {item.project}
                  </Typography>
                </Stack>
              </Tooltip>
              <Tooltip title="Team">
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  justifyContent="start"
                  sx={{ width: "50%" }}
                >
                  {/* <Typography
                variant="body2"
                // noWrap
                fontWeight="bold"
                onClick={() => null}
                component="span"
              >
                Members
              </Typography> */}
                  <Iconify sx={{ height: 18, width: 18 }} icon="ri:team-fill" />
                  <Typography
                    variant="body2"
                    noWrap
                    onClick={() => null}
                    component="span"
                  >
                    {item.member}
                  </Typography>
                </Stack>
              </Tooltip>
            </div>
            {/* {item.user?.name && item.role!=="admin" && ( */}
            <Tooltip title="Admin">
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                justifyContent="start"
              >
                {/* <Typography
                  variant="body2"
                  // noWrap
                  fontWeight="bold"
                  onClick={() => null}
                  component="span"
                >
                  Admin
                </Typography> */}
                <Iconify
                  sx={{ height: 18, width: 18 }}
                  icon="eos-icons:admin"
                />
                <Typography
                  variant="body2"
                  noWrap
                  onClick={() => null}
                  component="span"
                  sx={{ ml: 1 }}
                >
                  {item.user?.name}
                </Typography>
              </Stack>
            </Tooltip>
            {/* )} */}
          </Stack>
          <Stack spacing={1} sx={{ pb: 2, px: 2 }}>
            {item.team.length !== 0 && (
              <Stack
                justifyContent="flex-end"
                direction="row"
                alignItems="center"
                spacing={1}
                onClick={() => {}}
              >
                <Tooltip title="Project team">
                  <div className="avatar-container">
                    <AvatarGroup
                      onClick={handleTeamClick}
                      sx={{ height: 33, width: 33 }}
                      max={3}
                    >
                      {item.team.map((user) => {
                        const { first_name, last_name, email, image, status } =
                          user;
                        return (
                          <UserAvatar
                            key={user._id}
                            name={first_name}
                            image={image}
                            alt={first_name}
                          />
                        );
                      })}
                    </AvatarGroup>
                  </div>
                </Tooltip>
              </Stack>
            )}
          </Stack>
          {/* </Tooltip> */}
        </div>
        <Tooltip title="Your Role">
          <div className="role-bar">
            <Label color="success" variant="ghost">
              {item.role ? item.role : "undefined"}
            </Label>
          </div>
        </Tooltip>
        {/* {!item.status && (
          <Tooltip title="This workspace is not active">
            <div className="status-bar">
              <Label color="error" variant="ghost">
                Inactive
              </Label>
            </div>
          </Tooltip>
        )} */}
      </Card>
    </>
  );
}
