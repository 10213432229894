import { get_from_localStorage, invokeApi } from "src/utils";
export const _getDashboardData = (data) => {
  const requestObj = {
    path: `api/dashboard/get_dashboard_data`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_caldendar_tasks = (data) => {
  const requestObj = {
    path: `api/dashboard/get_dashboard_calendar_data_for_web`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
