import { invokeApi } from "src/utils";
import { get_from_localStorage } from "src/utils";

export const _get_support_tickets_list = (data, page, limit) => {
  const reqObj = {
    method: "POST",
    path: `api/support_ticket/list_support_ticket_by_user?page=${page}&limit=${limit}`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(reqObj);
};

export const _add_support_ticket = (data) => {
  const reqObj = {
    method: "POST",
    path: `api/support_ticket/add_support_ticket`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(reqObj);
};

export const _delete_support_ticket = (id) => {
  const reqObj = {
    method: "DELETE",
    path: `api/support_ticket/delete_support_ticket/${id}`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(reqObj);
};
export const _close_support_ticket = (id) => {
  const reqObj = {
    method: "GET",
    path: `api/support_ticket/close_support_ticket/${id}`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(reqObj);
};
export const _add_support_ticket_comment = (data) => {
  const reqObj = {
    method: "POST",
    path: `api/support_ticket_comment/add_support_ticket_comment`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(reqObj);
};

export const _delete_support_ticket_comment = (id) => {
  const reqObj = {
    method: "DELETE",
    path: `api/support_ticket_comment/delete_support_ticket_comment/${id}`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(reqObj);
};

export const _support_ticket_details = (id) => {
  const reqObj = {
    method: "GET",
    path: `api/support_ticket/detail_support_ticket/${id}`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(reqObj);
};

export const _delete_message = (id) => {
  const reqObj = {
    method: "DELETE",
    path: `api/support_ticket_comment/delete_support_ticket_comment/${id}`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(reqObj);
};
