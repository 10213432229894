import { invokeApi } from "src/utils";
import { get_from_localStorage } from "src/utils";

export const _subscription_listing = (id) => {
  const reqObj = {
    method: "GET",
    path: `api/subscription/get_customer_subscription/${id}`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(reqObj);
};

export const _cancel_subscription = (data) => {
  const reqObj = {
    method: "POST",
    path: `app/subscription/cancle_subscription_plan`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(reqObj);
};
