import { get_from_localStorage, invokeApi } from "src/utils";

export const _getChatList = () => {
  const requestObj = {
    path: `api/chat/only_chat_list?page=0&limit=100`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _getChatMessages = (chatId, page = 0) => {
  const requestObj = {
    path: `api/message/message_list/${chatId}?page=${page}&limit=15`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _sendMessage = (data) => {
  const requestObj = {
    path: `api/message/add_message`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _getChatTeamList = () => {
  const requestObj = {
    path: `api/chat/chat_team_list`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _addUserInChatList = (data) => {
  const requestObj = {
    path: `api/chat/add_chat`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _deleteMessage = (id) => {
  const requestObj = {
    path: `api/message/delete_message/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _deleteChat = (id) => {
  const requestObj = {
    path: `api/chat/delete_chat/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _getGroupChatList = (page = 0, limit = 100) => {
  const requestObj = {
    path: `api/chat/only_group_list?page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _createGroupChat = (data) => {
  const requestObj = {
    path: `api/chat/add_group`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _editGroupChat = (id, data) => {
  const requestObj = {
    path: `api/chat/edit_group/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _seenMessages = (id) => {
  const requestObj = {
    path: `api/message/seen_messages/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _editMessage = (data, id) => {
  const requestObj = {
    path: `api/message/edit_message/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _getAllChats = (page = 0, limit = 100) => {
  const requestObj = {
    path: `api/chat/chat_list?page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _getUnreadMessages = () => {
  const requestObj = {
    path: `api/chat/chat_unread_count`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};
