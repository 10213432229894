// GoogleAuth.js

import { _google_login } from "src/DAL";
import { googleClientId } from "src/config";

// Load the Google Sign-In API script
const loadGoogleScript = () => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/platform.js";
    script.onload = () => resolve();
    document.body.appendChild(script);
  });
};

// Initialize Google Sign-In
const initGoogleSignIn = () => {
  return new Promise((resolve) => {
    window.gapi.load("auth2", () => {
      window.gapi.auth2
        .init({
          client_id: googleClientId,
          scope:
            "email profile openid https://www.googleapis.com/auth/calendar",
          redirect_uri: "https://clientworkspace.dynamitedigital.info",
          fetch_basic_profile: true,
          response_type: "code",
        })
        .then(() => {
          resolve(window.gapi.auth2.getAuthInstance());
        });
    });
  });
};

export const signInWithGoogle = async () => {
  try {
    await loadGoogleScript();
    const authInstance = await initGoogleSignIn();
    const result = await authInstance.grantOfflineAccess({
      scope: "profile email openid https://www.googleapis.com/auth/calendar",
      prompt: "consent",
    });
    const user = authInstance.currentUser.get().getBasicProfile();
    const email = user.getEmail();
    return { email, result };
  } catch (error) {
    console.error("Error signing in with Google:", error);
  }
};

export const signOutFromGoogle = async () => {
  try {
    const authInstance = await initGoogleSignIn();
    if (authInstance) {
      await authInstance.signOut();
      console.log("User signed out from Google.");
    }
  } catch (error) {
    console.error("Error signing out from Google:", error);
  }
};
