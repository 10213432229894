import { useState } from "react";
import PropTypes from "prop-types";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Badge,
} from "@mui/material";
//
import Iconify from "./Iconify";
import { useAppContext } from "src/hooks";

// ----------------------------------------------------------------------
const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));
// const ListItemStyle = styled((props) => (
//   <ListItemButton disableGutters {...props} />
// ))(({ theme }) => ({
//   // fontSize: 16,
//   width: "90%",
//   margin: "auto",
//   ...theme.typography.body2,
//   height: 50,
//   position: "relative",
//   textTransform: "capitalize",
//   // marginLeft: theme.spacing(1),
//   marginBottom: theme.spacing(0.5),
//   marginTop: theme.spacing(0.5),
//   paddingLeft: theme.spacing(1),
//   // paddingRight: theme.spacing(1.5),
//   color: "black",
//   borderRadius: 10,

//   "&:hover": {
//     color: "#ffff",
//     // color: "#6dfc51",
//     width: "95%",

//     boxShadow:
//       "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
//     backgroundColor: theme.palette.primary.main,
//   },
//   "&:before": {
//     top: 0,
//     right: 0,
//     width: 3,
//     bottom: 0,
//     content: "''",
//     display: "none",
//     position: "absolute",
//     borderTopLeftRadius: 4,
//     borderBottomLeftRadius: 4,
//     backgroundColor: theme.palette.primary.main,
//   },
// }));
const arrowIosDownwardFill = "eva:arrow-ios-downward-fill";
const arrowIosForwardFill = "eva:arrow-ios-forward-fill";
const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
// const ListItemIconStyle = styled("div")(({ theme }) => ({
//   // fontWeight: 400,
//   marginRight: 15,
//   display: "flex",
//   borderRadius: "30%",
//   alignItems: "center",
//   // width: theme.spacing(8),
//   // height: theme.spacing(8),
//   justifyContent: "center",
//   // boxShadow: " rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;",
//   boxShadow:
//     "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",

//   // marginBottom: theme.spacing(2),
// }));
// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};
function NavItem({ item, active }) {
  const theme = useTheme();
  // const isActiveRoot = active(typeof item == "object" ? item.path : item);
  const isActiveRoot = item.children
    ? item.children.find((child) => active(child.path))
      ? true
      : false
    : active(item.path);
  const { title, path, icon, info, children, badge, badgeContent } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    "&:before": { display: "block" },
  };

  const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium",
  };
  // const activeRootStyle = {
  //   color: "#fff",
  //   fontWeight: "bold",
  //   width: "90%",
  //   margin: "auto",
  //   // boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
  //   // borderRadius: 2,
  //   // borderTopLeftRadius: 20,
  //   // borderBottomRightRadius: 20,
  //   boxShadow:
  //     "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
  //   bgcolor: alpha(
  //     theme.palette.primary.main,
  //     theme.palette.action.sidebarSelectedOpacity
  //   ),

  //   // "&:before": { display: "block" },
  // };

  // const activeSubStyle = {
  //   color: "text.primary",
  //   fontWeight: "fontWeightMedium",
  //   "&:hover": { color: "#FFFF" },
  // };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle
          // sx={{
          //   width: 35,
          //   height: 35,
          //   color: isActiveRoot
          //     ? (theam) => theme.palette.primary.main
          //     : "#919090",
          //   bgcolor: "#fff",
          // }}
          >
            {icon && icon}
          </ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {badge && (
            <Badge
              style={{ zIndex: 0 }}
              badge
              variant="dot"
              badgeContent={badgeContent}
              color="error"
            />
          )}
          {info && info}
          <Box sx={{ width: 16, height: 16, ml: 1 }}>
            <Iconify icon={open ? arrowIosDownwardFill : arrowIosForwardFill} />
          </Box>
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  // onClick={() =>
                  //   !active(path) && resetValues()
                  // }
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle
                  // sx={{
                  //   width: 35,
                  //   height: 35,
                  //   color: isActiveRoot
                  //     ? (theam) => theme.palette.primary.main
                  //     : "#919090",
                  //   bgcolor: "#fff",
                  // }}
                  >
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                        transition: (theme) =>
                          theme.transitions.create("transform"),
                        ...(isActiveSub && {
                          transform: "scale(2)",
                          bgcolor: "primary.main",
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {badge && (
        <Badge
          style={{ zIndex: 0 }}
          variant={!badgeContent ? "dot" : "standard"}
          badgeContent={badgeContent}
          color="error"
        />
      )}
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.func,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();

  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <Box {...other}>
      <List
        disablePadding
        sx={{ pb: 6 }}
        // sx={{ p: 1, pt: 0.4 }}
      >
        {navConfig().map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}
