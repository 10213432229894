import { Card, Tooltip } from "@mui/material";
import { formatISO, format, parseISO, isToday, isValid, parse } from "date-fns";
import { isPast } from "date-fns/esm";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import Iconify from "./Iconify";
import moment from "moment";
/* <<--------------------------------------------------------->> */
// const TaskDatePick = () => {
const TaskDatePick = ({ task, value, onChange, disabled, hasPortal }) => {
  /* <<--------------------------------------------------------->> */
  // const [value, setValue] = useState("00:00");
  // const [value, setValue] = useState("2022-08-17T19:00:00.000Z");
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };
  const handleChange = (_date) => {
    const fns_iso_date = formatISO(_date, {
      representation: "complete",
    });
    // console.log(fns_iso_date, "fns-iso-date");
    // // setValue(fns_iso_date);
    // let expired = isPast(parseISO(fns_iso_date));
    // console.log(expired, "expired?");
    // /* expired not working */
    // if (expired === true) {
    //   console.log("expired!");
    //   return;
    // }

    onChange(fns_iso_date);
    // handleClose();
  };

  const handleFilterTimes = (time) => {
    // const iso_time = formatISO(time, {
    //   representation: "complete",
    // });
    // // const past = isPast(parseISO(iso_time));
    // const past = moment(time).isBefore(moment());
    // if (past === true) {
    //   return false; /* disable times */
    // }
    // if (value === "") {
    //   return false; /* disable times */
    // }
    // let expired = isPast(parseISO(value));
    // if (expired === true) {
    //   return false; /* disable times */
    // }
    // return true;
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  return (
    <div id="task-date-pick">
      <DatePicker
        value={value ? value : ""}
        open={open}
        showTimeSelect
        filterTime={handleFilterTimes}
        onClickOutside={handleClose}
        timeIntervals={5}
        onChange={handleChange}
        selected={value ? parseISO(value) : new Date()}
        minDate={new Date()}
        disabled={disabled}
        portalId={hasPortal ? "date-picker-root-portal" : ""}
        customInput={
          <CustomPickerInput
            onOpen={handleOpen}
            onClose={handleClose}
            task={task}
            open={open}
            disabled={disabled}
          />
        }
      />
    </div>
  );
};

export default TaskDatePick;

const CustomPickerInput = React.forwardRef((props, ref) => {
  const { task, value, onOpen, open, onClose, disabled } = props;
  // console.log(task, "task-");
  const handleOpen = () => {
    if (disabled) {
      return;
    }
    onOpen();
  };
  const getFormattedDate = (_date) => {
    const date_format = "dd MMM yyyy hh:mm aaa";
    if (!_date) {
      return "Target date/time";
    }
    const formatted = format(parseISO(_date), date_format);

    return formatted;
  };
  if (open === true) {
    return (
      <span
        ref={ref}
        className="task-date"
        onClick={handleOpen}
        style={{
          color:
            isPast(parseISO(value)) &&
            task.task_status !== 1 &&
            task.task_status !== 3 &&
            task.task_status !== 4
              ? "red"
              : "",
        }}
      >
        {getFormattedDate(value)}
        {isPast(parseISO(value)) &&
          task.task_status !== 1 &&
          task.task_status !== 3 &&
          task.task_status !== 4 && (
            <Iconify
              icon="material-symbols:priority-high"
              sx={{
                height: "1rem",
                width: "1rem",
                color: "red",
              }}
            />
          )}
      </span>
    );
  }
  return (
    <Tooltip title="Target date/time">
      <span
        ref={ref}
        className="task-date"
        onClick={handleOpen}
        style={{
          color:
            isPast(parseISO(value)) &&
            task.task_status !== 1 &&
            task.task_status !== 3 &&
            task.task_status !== 4
              ? "red"
              : "",
        }}
      >
        {getFormattedDate(value)}
        {isPast(parseISO(value)) &&
          task.task_status !== 1 &&
          task.task_status !== 3 &&
          task.task_status !== 4 && (
            <Iconify
              icon="material-symbols:priority-high"
              sx={{
                height: "1rem",
                width: "1rem",
                color: "red",
              }}
            />
          )}
      </span>
    </Tooltip>
  );
});
