import {
  Container,
  Typography,
  Grid,
  Box,
  Card,
  Divider,
  Avatar,
  Stack,
  TextField,
  Backdrop,
  CircularProgress,
  Badge,
  styled,
  Button,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CountryPicker, Page, RichEditor } from "src/components";
import * as Yup from "yup";
import Displaydata from "./Displaydata";
// import { getTeamMemberDetail } from "src/DAL/teamMembers";
import { s3baseUrl } from "src/config/config";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { capitalCase } from "change-case";
import { get_from_localStorage, set_to_localStorage } from "src/utils";
import { dummy } from "src/assets";
import { ALLOWED_IMAGE_TYPES, country_list } from "src/constants";
import { useSnackbar } from "notistack";
import { _update_user_profile, _upload_file } from "src/DAL";
import { useAppContext } from "src/hooks";
import { LoadingButton } from "@mui/lab";

const ProfileCopy = () => {
  const navigate = useNavigate();
  const { dispatch_set_user_profile, dispatch_get_user_profile } =
    useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const profile = get_from_localStorage("user");
  const [tempDP, setTempDP] = useState("");
  const [inputs, setInputs] = useState({
    first_name: profile.first_name,
    last_name: profile.last_name,
    email: profile.email,
    gender: profile.gender,
    contact_number: profile.contact_number,
    image: profile.image,
    country_code: profile.country_code,
    country:
      country_list.find(
        (country) => country.phone == profile.country_code.replace("+", "")
      ) ?? country_list[0],
  });
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [edit, setEdit] = useState(true);
  const [file, setFile] = useState("");
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 30,
    height: 30,
    background: "",
    border: `2px solid white`,
  }));
  const handleFileChange = async (e) => {
    if (loading) {
      return;
    }
    let file = e.target.files[0];
    const { type, size } = file;
    console.log(size, "Ascakajschkasjc");
    if (size > 1048576) {
      enqueueSnackbar("Please select Image upto size of 1mb", {
        variant: "warning",
      });
      return;
    }
    let valid_type = false;
    ALLOWED_IMAGE_TYPES.map((item, i) => {
      if (item === String(type)) {
        valid_type = true;
      }
    });
    if (!valid_type) {
      enqueueSnackbar("Please choose an image", { variant: "error" });
      return;
    }
    setFile(file);
    setTempDP(URL.createObjectURL(file));
    // setInputs({ ...inputs, ["image"]: URL.createObjectURL(file) });
    //
  };
  const getCode = () => {
    if (inputs.country?.phone) {
      return `+${inputs.country.phone}`;
    }
    return "";
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handelEdit = (e) => {
    setEdit(!edit);
    setTempDP("");
    setInputs({
      first_name: profile.first_name,
      last_name: profile.last_name,
      email: profile.email,
      gender: profile.gender,
      contact_number: profile.contact_number,
      image: profile.image,
      country_code: getCode() ?? "",
      country:
        country_list.find(
          (country) => country.phone == profile.country_code.replace("+", "")
        ) ?? country_list[0],
    });
  };
  const [loading, setLoading] = useState(false);
  const EditSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, "First Name Too Short!")
      .max(16, "First Name Too Long!")
      .required("First name required"),
    last_name: Yup.string()
      .min(2, "Last Name too Short!")
      .max(16, "Last Name Too Long!")
      .required("Last name required"),
    contact_number: Yup.string()
      .min(5, "Please enter valid phone number!")
      .max(15, "Please enter valid phone number!")
      .required("Please enter phone number"),
    gender: Yup.string().required("Choose gender"),
  });
  const handleSubmit = async (e) => {
    e.preventDefault();

    let image_path = profile.image;
    let invalid = false;
    console.log(inputs);
    await EditSchema.validate(inputs).catch((err) => {
      if (err) {
        err.errors.map((error) => {
          enqueueSnackbar(error, { variant: "error" });
          invalid = true;
        });
        return;
      }
    });
    if (invalid) {
      return;
    }
    setLoading(true);
    if (file) {
      const data = new FormData();
      data.append("directory", "images/");
      data.append("upload_file", file);
      const result = await _upload_file(data);
      if (result.code === 200) {
        const { path } = result;
        image_path = path;
      } else {
        setLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
        return;
      }
    }
    let user_profile = {
      ...dispatch_get_user_profile(),
      ["first_name"]: inputs.first_name,
      ["last_name"]: inputs.last_name,
      ["gender"]: inputs.gender,
      ["contact_number"]: inputs.contact_number,
      ["image"]: image_path,
      ["country_code"]: getCode(),
    };
    setInputs({ ...inputs, image: image_path });
    let payload = {
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      contact_number: inputs.contact_number,
      image: image_path,
      gender: inputs.gender,
      country_code: getCode(),
    };
    const update_user_result = await _update_user_profile(
      payload,
      profile.user_id
    );
    if (update_user_result.code === 200) {
      setLoading(false);
      // setEdit(false);
      dispatch_set_user_profile(user_profile);
      set_to_localStorage("user", user_profile);
      console.log(inputs, "ascascgasjhcgasjhc");
      setFile();
      enqueueSnackbar("Profile Updated Successfully", { variant: "success" });
      // navigate(-1);
    } else {
      setLoading(false);
    }
  };
  return (
    <Page title="Profile">
      <Container maxWidth="xl">
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="mb-4">
          <Typography variant="h4" gutterBottom>
            Profile
          </Typography>
        </div>
        <Card
          sx={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            mb: 1,
            width: "100%",

            py: 10,
          }}
        >
          <Stack
            direction="row"
            spacing={0}
            justifyContent="center"
            sx={{ width: "80%", mx: "auto" }}
          >
            <Badge
              sx={{ m: "auto", mt: "30px" }}
              overlap="circular"
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              invisible={!edit}
              badgeContent={
                <SmallAvatar alt="upload image">
                  <IconButton
                    sx={{ fontSize: 20 }}
                    aria-label="upload picture"
                    component="label"
                  >
                    <ModeEditOutlinedIcon />
                    <input
                      hidden
                      accept=".jpg,.png"
                      name="logo"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </IconButton>
                </SmallAvatar>
              }
            >
              <Avatar
                alt={inputs?.full_name}
                variant="square"
                // src={
                //   "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                // }
                src={
                  tempDP
                    ? tempDP
                    : inputs.image
                    ? s3baseUrl + inputs.image
                    : dummy
                }
                sx={{
                  width: 250,
                  height: 300,
                  bgcolor: "#e6ecff",
                  color: "black",
                }}
              />
            </Badge>
            {!edit ? (
              <div>
                <Box sx={{ width: "80%", mx: "auto" }} margin={0}>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 0, sm: 0, md: 1 }}
                    mt={1}
                  >
                    <Grid item md={12} xs={12}>
                      <Displaydata
                        icon="material-symbols:mail-lock-outline"
                        text={inputs.email}
                        lable="Email"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Displaydata
                        icon="material-symbols:send-to-mobile-outline-rounded"
                        text={inputs.first_name + " " + inputs.last_name}
                        lable="Name"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Displaydata
                        icon="material-symbols:send-to-mobile-outline-rounded"
                        text={
                          inputs.country_code
                            ? inputs.country_code + " " + inputs.contact_number
                            : inputs.contact_number
                        }
                        lable="Contact No."
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Displaydata
                        icon="icons8:gender"
                        text={capitalCase(inputs.gender)}
                        lable="Gender"
                      />
                    </Grid>
                    <Grid item md={12} xs={12} mt={2}>
                      <Button variant="outlined" onClick={() => handelEdit()}>
                        Edit Profile
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            ) : (
              <form autoComplete="off">
                <Box sx={{ width: "80%", mx: "auto" }} margin={0}>
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 0, sm: 0, md: 3 }}
                    mt={1}
                  >
                    <Grid item md={12} xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        // required
                        disabled
                        name="first_name"
                        value={profile.email}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="First Name"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        required
                        name="first_name"
                        value={inputs.first_name}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Last Name"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        // fullWidth
                        required
                        name="last_name"
                        value={inputs.last_name}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CountryPicker
                        country={inputs.country}
                        setFormInputs={setInputs}
                      />
                    </Grid>
                    <Grid xs={4} item>
                      <TextField
                        value={getCode()}
                        label="Country code"
                        required
                        disabled
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        value={inputs.contact_number}
                        onChange={handleChange}
                        label="Phone number"
                        required
                        type="text"
                        variant="outlined"
                        name="contact_number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Gender
                        </InputLabel>
                        <Select
                          label="Gender"
                          name="gender"
                          value={inputs.gender}
                          onChange={handleChange}
                        >
                          <MenuItem value="male">Male</MenuItem>
                          <MenuItem value="female">Female</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Stack direction="row" spacing={2}>
                        <LoadingButton
                          loading={loading}
                          variant="outlined"
                          onClick={handleSubmit}
                        >
                          save
                        </LoadingButton>
                        {/* <Button variant="outlined" onClick={() => handelEdit()}>
                          cancel
                        </Button> */}
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            )}
          </Stack>
        </Card>
      </Container>
    </Page>
  );
};

export default ProfileCopy;
