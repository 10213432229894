import { useEffect, useState } from "react";
import PropTypes from "prop-types";

// material
import { Menu, Button, MenuItem, Typography } from "@mui/material";
import Iconify from "./Iconify";

// ----------------------------------------------------------------------

export default function ButtonSelect({
  title,
  selectedIndex,
  onFilterOptionClick,
  options,
  sx,
}) {
  const [dropDownWidth, setDropDownWidth] = useState("auto");
  const [open, setOpen] = useState(null);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const handleClick = (_option, _index) => {
    onFilterOptionClick(_option, _index);
    setOpen(null);
  };
  const calculateButtonWidth = () => {
    const btn = document.getElementById("mui-btn-select");
    if (btn) {
      const width = `${btn.clientWidth}px`;

      setDropDownWidth(width);
    }
  };
  useEffect(() => {
    calculateButtonWidth();
    return () => setDropDownWidth("auto");
  }, [open]);
  return (
    <>
      <Button
        className="btn-select"
        id="mui-btn-select"
        color="inherit"
        onClick={handleOpen}
        sx={{
          bgcolor: (theme) => theme.palette.action.hover,
          alignItems: "self-end",
          ...sx,
        }}
        endIcon={
          <Iconify
            sx={{
              color: (theme) => theme.palette.primary.main,
              height: "1.4rem",
              width: "1.4rem",
            }}
            icon={
              open
                ? "ic:baseline-keyboard-arrow-up"
                : "ic:baseline-keyboard-arrow-down"
            }
          />
        }
      >
        <Typography
          sx={{
            alignSelf: "self-end",
            color: (theme) => theme.palette.primary.main,
            //  mb: 0.2
          }}
          component="span"
          variant="subtitle2"
          fontWeight="bold"
        >
          {title ? title : `Title`}
          <Typography
            fontWeight="bold"
            sx={{
              // marginBottom: 0.2,
              display: "inline",
            }}
          >
            &nbsp;:
          </Typography>
          &nbsp;
        </Typography>
        <Typography
          component="span"
          noWrap
          variant="subtitle2"
          sx={{
            color: "text.primary",
            alignSelf: "self-end",
            // mb: 0.2
          }}
          // sx={{ color: 'text.secondary', alignSelf: 'self-end', mb: 0.2 }}
        >
          {options[selectedIndex]?.label}
        </Typography>
      </Button>
      <Menu
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            width: dropDownWidth,
            mt: 1,
            px: 0.7,
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            selected={index === selectedIndex}
            onClick={() => handleClick(option, index)}
          >
            <Typography variant="body2" noWrap>
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
ButtonSelect.propTypes = {
  title: PropTypes.string,
  selectedIndex: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  sx: PropTypes.object,
  onFilterOptionClick: PropTypes.func.isRequired,
};
