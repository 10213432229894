import { get_from_localStorage, invokeApi } from "src/utils";

export const _create_workspace_project = async (data) => {
  const requestObj = {
    path: `api/project/add_project`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _workspace_projects_listing = async (data, id) => {
  /* see postman for pagination */
  const requestObj = {
    path: `api/project/project_list/${id}?page=0&limit=100`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _workspace_favorite_projects_listing = async (id) => {
  const requestObj = {
    path: `api/project/fav_project_list/${id}?page=0&limit=100`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _update_workspace_project = async (data, id) => {
  const requestObj = {
    path: `api/project/update_project/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _delete_workspace_project = async (data, id) => {
  const requestObj = {
    path: `api/project/delete_project/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _update_project_team = async (data, id) => {
  const requestObj = {
    path: `api/project/update_team_in_project/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _project_detail = async (data, id) => {
  const requestObj = {
    path: `api/project/project_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _project_sections_filter = async (data, id, page, limit = 15) => {
  /* see postman for pagination */
  const requestObj = {
    path: `api/task/section_filter/${id}?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _project_tasks_filter = async (data, id) => {
  /* see postman for pagination */
  const requestObj = {
    path: `api/task/task_filter_list/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _load_more_tasks = async (path, data) => {
  const requestObj = {
    path: path,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _project_sections_old = async (data, id) => {
  const requestObj = {
    path: `api/section/all_section_list/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _project_sections = async (data, id) => {
  const requestObj = {
    path: `api/section/section_list/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _project_team = async (data, id) => {
  const requestObj = {
    path: `api/project/project_team/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _favourite_project = async (id) => {
  const requestObj = {
    path: `api/project/project_favourite/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};
