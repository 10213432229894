import * as React from "react";
import { useEffect } from "react";
import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  PieSeries,
  AccumulationDataLabel,
  Inject,
} from "@syncfusion/ej2-react-charts";
import { Browser } from "@syncfusion/ej2-base";
export let pointRender = (args) => {
  let selectedTheme = location.hash.split("/")[1];
  selectedTheme = selectedTheme ? selectedTheme : "material";
  if (selectedTheme === "fluent" || selectedTheme === "bootstrap5") {
    args.fill = seriesColor[args.point.index % 10];
  }
  if (selectedTheme.indexOf("dark") > -1) {
    if (selectedTheme.indexOf("material") > -1) {
      args.border.color = "#303030";
    } else if (selectedTheme.indexOf("bootstrap5") > -1) {
      args.border.color = "#212529";
    } else if (selectedTheme.indexOf("bootstrap") > -1) {
      args.border.color = "#1A1A1A";
    } else if (selectedTheme.indexOf("fabric") > -1) {
      args.border.color = "#201f1f";
    } else if (selectedTheme.indexOf("fluent") > -1) {
      args.border.color = "#252423";
    } else if (selectedTheme.indexOf("bootstrap") > -1) {
      args.border.color = "#1A1A1A";
    } else if (selectedTheme.indexOf("tailwind") > -1) {
      args.border.color = "#1F2937";
    } else {
      args.border.color = "#222222";
    }
  } else if (selectedTheme.indexOf("highcontrast") > -1) {
    args.border.color = "#000000";
  } else {
    args.border.color = "#FFFFFF";
  }
};
let seriesColor = [
  "#FFE066",
  "#FAB666",
  "#F68F6A",
  "#F3646A",
  "#CC555A",
  "#9C4649",
];
const DonutChart = ({ percentage, allTasks, completedTasks }) => {
  let data1;
  if (!allTasks) {
    data1 = [
      { x: "Completed", y: 0, text: "Completed" },
      {
        x: "Pending",
        y: 0.1,
        text: "pending",
      },
    ];
  } else {
    data1 = [
      { x: "Completed", y: completedTasks, text: "Completed" },
      {
        x: "Pending",
        y: allTasks - completedTasks,
        text: "pending",
      },
    ];
  }

  const onChartLoad = (args) => {
    document.getElementById("pie-chart").setAttribute("title", "");
  };
  const load = (args) => {
    let selectedTheme = location.hash.split("/")[1];
    selectedTheme = selectedTheme ? selectedTheme : "Material";
    args.accumulation.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    )
      .replace(/-dark/i, "Dark")
      .replace(/light/i, "Light")
      .replace(/contrast/i, "Contrast");
  };
  return (
    <div className="control-pane">
      <div className="control-section">
        <AccumulationChartComponent
          style={{ height: 100, width: 100, margin: 0 }}
          id="pie-chart"
          centerLabel={{
            text: percentage,
            textStyle: {
              fontWeight: "400",
              fontFamily: "Montserrat, sans-serif",
              size: Browser.isDevice ? "7px" : "15px",
            },
          }}
          enableSmartLabels={true}
          load={load.bind(this)}
          loaded={onChartLoad.bind(this)}
          pointRender={pointRender}
          enableBorderOnMouseMove={false}
          legendSettings={{ visible: false }}
        >
          <Inject services={[PieSeries, AccumulationDataLabel]} />
          <AccumulationSeriesCollectionDirective style={{ margin: 0 }}>
            <AccumulationSeriesDirective
              dataSource={data1}
              xName="x"
              yName="y"
              innerRadius="75%"
              border={{ width: 0.1 }}
              startAngle={0}
              //   startAngle={Browser.isDevice ? 30 : 62}
              dataLabel={{
                visible: true,
                position: "Outside",
                name: "text",
                font: { fontWeight: "600" },
                connectorStyle: { length: "100px", type: "Curve" },
              }}
              radius={"100%"}
              //   radius={Browser.isDevice ? "40%" : "70%"}
            />
          </AccumulationSeriesCollectionDirective>
        </AccumulationChartComponent>
      </div>
    </div>
  );
};
export default DonutChart;
