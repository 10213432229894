import React, { useEffect, useRef, useState } from "react";
import { makeStyles, styled } from "@mui/styles";
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Container,
  Stack,
  CircularProgress,
  AvatarGroup,
  Avatar,
  LinearProgress,
  linearProgressClasses,
  Button,
  Tooltip,
  MenuItem,
} from "@mui/material";
import { ChevronLeft, ChevronRight, CircleRounded } from "@mui/icons-material";
import { TaskCalendar } from "..";
import { DonutChart } from "./components";
import {
  Iconify,
  MenuPopover,
  MuiDialog,
  Page,
  PriorityPopover,
  Scrollbar,
  StatusPopover,
  UserAvatar,
} from "src/components";
import {
  _delete_user_workspace,
  _getDashboardData,
  _google_login,
} from "src/DAL";
import {
  get_from_localStorage,
  get_month_first_day,
  get_month_last_day,
  set_to_localStorage,
  truncateText,
} from "src/utils";
import { Link, useNavigate } from "react-router-dom";
import { Favprojects, UpgradeCardBg, noResults } from "src/assets";
import { useSnackbar } from "notistack";
import { signInWithGoogle, signOutFromGoogle } from "./components/googleAuth";
import { s3baseUrl } from "src/config";
import { useAppContext } from "src/hooks";
import DaashboardTaskCard from "./components/DaashboardTaskCard";
import moment from "moment-timezone";
import { LoadingButton } from "@mui/lab";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "rgba(255, 255, 255, 0.3)",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#fff",
  },
}));
const useStyles = makeStyles(() => ({
  dashboardContainer: {
    // position: "relative",
    display: "flex",
    overflowX: "auto",
    scrollbarWidth: "none", // Hide scrollbar for Firefox
    "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
    "&::-webkit-scrollbar": {
      display: "none", // Hide scrollbar for Chrome, Safari, and Opera
    },
    "&:hover $scrollButtonRight": {
      opacity: 1,
    },
    "&:hover $scrollButtonLeft": {
      opacity: 1,
    },
  },
  card: {
    flex: "0 0 190px",
    marginRight: "20px",
    padding: "10px 20px",
    cursor: "pointer",
  },
  addCard: {
    flex: "0 0 180px",
    marginRight: "20px",
    marginLeft: "5px",
    marginTop: "2%",
    marginBottom: "2%",
    padding: "20px",
    cursor: "pointer",
    minHeight: "96%",
  },
  scrollButtonRight: {
    position: "absolute",
    height: "100%",
    borderRadius: 0,
    // top: "50%",
    right: 0,
    // transform: "translateY(-50%)",
    opacity: 0,
    transition: "opacity 0.3s",
  },
  scrollButtonLeft: {
    zIndex: 1,
    position: "absolute",
    height: "100%",
    borderRadius: 0,
    // top: "50%",
    left: 0,
    // transform: "translateY(-50%)",
    opacity: 0,
    transition: "opacity 0.3s",
  },
}));
// ---------------------------------------------------------
const ImgContainer = styled("div")({
  width: "30%",
  // position: 'relative',
  margin: "auto",
});
const getTaskPercentage = (total, completed) => {
  if (total == 0) {
    return 0;
  } else {
    return Math.round(completed / (total / 100));
  }
};

const Dashboard = () => {
  const {
    dispatch_set_user_profile,
    dispatch_get_user_profile,
    dispatch_get_current_plan,
    disconnectGoogleDialogOpen,
    setDisconnectGoogleDialogOpen,
    googleConnectionLoading,
    setGoogleConnectionLoading,
    dispatch_set_plan_limits,
    dispatch_get_plan_limits,
    handleConnectionWithGoogleCalendar,
    handleDisconnectfromGoogleCalendar,
    startLoading,
    dashboardData,
    setDashboardData,
  } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [taskType, setTaskType] = useState("all");
  const [loading, setLoading] = useState(dashboardData ? false : true);
  const classes = useStyles();
  const dashboardRef = useRef(null);
  const [isScrollEnd, setIsScrollEnd] = useState(false);
  const [isScrollStart, setIsScrollStart] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [deleteWorkspaceLoading, setDeleteWorkspaceLoading] = useState(false);
  const [targetDeleteId, setTargetDeleteId] = useState();
  const [open, setOpen] = useState(null);
  const checkScrollEnd = () => {
    const { scrollLeft, scrollWidth, clientWidth } = dashboardRef.current;
    const isStart = scrollLeft == 0;
    setIsScrollStart(isStart);
    const isEnd = Math.round(scrollLeft + clientWidth) > scrollWidth - 10;
    setIsScrollEnd(isEnd);
  };
  useEffect(() => {
    if (dashboardData) {
      const dashboardContainer = dashboardRef.current;
      if (dashboardContainer?.scrollWidth == dashboardContainer?.clientWidth) {
        setIsScrollEnd(true);
      }
      dashboardContainer.addEventListener("scroll", checkScrollEnd);
      return () => {
        dashboardContainer.removeEventListener("scroll", checkScrollEnd);
      };
    }
  }, [dashboardData]);
  const scrollRight = () => {
    dashboardRef.current.scrollBy({
      left: 300, // Adjust the scroll amount as needed
      behavior: "smooth",
    });
  };
  const scrollLeft = () => {
    dashboardRef.current.scrollBy({
      left: -300, // Adjust the scroll amount as needed
      behavior: "smooth",
    });
  };
  const getColoredProjects = (projects) => {
    let tempProjects = projects;
    const colors = ["#717eee", "#ff7783", "#ffbb56", "#0ac97f"];

    let colorIndex = 0;

    tempProjects.forEach((project) => {
      if (project.image) {
        return;
      }
      project.bgColor = colors[colorIndex];
      colorIndex = (colorIndex + 1) % colors.length;
    });
    return tempProjects;
  };
  const getDashboardData = async () => {
    try {
      let first_day = get_month_first_day();
      let last_day = get_month_last_day();
      const data = { start_date: first_day, end_date: last_day };
      const result = await _getDashboardData(data);
      if (result.code == 200) {
        setDashboardData({
          ...result.dashboard,
          project_list: getColoredProjects(result.dashboard.project_list),
        });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while fetching dashboard data");
    } finally {
      setLoading(false);
    }
  };
  const getTasks = (data, type) => {
    let tasks = [];
    if (type == "all") {
      tasks = data;
    } else if (type == "pending") {
      tasks = data.filter((task) => task.task_status == 0);
    } else if (type == "inProgress") {
      tasks = data.filter((task) => task.task_status == 2);
    } else if (type == "completed") {
      tasks = data.filter((task) => task.task_status == 3);
    }
    return tasks;
  };
  const handleChangeTaskType = (type) => {
    if (taskType == type) {
      return;
    } else {
      setTaskType(type);
    }
  };
  const handleNavigate = (_task) => {
    console.log(_task, "tsk");
    if (!_task) {
      return;
    }
    navigate(`/workspaces/projects/${_task.project.id}`, {
      state: { task_id: _task._id },
    });
  };
  const handleDeleteWorkspace = async () => {
    try {
      setDeleteWorkspaceLoading(true);
      const result = await _delete_user_workspace(null, targetDeleteId._id);
      if (result.code == 200) {
        setDashboardData((prev) => {
          return {
            ...prev,
            workspace_list: prev.workspace_list.filter(
              (workspace) => workspace._id !== targetDeleteId._id
            ),
          };
        });
        enqueueSnackbar("Workspace deleted successfully", {
          variant: "success",
        });
        setDeleteDialogOpen(false);
        setOpen(null);
        setTargetDeleteId();
        dispatch_set_plan_limits({
          ...dispatch_get_plan_limits(),
          workspace_count: dispatch_get_plan_limits().workspace_count - 1,
        });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while deleting workspace");
    } finally {
      setDeleteWorkspaceLoading(false);
    }
  };
  const onEditWorkspace = () => {
    navigate(`/workspaces/edit/${targetDeleteId._id}`, {
      state: { item: targetDeleteId },
    });
  };
  useEffect(() => {
    getDashboardData();
  }, []);
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <CircularProgress />
      </div>
    );
  }
  return (
    <Page title="Dashboard">
      {dashboardData && (
        <Container maxWidth="xl" style={{ backgroundColor: "#fff" }}>
          <div className="row">
            <div className="col-md-8">
              <div style={{ position: "relative" }}>
                <Box className={classes.dashboardContainer} ref={dashboardRef}>
                  {!isScrollStart && (
                    <IconButton
                      className={classes.scrollButtonLeft}
                      onClick={scrollLeft}
                    >
                      <ChevronLeft />
                    </IconButton>
                  )}
                  <CustomCard key={Math.random()} type={"add"} />
                  {dashboardData.project_list == 0 && (
                    <div
                      style={{
                        width: "100%",
                        border: "3px dashed #00ab55",
                        textAlign: "center",
                        borderRadius: "15px",
                        marginTop: "2%",
                        marginBottom: "2%",
                        padding: "20px",
                        cursor: "pointer",
                        minHeight: "96%",
                        cursor: "default",
                      }}
                      className="d-flex flex-column justify-content-center align-items-center"
                    >
                      <div className="d-flex justify-content-center mb-2">
                        <img src={Favprojects} />
                      </div>
                      <Typography
                        fontWeight={600}
                        fontSize={"15px"}
                        color="primary"
                      >
                        Greetings!
                      </Typography>
                      <Typography fontSize={"12px"}>
                        This is where your favorite projects will appear. You're
                        not in any project yet, but you can create yours with
                      </Typography>
                      <Typography fontSize={"12px"}>
                        "Add New Workspace"
                      </Typography>
                    </div>
                  )}
                  {dashboardData.project_list.map((project, index) => (
                    <CustomCard
                      deleteWorkspace={() => {
                        setDeleteDialogOpen(true);
                      }}
                      onEditWorkspace={onEditWorkspace}
                      setTargetDeleteId={setTargetDeleteId}
                      open={open}
                      setOpen={setOpen}
                      key={project._id}
                      type={"workspace"}
                      project={project}
                      index={index}
                    />
                  ))}
                  {/* {dashboardData.workspace_count > 10 && (
                  <Button onClick={() => navigate("/workspaces")}>
                    View All
                  </Button>
                )} */}
                  {!isScrollEnd && (
                    <IconButton
                      className={classes.scrollButtonRight}
                      onClick={scrollRight}
                    >
                      <ChevronRight />
                    </IconButton>
                  )}
                </Box>
              </div>
              <Box sx={{ mt: 3 }}>
                {/* {dashboardData.web_calendar_tasks && ( */}
                <div className="pb-3">
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ marginBottom: "20px" }}
                  >
                    <Typography fontWeight="bold" fontSize="25px">
                      Calendar
                    </Typography>
                    {/* {!dispatch_get_user_profile().google_account_email && (
                        <LoadingButton
                          loading={googleConnectionLoading}
                          variant="outlined"
                          onClick={() => {
                            startLoading();
                            handleConnectionWithGoogleCalendar();
                          }}
                        >
                          Connect With Google{" "}
                          <Iconify
                            icon="logos:google-calendar"
                            sx={{ height: "28px", width: "28px", ml: 1 }}
                          />
                        </LoadingButton>
                      )}
                      {dispatch_get_user_profile().google_account_email && (
                        <Button
                          variant="outlined"
                          onClick={() => setDisconnectGoogleDialogOpen(true)}
                        >
                          Disconnect from Google{" "}
                          <Iconify
                            icon="logos:google-calendar"
                            sx={{ height: "28px", width: "28px", ml: 1 }}
                          />
                        </Button>
                      )} */}
                  </div>
                  <TaskCalendar events={dashboardData.web_calendar_tasks} />
                </div>
                {/* )} */}
              </Box>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-7">
                      <div className="d-flex justify-content-center">
                        <Card sx={{ width: "100%" }}>
                          <CardContent
                            sx={{ p: 1 }}
                            style={{ paddingBottom: "8px" }}
                          >
                            <div className="d-flex flex-column align-items-center justify-content-between">
                              <Typography
                                textAlign={"center"}
                                fontWeight={"bold"}
                              >
                                Progress
                              </Typography>
                              <DonutChart
                                percentage={`${getTaskPercentage(
                                  dashboardData.overall_task,
                                  dashboardData.completed_task_count +
                                    dashboardData.verified_task_count
                                )} %`}
                                allTasks={dashboardData.overall_task}
                                completedTasks={
                                  dashboardData.completed_task_count +
                                  dashboardData.verified_task_count
                                }
                              />
                              <div className="text-align-center">
                                <Typography fontWeight={"bold"}>
                                  {dashboardData.completed_task_count} Completed
                                </Typography>
                                <Typography fontWeight={"bold"}>
                                  {dashboardData.verified_task_count} Verified
                                </Typography>
                                <Typography>
                                  from {dashboardData.overall_task} tasks
                                </Typography>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    </div>
                    <div className="col-5 ps-0">
                      {/* <div className="d-flex flex-column justify-content-between align-items-center h-100 "> */}
                      <Stack
                        // spacing={0.3}
                        sx={{ height: "100%" }}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"space-between"}
                      >
                        <Card sx={{ minWidth: "100%", borderRadius: "5px" }}>
                          <CardContent
                            sx={{ p: 1, py: 0.5 }}
                            style={{ paddingBottom: "4px" }}
                          >
                            <div className="d-flex justify-content-between">
                              <Typography fontSize={15} fontWeight={"bold"}>
                                {dashboardData.pending_task_count}
                              </Typography>
                              <Iconify
                                icon="icon-park-outline:dot"
                                height={20}
                                width={20}
                                sx={{ color: "#FFC107" }}
                              />
                            </div>
                            <div>
                              <Typography fontSize={10}>
                                {dashboardData.pending_task_count <= 1
                                  ? "task "
                                  : "tasks "}
                                pending
                              </Typography>
                              {/* <Typography fontSize={10}></Typography> */}
                            </div>
                          </CardContent>
                        </Card>
                        <Card sx={{ minWidth: "100%", borderRadius: "5px" }}>
                          <CardContent
                            sx={{ p: 1, py: 0.5 }}
                            style={{ paddingBottom: "4px" }}
                          >
                            <div className="d-flex justify-content-between">
                              <Typography fontSize={15} fontWeight={"bold"}>
                                {dashboardData.inprogress_task_count}
                              </Typography>
                              <Iconify
                                icon="icon-park-outline:dot"
                                height={20}
                                width={20}
                                sx={{ color: "#3366FF" }}
                              />
                            </div>
                            <div>
                              <Typography fontSize={10}>
                                {dashboardData.inprogress_task_count <= 1
                                  ? "task"
                                  : "tasks"}{" "}
                                in progress
                              </Typography>
                              {/* <Typography fontSize={10}>progress</Typography> */}
                            </div>
                          </CardContent>
                        </Card>
                        <Card sx={{ minWidth: "100%", borderRadius: "5px" }}>
                          <CardContent
                            sx={{ p: 1, py: 0.5 }}
                            style={{ paddingBottom: "4px" }}
                          >
                            <div className="d-flex justify-content-between">
                              <Typography fontSize={15} fontWeight={"bold"}>
                                {dashboardData.ready_for_test}
                              </Typography>
                              <Iconify
                                icon="icon-park-outline:dot"
                                height={20}
                                width={20}
                                sx={{ color: "#a4bdfc" }}
                              />
                            </div>
                            <div>
                              <Typography fontSize={10}>
                                {dashboardData.ready_for_test <= 1
                                  ? "task "
                                  : "tasks "}
                                ready for testing
                              </Typography>
                              {/* <Typography fontSize={10}></Typography> */}
                            </div>
                          </CardContent>
                        </Card>
                        {/* <Card sx={{ minWidth: "100%", borderRadius: "5px" }}>
                          <CardContent
                            sx={{ p: 1, py: 0.5 }}
                            style={{ paddingBottom: "4px" }}
                          >
                            <div className="d-flex justify-content-between">
                              <Typography fontSize={15} fontWeight={"bold"}>
                                {dashboardData.verified_task_count}
                              </Typography>
                              <Iconify
                                icon="icon-park-outline:dot"
                                height={20}
                                width={20}
                                sx={{ color: "#969292" }}
                              />
                            </div>
                            <div>
                              <Typography fontSize={10}>
                                {dashboardData.verified_task_count <= 1
                                  ? "task"
                                  : "tasks"}{" "}
                                verified
                              </Typography>
                            </div>
                          </CardContent>
                        </Card> */}
                      </Stack>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                {/* <div className="col-12">
                  <Card
                    sx={{ my: 2 }}
                    style={{
                      // background: "rgb(3,50,0)",
                      // background:
                      //   "linear-gradient(90deg, rgba(3,50,0,1) 0%, rgba(0,171,85,1) 35%, rgba(147,236,159,1) 100%)",
                      background: `url(${UpgradeCardBg}),rgba(0,0,0,0.5)`,
                      backgroundBlendMode: "multiply",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <CardContent sx={{ color: "#fff" }}>
                      {dispatch_get_current_plan().subscription_plan_name !==
                        "advance" && (
                        <>
                          <Typography fontWeight={"bold"}>
                            Need More Access?
                          </Typography>
                          <Typography>
                            Upgrade your plan for more benifits.
                          </Typography>
                        </>
                      )}
                      <Stack
                        spacing={1}
                        onClick={() => {
                          if (
                            dispatch_get_current_plan()
                              .subscription_plan_name === "advance"
                          ) {
                            window.open(
                              `${
                                window.location.origin
                              }/plansLink?token=${get_from_localStorage(
                                "token"
                              )}`
                            );
                          }
                        }}
                        sx={{
                          cursor:
                            dispatch_get_current_plan()
                              .subscription_plan_name === "advance"
                              ? "pointer"
                              : "",
                        }}
                      >
                        <>
                          <Typography>Current Plan:</Typography>
                          <Typography
                            fontWeight={"bold"}
                            style={{ marginTop: 0 }}
                          >
                            {dispatch_get_current_plan().name}
                          </Typography>
                        </>
                        {dispatch_get_current_plan().subscription_plan_name !==
                          "advance" && (
                          <Button
                            variant="contained"
                            // component={Link}
                            // to={{
                            //   pathname: `/plansLink?token=${get_from_localStorage(
                            //     "token"
                            //   )}`,
                            //   state: { currentPlan: "saaf" },
                            // }}
                            // target="_blank"
                            // rel="noopener noreferrer"
                            onClick={() => {
                              window.open(
                                `${
                                  window.location.origin
                                }/plansLink?token=${get_from_localStorage(
                                  "token"
                                )}`
                              );
                            }}
                          >
                            Upgrade
                          </Button>
                        )}
                      </Stack>
                    </CardContent>
                  </Card>
                </div> */}
                <div className="col-12">
                  <div>
                    <Card sx={{ mt: 2 }}>
                      <CardContent
                        sx={{ p: 1 }}
                        style={{ paddingBottom: "8px" }}
                      >
                        <Typography
                          fontWeight={"bold"}
                          textAlign={"center"}
                          sx={{ mb: 2 }}
                        >
                          Today's Tasks
                        </Typography>
                        <div className="d-flex justify-content-between">
                          <Typography
                            onClick={() => handleChangeTaskType("all")}
                            sx={{
                              cursor: "pointer",
                              color: taskType == "all" ? "#00ab55" : "",
                            }}
                            fontSize={10}
                          >
                            All{" "}
                            <span
                              style={{
                                backgroundColor:
                                  taskType == "all" ? "#00ab55" : "#6C757D",
                              }}
                              className="badge"
                            >
                              {getTasks(dashboardData.today_task, "all").length}
                            </span>
                          </Typography>
                          <Typography
                            onClick={() => handleChangeTaskType("pending")}
                            sx={{
                              cursor: "pointer",
                              color: taskType == "pending" ? "#00ab55" : "",
                            }}
                            fontSize={10}
                          >
                            Pending{" "}
                            <span
                              className="badge"
                              style={{
                                backgroundColor:
                                  taskType == "pending" ? "#00ab55" : "#6C757D",
                              }}
                            >
                              {
                                getTasks(dashboardData.today_task, "pending")
                                  .length
                              }
                            </span>
                          </Typography>
                          <Typography
                            onClick={() => handleChangeTaskType("inProgress")}
                            sx={{
                              cursor: "pointer",
                              color: taskType == "inProgress" ? "#00ab55" : "",
                            }}
                            fontSize={10}
                          >
                            In Progress{" "}
                            <span
                              className="badge"
                              style={{
                                backgroundColor:
                                  taskType == "inProgress"
                                    ? "#00ab55"
                                    : "#6C757D",
                              }}
                            >
                              {
                                getTasks(dashboardData.today_task, "inProgress")
                                  .length
                              }
                            </span>
                          </Typography>
                          <Typography
                            onClick={() => handleChangeTaskType("completed")}
                            sx={{
                              cursor: "pointer",
                              color: taskType == "completed" ? "#00ab55" : "",
                            }}
                            fontSize={10}
                          >
                            Completed{" "}
                            <span
                              className="badge"
                              style={{
                                backgroundColor:
                                  taskType == "completed"
                                    ? "#00ab55"
                                    : "#6C757D",
                              }}
                            >
                              {
                                getTasks(dashboardData.today_task, "completed")
                                  .length
                              }
                            </span>
                          </Typography>
                        </div>
                        {getTasks(dashboardData.today_task, taskType) == 0 && (
                          <div className="centered-row" style={{ flexGrow: 1 }}>
                            <Stack sx={{ height: "5rem", my: 2 }}>
                              <ImgContainer id="not-found-results-container">
                                <img
                                  width="100%"
                                  src={noResults}
                                  style={{ margin: "auto" }}
                                />
                              </ImgContainer>
                              <Typography
                                color={(theme) => theme.palette.text.disabled}
                                variant="body2"
                                textAlign="center"
                                fontWeight="bold"
                              >
                                Not Found !
                              </Typography>
                            </Stack>
                          </div>
                        )}
                        {getTasks(dashboardData.today_task, taskType) != 0 && (
                          <Scrollbar
                            sx={{
                              maxHeight: "60vh",
                            }}
                          >
                            <Stack spacing={1} sx={{ p: 1 }}>
                              {getTasks(dashboardData.today_task, taskType).map(
                                (task) => {
                                  return (
                                    <DaashboardTaskCard
                                      task={task}
                                      onTaskClick={handleNavigate}
                                    />
                                  );
                                }
                              )}
                            </Stack>
                          </Scrollbar>
                        )}
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}
      <MuiDialog
        title="Disconnect from Google Calendar"
        message={`Are you sure you want to disconnect this calendar from google account "${
          dispatch_get_user_profile().google_account_email
        }" ?`}
        open={disconnectGoogleDialogOpen}
        onToggle={(val) => setDisconnectGoogleDialogOpen(val)}
        loading={googleConnectionLoading}
        onAgree={handleDisconnectfromGoogleCalendar}
      />
      <MuiDialog
        title="Delete Workspace"
        message="Are you sure you want to delete this workspace"
        open={deleteDialogOpen}
        onToggle={(val) => setDeleteDialogOpen(val)}
        loading={deleteWorkspaceLoading}
        onAgree={handleDeleteWorkspace}
      />
    </Page>
  );
};

const CustomCard = ({
  project,
  index,
  type,
  deleteWorkspace,
  open,
  setOpen,
  setTargetDeleteId,
  onEditWorkspace,
}) => {
  const handleClose = () => {
    setOpen(null);
  };
  const classes = useStyles();
  const navigate = useNavigate();
  if (type == "workspace") {
    return (
      <Card
        onClick={() =>
          navigate(`/workspaces/projects/${project._id}`, {
            state: { project_detail: project },
          })
        }
        className={classes.card}
        style={{
          background: project.image
            ? `url(${s3baseUrl + project.image}), rgba(0,0,0,0.7)`
            : project.bgColor,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundBlendMode: "multiply",
          color: "#fff",
        }}
      >
        <div className="d-flex flex-column justify-content-between h-100">
          {/* <div className="d-flex justify-content-between">
            <div className="avatar-container">
              <AvatarGroup
                max={3}
                className="d-flex flex-row"
                sx={{ height: 33, width: 33 }}
              >
                {workspace.team != 0 &&
                  workspace.team.map((member) => {
                    return (
                      <UserAvatar
                        key={member.user_id}
                        image={member.image}
                        alt={member.first_name}
                        name={member.first_name + " " + member.last_name}
                      />
                    );
                  })}
              </AvatarGroup>
            </div>
            {(workspace.role == "admin" || workspace.role == "manager") && (
              <div>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(e.target);
                    setTargetDeleteId(workspace);
                  }}
                >
                  <Iconify
                    icon="fluent:more-vertical-16-filled"
                    sx={{ color: "#fff" }}
                  />
                </IconButton>
                <MenuPopover
                  open={Boolean(open)}
                  anchorEl={open}
                  onClose={(e) => {
                    e.stopPropagation();
                    handleClose();
                  }}
                  sx={{
                    p: 0.5,
                    mt: 1.5,
                    ml: 0.75,
                    width: "7rem",
                    "& .MuiMenuItem-root": {
                      typography: "body2",
                      borderRadius: 0.75,
                    },
                  }}
                >
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      onEditWorkspace();
                    }}
                    key={index}
                    sx={{ width: "6.5rem" }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteWorkspace();
                    }}
                    key={index}
                    sx={{ width: "6.5rem" }}
                  >
                    Delete
                  </MenuItem>
                </MenuPopover>
              </div>
            )}
          </div> */}
          <Typography variant="h5" component="h2">
            {truncateText(project.title, 25)}
          </Typography>
          <div>
            <Stack spacing={0}>
              <div className="d-flex justify-content-between align-items-center">
                <Typography fontSize={"12px"}>Total Sprints</Typography>
                <Typography>{project.all_sprint_count}</Typography>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <Typography fontSize={"12px"}>In Progress</Typography>
                <Typography>{project.in_progress_sprint_count}</Typography>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <Typography fontSize={"12px"}>On-time completed</Typography>
                <Typography>
                  {project.on_time_completed_sprint_count}
                </Typography>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <Typography fontSize={"12px"}>Late completed</Typography>
                <Typography>{project.late_sprint_count}</Typography>
              </div>
            </Stack>

            {/* <Typography variant="body2" component="p">
              {project.all_sprint_count}{" "}
              {project.all_sprint_count <= 1 ? "sprint" : "sprints"}
              <Iconify
                icon="fluent:divider-tall-24-filled"
                sx={{ height: "24px", width: "24px" }}
                color="#fff"
              />
            </Typography> */}
            <div className="row">
              <div className="col-9 p-0">
                <BorderLinearProgress
                  className="mt-2"
                  variant="determinate"
                  value={getTaskPercentage(
                    project.all_sprint_count,
                    project.on_time_completed_sprint_count +
                      project.late_sprint_count
                  )}
                />
              </div>
              <div className="col-3 p-0 text-center">
                {getTaskPercentage(
                  project.all_sprint_count,
                  project.on_time_completed_sprint_count +
                    project.late_sprint_count
                )}
                {"%"}
              </div>
            </div>
          </div>
        </div>
      </Card>
    );
  } else {
    return (
      <Card
        className={classes.addCard}
        onClick={() => navigate("/workspaces/create")}
      >
        <CardContent sx={{ height: "100%" }}>
          <div className="d-flex flex-column justify-content-center align-items-center text-center h-100">
            <Iconify
              icon="icon-park-solid:add"
              sx={{
                width: "36px",
                height: "36px",
                color: "#00AB55",
              }}
            />
            <Typography fontWeight={"bold"}>Add New Workspace</Typography>
          </div>
        </CardContent>
      </Card>
    );
  }
};

export default Dashboard;
