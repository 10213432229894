import { Button, Checkbox, Grid, IconButton } from "@mui/material";
import React, { useState } from "react";
import Iconify from "src/components/Iconify";

function CheckListItem({
  checklist,
  openDeleteChecklistDialog,
  editChecked,
  setTargetChecklist,
  handleSaveCheckListItem,
  newChecklistItem,
  setNewChecklistItem,
  addingChecklistItem,
  setAddingChecklistItem,
  setEditorOpen,
  setTargetChecklistItem,
  setEditChecklistItem,
  editChecklistItem,
  openChecklistItemDeleteDialog,
  canEditTask,
}) {
  return (
    <Grid container spacing={1} key={checklist._id}>
      <Grid item xs={1}>
        <div className="d-flex justify-content-end pt-3">
          <Iconify
            icon="basil:checked-box-outline"
            sx={{ height: "24px", width: "24px" }}
          />
        </div>
      </Grid>
      <Grid item xs={11}>
        <div className="pt-3 ps-3 w-100">
          <div className="d-flex justify-content-between">
            <h6 style={{ fontWeight: 600 }}>{checklist.name}</h6>
            {canEditTask && (
              <div
                className="sidebar-button"
                onClick={() => openDeleteChecklistDialog(checklist)}
              >
                Delete
              </div>
            )}
          </div>
        </div>
      </Grid>
      {checklist.items.map((item) => (
        <Grid
          item
          xs={12}
          key={item._id}
          spacing={0}
          style={{ paddingTop: "0px" }}
        >
          <div className="d-flex ps-3 align-items-start">
            <div>
              <Checkbox
                checked={item.status}
                size="small"
                onChange={(e) =>
                  editChecked(checklist, item, {
                    ...item,
                    status: e.target.checked,
                  })
                }
                onClick={(e) => {
                  e.stopPropagation();
                  setEditChecklistItem("");
                  setEditorOpen(false);
                }}
              />
            </div>
            {editChecklistItem !== item._id && (
              <div className="ps-3 d-flex justify-content-between align-items-center check-list-item w-100">
                <div
                  style={{
                    width: "90%",
                    // minHeight: "35px",
                    wordWrap: "break-word",
                    wordBreak: "break-word",
                  }}
                  onClick={(e) => {
                    if (canEditTask) {
                      e.stopPropagation();
                      setAddingChecklistItem("");
                      setTargetChecklist(checklist);
                      setNewChecklistItem(item.name);
                      setTargetChecklistItem(item);
                      setEditChecklistItem(item._id);
                    }
                  }}
                >
                  {item.name}
                </div>
                {canEditTask && (
                  <div className="hiddenDiv">
                    <IconButton
                      onClick={() => {
                        setTargetChecklistItem(item);
                        setTargetChecklist(checklist);
                        openChecklistItemDeleteDialog();
                      }}
                      // size="small"
                    >
                      <Iconify
                        icon={"fluent:delete-24-regular"}
                        sx={{ height: "16px", width: "16px" }}
                      />
                    </IconButton>
                  </div>
                )}
              </div>
            )}
            {editChecklistItem == item._id && (
              <div className="w-100 p-3 edit-checklist-div">
                <textarea
                  className="checklist-item-title w-100"
                  value={newChecklistItem}
                  onChange={(e) => setNewChecklistItem(e.target.value)}
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditorOpen(false);
                    setAddingChecklistItem("");
                  }}
                />
                <div className="d-flex">
                  <Button
                    size="small"
                    variant="contained"
                    onClick={(e) => {
                      //   e.stopPropagation();
                      editChecked(checklist, item, {
                        ...item,
                        name: newChecklistItem,
                      });
                    }}
                  >
                    Save
                  </Button>
                  <IconButton sx={{ ml: 1 }}>
                    <Iconify icon="gridicons:cross" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </Grid>
      ))}
      <Grid item xs={12}>
        {addingChecklistItem !== checklist._id && canEditTask && (
          <div
            className="sidebar-button"
            style={{ display: "inline", marginLeft: "70px" }}
            onClick={(e) => {
              e.stopPropagation();
              setNewChecklistItem("");
              setEditChecklistItem("");
              setTargetChecklist(checklist);
              setAddingChecklistItem(checklist._id);
              setEditorOpen(false);
            }}
          >
            Add Checklist Item
          </div>
        )}
        {addingChecklistItem == checklist._id && (
          <div style={{ marginLeft: "70px" }}>
            <textarea
              className="checklist-item-title"
              autoFocus
              placeholder="Add checklist item"
              value={newChecklistItem}
              onChange={(e) => setNewChecklistItem(e.target.value)}
              onClick={(e) => {
                e.stopPropagation();
                setEditorOpen(false);
              }}
            />
            <div>
              <Button
                size="small"
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSaveCheckListItem();
                  setEditorOpen(false);
                }}
              >
                Save
              </Button>
              <Button
                sx={{ ml: 1 }}
                size="small"
                onClick={() => {
                  setAddingChecklistItem("");
                  setNewChecklistItem("");
                  setEditChecklistItem("");
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  );
}

export default CheckListItem;
