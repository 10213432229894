import React, { useRef } from "react";
import MenuPopover from "./MenuPopover";
import { IconButton, MenuItem } from "@mui/material";
import Iconify from "./Iconify";

function MoreMenu({ open, onClose, options, item, handleOpenOptions }) {
  const anchorRef = useRef(null);
  return (
    <>
      <IconButton
        sx={{ bgcolor: "rgba(255, 255, 255, 0.438)" }}
        ref={anchorRef}
        onClick={(e) => {
          e.stopPropagation();
          handleOpenOptions(e, item);
        }}
      >
        <Iconify
          sx={{ color: "black" }}
          // icon='mi:options-horizontal'
          icon="fluent:more-vertical-16-filled"
        />
      </IconButton>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={onClose}
        sx={{
          p: 0.5,
          mt: 1.5,
          ml: 0.75,
          width: "7rem",
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem onClick={option.clickHandler}>{option.label}</MenuItem>
        ))}
      </MenuPopover>
    </>
  );
}

export default MoreMenu;
