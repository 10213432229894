import { get_from_localStorage, invokeApi } from "src/utils";

export const _update_task_files = async (data) => {
  const requestObj = {
    path: `api/task/update_task_images`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_task_checklist = async (data) => {
  const requestObj = {
    path: `api/task/enable_checklist`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _remove_task_checklist = async (data) => {
  const requestObj = {
    path: `api/task/disable_checklist`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_task_checklist_item = async (data) => {
  const requestObj = {
    path: `api/task/update_checklist_item`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _task_detail = (id) => {
  const requestObj = {
    path: `api/task/task_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};
