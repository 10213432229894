import { Icon } from "@iconify/react";
import { makeStyles } from "@mui/styles";
import { Paper, Typography } from "@mui/material";
const useStyles = makeStyles({
  root: {
    textAlign: "left",
    padding: 0,
    // color: "#5f80e8",
    // backgroundColor: "#e6ecff",
    position: "relative",
    top: 10,
    left: "4%",
  },
});

const Displaydata = ({ icon, text, lable }) => {
  const classes = useStyles();
  //   className={classes.root}
  return (
    <>
      {" "}
      <div className={classes.root}>
        <Typography variant="body1">{lable}</Typography>
      </div>
      <Paper elevation={5} sx={{ p: 2 }}>
        <div className="d-flex flex-row justify-content-start ">
          <div className="">
            <Typography variant="body1">
              <Icon
                fontSize="20"
                // style={{ color: option.color }}
                className="me-2"
                icon={icon}
              />
            </Typography>
          </div>
          <div className="">
            <Typography variant="body1" fontWeight={"bold"}>
              {text}
            </Typography>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default Displaydata;
