import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import {
  Card,
  Link,
  Container,
  Typography,
  IconButton,
  Stack,
  Button,
} from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Logo from "../../components/Logo";
// components
import { AuthSocial, Iconify, Page } from "src/components";
// import LoginForm from './components/LoginForm';
//files
import illustration_login from "src/assets/media/illustration_login.png";
import { useEffect, useLayoutEffect, useState } from "react";
import VerificationInput from "react-verification-input";
import { LoadingButton } from "@mui/lab";
import { _resend_code, _verify_email } from "src/DAL";
import { useSnackbar } from "notistack";
import {
  del_from_local_storage,
  get_from_localStorage,
  set_to_localStorage,
} from "src/utils";
import { replace } from "lodash";
import { useAppContext } from "src/hooks";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function EmailVerification() {
  const { dispatch_set_user_profile } = useAppContext();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [timer, setTimer] = useState(
    get_from_localStorage("timer")
      ? get_from_localStorage("timer")
      : state?.from == "register"
      ? 59
      : 0
  );
  const smUp = useResponsive("up", "sm");
  const mdUp = useResponsive("up", "md");
  const [step, setStep] = useState(1);
  const [resendLoading, setResendLoading] = useState(timer > 0 ? true : false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const verifyEmail = async (code) => {
    setSubmitLoading(true);
    document.getElementsByClassName("vi")[0].blur();
    const req_data = {
      email: state?.email,
      verification_code: code,
      device_token: "",
    };
    const result = await _verify_email(req_data);
    if (result.code == 200) {
      if (state?.from == "register") {
        navigate("/register", {
          state: { verification: result, invitation: state?.invitation },
        });
      } else {
        set_to_localStorage("token", result.token);
        set_to_localStorage("user", result.customer);
        dispatch_set_user_profile(result.customer);
        if (state?.invitation) {
          navigate("/invitations");
        } else {
          navigate("/dashboard");
        }
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setSubmitLoading(false);
  };
  function countdown(start) {
    var seconds = start ? start : 59;

    var timer = setInterval(function () {
      if (seconds <= 0) {
        clearInterval(timer);
        setResendLoading(false);
      }

      seconds--;
      setTimer(seconds);
    }, 1000);
  }
  const handleResendCode = async (e) => {
    e.preventDefault();
    setResendLoading(true);
    const result = await _resend_code({ email: state?.email });
    if (result.code == 200) {
      enqueueSnackbar("Code sent successfully", { variant: "success" });
      countdown();
    } else {
      setResendLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    }
  };
  useEffect(() => {
    set_to_localStorage("timer", timer);
  }, [timer]);
  useEffect(() => {
    if (timer > 0) {
      countdown(timer);
    }
    if (!state) {
      navigate("/login");
    }
  }, []);
  useLayoutEffect(() => {
    return del_from_local_storage("timer");
  }, []);
  return (
    <Page title="Email Verification">
      <RootStyle>
        <HeaderStyle>
          <Logo />

          {/* {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Login instead &nbsp;
              <Link variant="subtitle2" component={RouterLink} to="/login">
                Login
              </Link>
            </Typography>
          )} */}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography> */}
            <img src={illustration_login} alt="login" />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="start"
              spacing={1}
            >
              {step !== 1 && (
                <IconButton onClick={() => setStep((prev) => prev - 1)}>
                  <Iconify icon="akar-icons:arrow-left" />
                </IconButton>
              )}
              <Typography variant="h4" gutterBottom>
                Verify Email
              </Typography>
            </Stack>

            {/* <Typography sx={{ color: 'text.secondary', mb: 3 }}>Enter your email below.</Typography> */}

            {/* <AuthSocial /> */}

            {/* <ForgotForm step={step} setStep={setStep} /> */}
            <VerificationInput
              style={{ width: "100%" }}
              validChars="0-9"
              inputProps={{ inputMode: "numeric" }}
              onComplete={verifyEmail}
            />
            <Button
              sx={{ mt: 3, width: "150px" }}
              variant="contained"
              disabled={submitLoading || resendLoading}
              onClick={handleResendCode}
            >
              Resend {timer > 0 && timer}
            </Button>
            {/*      {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to="/register">
                  Get started
                </Link>
              </Typography>
            )} */}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
