import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";

// ----------------------------------------------------------------------
import { createRoot } from "react-dom/client";
import { AppContext } from "./hooks";
import React, { StrictMode } from "react";
import { SnackbarProvider } from "notistack";
import { IconButton, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { registerLicense } from "@syncfusion/ej2-base";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { stripePublicKey } from "./config/config";
const container = document.getElementById("root");
const notiStackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notiStackRef.current.closeSnackbar(key);
};
const root = createRoot(container);
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NGaF5cXmdCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXhfcnRWQ2ldVkZ1W0c="
);
// const stripePromise = loadStripe(stripePublicKey);
root.render(
  <HelmetProvider>
    <BrowserRouter>
      {/* react-beautiful-dnd not supported in strict mode
    remove it to test drag-drop */}
      {/* <StrictMode> */}
      <SnackbarProvider
        hideIconVariant
        ref={notiStackRef}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        TransitionComponent={Slide}
        maxSnack={3}
        autoHideDuration={2500}
        action={(key) => (
          <IconButton onClick={onClickDismiss(key)}>
            <CloseIcon htmlColor="white" />
          </IconButton>
        )}
      >
        {/* <Elements stripe={stripePromise}> */}
        <AppContext>
          <App />
        </AppContext>
        {/* </Elements> */}
      </SnackbarProvider>
      {/* </StrictMode> */}
    </BrowserRouter>
  </HelmetProvider>
);
// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
