import { get_from_localStorage, invokeApi } from "src/utils";

export const _update_task_tags_in_task = (data) => {
  const requestObj = {
    path: `api/task/update_tag`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_task_tag_in_project = (data) => {
  const requestObj = {
    path: `api/project/add_tag_color`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _edit_task_tag_in_project = (data) => {
  const requestObj = {
    path: `api/project/update_tag_color`,
    method: "PUT",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_task_tag_in_project = (data) => {
  const requestObj = {
    path: `api/project/delete_tag_color`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
