import React, { useState } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Box, Typography } from '@mui/material';
import Iconify from '../Iconify';
import { get_zone_date } from 'src/utils';
import moment from 'moment';
const TaskDatePicker = ({ value, onChange, actionAllowed, disabled }) => {
  // const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (disabled === true) {
      return;
    }
    setOpen(true);
  };
  const getFormattedDate = (date) => {
    if (!date) {
      if (disabled) {
        return '';
      }
      if (!disabled) {
        return 'Select date';
      }
    }
    return get_zone_date(date, 'DD MMM YYYY');
  };
  const getFormatted = (_format, _date) => {
    let date = _date ? _date : moment();
    const with_zone = get_zone_date(date, _format);
    return moment(with_zone);
  };
  const getMinDate = (format) => {
    let today = moment();
    const with_zone = get_zone_date(today, format);
    return moment(with_zone);
  };
  const isDatePast = (_date) => {
    let target_date = getFormatted('YYYY-MM-DD', _date);
    let today_date = getFormatted('YYYY-MM-DD');
    let expired = target_date.isBefore(today_date);
    return expired;
  };
  const getColor = (date) => {
    if (!date) {
      return '#00AB55';
    }
    let expired = isDatePast(date);
    if (expired) {
      return '#FF4842';
    }
    return '#00AB55';
  };
  const onDateChange = (newValue) => {
    /*todo: if date is past, don't let date change happen, */
    /* try to handle it here */
    let past_new_date = isDatePast(newValue);
    let past_current_date = isDatePast(value);
    // console.log(past_new_date, 'past_new_date');
    // console.log(past_current_date, 'past_current_date');
    onChange(newValue);
  };
  /* <<--------------------------------------------------------->> */
  return (
    <span id='task-date-picker'>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label='Custom input'
          value={value ? value : ''}
          disabled={disabled}
          open={open}
          onClose={() => setOpen(false)}
          onChange={(newValue) => {
            onDateChange(newValue);
          }}
          disableHighlightToday
          rifmFormatter={(date) => getFormattedDate(date)}
          minDate={getMinDate('YYYY-MM-DD')}
          renderInput={({ inputRef, inputProps, InputProps }) => {
            return (
              <Box
                className='pointer'
                onClick={handleOpen}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                {/* <input ref={inputRef} {...inputProps} /> */}
                {inputProps.value !== '' && (
                  <Typography
                    sx={{
                      color: disabled
                        ? (theme) => theme.palette.text.disabled
                        : getColor(inputProps.value),
                      textDecoration: 'underline',
                      fontSize: '12px',
                    }}
                    ref={inputRef}
                    {...inputProps}
                  >
                    {inputProps.value ? inputProps.value : 'Select date'}
                  </Typography>
                )}
                {/* {InputProps?.endAdornment} */}
              </Box>
            );
          }}
        />
      </LocalizationProvider>
    </span>
  );
};

export default TaskDatePicker;
