import React from "react";
import {
  Paper,
  Stack,
  Box,
  Typography,
  Avatar,
  ButtonBase,
  IconButton,
} from "@mui/material";
import { capitalCase } from "change-case";
import { s3baseUrl } from "src/config/";
import { Iconify, Label } from "src/components";
import { useAppContext } from "src/hooks";
// ---------------------------------------------------------

const ProjectUserCard = ({
  item,
  userRole,
  onRemoveUser,
  onAddUser,
  addLoading,
}) => {
  // ---------------------------------------------------------
  // const { user_profile, role } = item;
  const { image, first_name, last_name, role } = item;
  const { dispatch_set_current_selected_item } = useAppContext();
  const canRemove = (_role) => {
    console.log(_role, userRole, "tgt + user role");
    if (String(userRole) === "manager") {
      if (String(_role) === "admin") {
        return false;
      }
      // if (String(_role) === String(userRole)) {
      //   return false;
      // }
      return true;
    }
    if (String(userRole) === "admin") {
      return true;
    }
    if (String(userRole) === "team") {
      return false;
    }
    if (String(_role) === String(userRole)) {
      return false;
    }
    return false;
  };
  const handleRemove = () => {
    dispatch_set_current_selected_item((prev) => {
      return { ...prev, ["target_user"]: item };
    });
    onRemoveUser(item);
  };
  const handleAdd = () => {
    dispatch_set_current_selected_item((prev) => {
      return { ...prev, ["target_user"]: item };
    });
    onAddUser(item);
  };
  // ---------------------------------------------------------
  return (
    <Paper
      className="position-relative"
      onClick={() => console.log(item)}
      elevation={3}
      sx={{ p: 1 }}
    >
      <Stack spacing={1} direction="column">
        <Stack direction="row">
          <Box alignItems="center" justifyContent="center">
            {image ? (
              <Avatar src={s3baseUrl + image} />
            ) : (
              <Avatar>{first_name.substring(0, 2)}</Avatar>
            )}
          </Box>
          <Stack spacing={1} sx={{ ml: 1 }}>
            <Typography variant="body1" noWrap>
              {first_name + " " + last_name}
            </Typography>
            <Stack direction="row" spacing={1} style={{ marginTop: 0 }}>
              <Typography fontWeight="bold" variant="body1">
                Role
              </Typography>
              <Typography variant="body1" noWrap>
                {capitalCase(role)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        {canRemove(role) && (
          <div className="centered-row ">
            {!item.is_added && item.role !== "admin" && (
              <ButtonBase
                onClick={handleAdd}
                disabled={addLoading}
                sx={{ borderRadius: "5px" }}
              >
                <Label
                  sx={{ cursor: "pointer", width: "4rem" }}
                  variant="ghost"
                  color="success"
                >
                  Add
                </Label>
              </ButtonBase>
            )}
          </div>
        )}
        {canRemove(role) && item.is_added && (
          <div className="centered-row ">
            {item.role !== "admin" && (
              <ButtonBase
                onClick={handleRemove}
                // disabled={addLoading}
                sx={{ borderRadius: "5px" }}
              >
                <Label
                  sx={{ cursor: "pointer", width: "4rem" }}
                  variant="ghost"
                  color="error"
                >
                  Remove
                </Label>
              </ButtonBase>
            )}
          </div>
        )}
        {/* {canRemove(role) && (
          <div className='centered-row '>
            {item.is_added ? (
              <ButtonBase onClick={handleRemove} sx={{ borderRadius: '5px' }}>
                <Label sx={{ cursor: 'pointer', width: '4rem' }} variant='ghost' color='error'>
                  Remove
                </Label>
              </ButtonBase>
            ) : (
              <ButtonBase onClick={handleAdd} sx={{ borderRadius: '5px' }}>
                <Label sx={{ cursor: 'pointer', width: '4rem' }} variant='ghost' color='success'>
                  Add
                </Label>
              </ButtonBase>
            )}
          </div>
        )} */}
      </Stack>
      {/* {canRemove(role) && item.is_added && (
        <div
          className="position-absolute"
          style={{ top: "5px", right: "10px" }}
        >
          <IconButton onClick={handleRemove} size="small">
            <Iconify
              sx={{ color: "black" }}
              icon={"clarity:window-close-line"}
            />
          </IconButton>
        </div>
      )} */}
    </Paper>
  );
};

export default ProjectUserCard;
