import { Card, CardContent, Stack } from "@mui/material";
import moment from "moment";
import React from "react";
import DaashboardTaskCard from "src/pages/dashboard/components/DaashboardTaskCard";
import NoTasksFound from "src/pages/dashboard/components/NoTasksFound";

export default function DayCalendar({ events, handleClick, currentDate }) {
  console.log(events);
  const handleSortDate = (events) => {
    const startDate = moment(currentDate.start_date).format("YYYY-MM-DD");
    const endDate = moment(currentDate.end_date).format("YYYY-MM-DD");

    const filteredEvents = events.filter((event) => {
      const eventStartDate = moment.utc(event.start).format("YYYY-MM-DD");
      return (
        moment(eventStartDate).isSameOrAfter(startDate) &&
        moment(eventStartDate).isSameOrBefore(endDate)
      );
    });

    return filteredEvents;
  };

  return (
    <>
      <div className="row">
        {handleSortDate(events).length > 0 ? (
          handleSortDate(events).map((event) => {
            return (
              <Card>
                <CardContent>
                  <Stack spacing={2}>
                    <div className="d-flex justify-content-center">
                      <h4>{moment(event.start).format("dddd")}</h4>
                      {/* <span>{moment(event.start).format("MM/DD")}</span> */}
                    </div>
                    <DaashboardTaskCard
                      task={event.more}
                      onTaskClick={handleClick}
                    />
                  </Stack>
                </CardContent>
              </Card>
            );
          })
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <NoTasksFound />
          </div>
        )}
      </div>
    </>
  );
}
