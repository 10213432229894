// MuiMultiAutocomplete
import { Autocomplete, Chip, TextField } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  chip: {
    "& .MuiChip-deleteIcon": {
      color: "#00AB55", // Customize the color as needed
    },
  },
}));
const MuiMultiAutocomplete = ({
  label,
  options,
  value,
  onChange,
  disabled,
  fixedOptions = [],
  required = false,
  disableCloseOnSelect = false,
}) => {
  // ---------------------------------------------------------
  const classes = useStyles();
  const handleChange = (newValue) => {
    if (newValue) {
      onChange(newValue);
    }
  };
  // ---------------------------------------------------------
  return (
    <Autocomplete
      multiple
      id="tags-filled"
      options={options}
      value={value}
      disableCloseOnSelect={disableCloseOnSelect}
      disabled={disabled}
      filterSelectedOptions={true}
      isOptionEqualToValue={(option, value) => option._id === value._id}
      onChange={(event, value) =>
        handleChange([
          ...fixedOptions,
          ...value.filter(
            (option) =>
              fixedOptions.findIndex(
                (fixedOption) => fixedOption._id == option._id
              ) === -1
          ),
        ])
      }
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={`${option.label}`}
            disabled={fixedOptions.indexOf(option) !== -1}
            // deleteIcon={<CancelIcon sx={{ color: "red" }} />}
            classes={{ root: classes.chip }}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          variant="outlined"
          label={label}
        />
      )}
    />
  );
};

export default MuiMultiAutocomplete;

MuiMultiAutocomplete.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
