import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
// ---------------------------------------------------------
export default function MuiAutoComplete({
  label,
  options,
  value,
  onChange,
  disabled,
  required = false,
  disablePortal = false,
}) {
  const [inputValue, setInputValue] = React.useState("");
  // ---------------------------------------------------------
  const handleChange = (newValue) => {
    console.log(newValue);
    if (newValue) {
      onChange(newValue);
    } else {
      onChange();
    }
  };
  // ---------------------------------------------------------
  return (
    <div>
      <Autocomplete
        value={value}
        disabled={disabled}
        onChange={(event, newValue) => {
          handleChange(newValue);
        }}
        sx={{ width: "100%" }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        disablePortal={disablePortal}
        // isOptionEqualToValue={(option, value) => option.title === value.title}
        id="controllable-states-demo"
        options={options}
        renderInput={(params) => (
          <TextField
            sx={{ width: "100%" }}
            required={required}
            {...params}
            label={label}
          />
        )}
      />
    </div>
  );
}
MuiAutoComplete.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
