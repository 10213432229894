import React, { useEffect, useRef, useState } from "react";
import { RichEditor, UserAvatar } from "..";
import { Button } from "@mui/material";
import { useAppContext } from "src/hooks";
import moment from "moment";

function TaskComment({
  comment,
  onEdit,
  isEditing,
  onEditClick,
  onDelComment,
  editCommentTitle,
  setEditCommentTitle,
  handleUpdateTaskComment,
}) {
  const { dispatch_get_user_profile } = useAppContext();

  const editorRef = useRef();
  return (
    <div key={comment._id}>
      <div className="p-4 pe-0 pb-0 d-flex">
        <UserAvatar
          image={comment.creator.image}
          alt={comment.creator.first_name}
          name={comment.creator.first_name}
        />
        <div className="px-2 w-100">
          <div className="d-flex align-items-center">
            <div className="comment-name">
              {comment.creator.first_name + " " + comment.creator.last_name}
            </div>
            <div className="comment-time">
              {moment(comment?.created_at).format("MMM DD [at] hh:mm a")}
            </div>
          </div>

          {!isEditing && (
            <div
              className="comment-data mt-1"
              dangerouslySetInnerHTML={{ __html: comment.comment_title }}
              style={{ wordWrap: "break-word", wordBreak: "break-word" }}
            />
          )}
          {isEditing && (
            <div className="mt-1">
              <RichEditor
                editorRef={editorRef}
                value={editCommentTitle}
                setValue={setEditCommentTitle}
              />
              <div className="mt-1">
                <Button
                  size="small"
                  variant="contained"
                  onClick={(e) => handleUpdateTaskComment(e, "detail", comment)}
                >
                  Save
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{ ml: 1 }}
                  onClick={() => onEditClick({ _id: "" })}
                >
                  Discard
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      {!isEditing &&
        comment?.creator?.user_id === dispatch_get_user_profile().user_id && (
          <div className="pb-0 d-flex mt-2" style={{ paddingLeft: "73px" }}>
            <div
              className="edit-delete-button"
              onClick={() => onEditClick(comment)}
            >
              Edit
            </div>
            <div
              className="ml-2 edit-delete-button"
              onClick={() => onDelComment(comment)}
            >
              Delete
            </div>
          </div>
        )}
    </div>
  );
}

export default TaskComment;
