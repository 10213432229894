import {
  Avatar,
  Box,
  ButtonBase,
  Card,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { dummy } from "src/assets";
import { Label } from "src/components";

function DummyUserCard({ email, onInvite, isInvited = false }) {
  return (
    <>
      <Card>
        <Box
          className="d-flex align-items-center justify-content-center"
          sx={{ p: 3, pb: 0, position: "relative" }}
        >
          <Avatar
            sx={{ width: "7rem", height: "7rem" }}
            alt="user-image"
            style={{ cursor: "pointer" }}
            src={dummy}
          />
        </Box>
        <Stack spacing={1} sx={{ p: 3, pt: 1 }}>
          <Typography
            textAlign="center"
            variant="subtitle2"
            fontWeight="bold"
            noWrap
            style={{ cursor: "pointer" }}
            onClick={() => null}
          >
            {email}
          </Typography>
          {!isInvited && (
            <ButtonBase
              onClick={() => onInvite({ email })}
              sx={{ borderRadius: "5px", width: "100%" }}
            >
              <Label
                sx={{ "&:hover": { cursor: "pointer" }, width: "100%" }}
                color="info"
                variant="ghost"
              >
                Invite via Email
              </Label>
            </ButtonBase>
          )}
          {isInvited && (
            <ButtonBase
              disableRipple
              sx={{ borderRadius: "5px", width: "100%" }}
            >
              <Label color="success" variant="ghost">
                Invite Sent!
              </Label>
            </ButtonBase>
          )}
        </Stack>
      </Card>
    </>
  );
}

export default DummyUserCard;
