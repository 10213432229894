import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import warningImage from "src/assets/media/warning.png";

function CancelSubscriptionConfrimation({ open, setOpen, handleAgree }) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const handleCloseDialog = () => {
    setOpen(false);
  };
  useEffect(() => {
    setIsLoading(false);
  }, [open]);

  let _multiple_hit = true;
  return (
    <>
      <Dialog
        open={open}
        onClose={isLoading ? "" : handleCloseDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              padding: "0.5rem 0rem",
              borderTop: "0.3rem solid",
              borderColor: theme.palette.primary.main,
            },
          },
        }}
      >
        <DialogContent sx={{ paddingBottom: 0 }}>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "black",
            }}
          >
            <h3 className="mb-2">Cancel Subscription?</h3>
            <p className="mb-0">
              Are you sure you want to cancel your subscription?{" "}
            </p>
            <p className="">
              <strong>You can't undo this action.</strong>
            </p>
            <div class="alert alert-danger d-flex w-100 mt-2" role="alert">
              <div
                style={{ width: "34px", height: "24px" }}
                className="me-3 mt-2"
              >
                <img src={warningImage} alt=""></img>
              </div>
              <div>
                <strong>Warning</strong>

                <p className="mb-0">
                  Canceling your subscription will result in the termination of
                  access to all your programs, website pages, and navigation
                  bar.
                </p>
              </div>
            </div>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ paddingX: 3 }}>
          <Button
            onClick={handleCloseDialog}
            variant="outlined"
            disabled={isLoading}
            size="small"
            // sx={{
            //   borderColor: "#e12d39",
            //   color: "black",
            //   "&:hover": {
            //     borderColor: "#75040b",
            //     color: "black",
            //   },
            //   textAlign: "center",
            // }}
          >
            Close
          </Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            size="small"
            onClick={() => {
              // setTimeout(() => {
              setIsLoading(true);
              handleAgree(_multiple_hit);
              if (_multiple_hit) {
                _multiple_hit = null;
              }
              // }, 1)
            }}
            sx={{
              background: "#e12d39",
              "&:hover": {
                background: "#75040b",
              },
              textAlign: "center",
            }}
          >
            Yes, Countinue
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CancelSubscriptionConfrimation;
