import React from "react";

function SidebarButton({ button }) {
  return (
    <div className="sidebar-button d-flex align-items-center">
      {button.icon} {button.title}
    </div>
  );
}

export default SidebarButton;
