import React, { useState, useLayoutEffect, useRef } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CommentCard from "src/pages/workspace-project/components/CommentCard";
import { CircularLoader, MuiDrawer, Iconify, MuiDialog, RichEditor } from "..";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

const CommentsDrawer = ({
  open,
  onClose,
  selectedTask,
  disabled,
  comments,
  onCommentAction,
  loading,
  newComment,
  setNewComment,
}) => {
  /* <<--------------------------------------------------------->> */
  const [editingComment, setEditingComment] = useState(false);
  const editorRef = useRef();
  const scrollbarRef = useRef();
  const [processingEditComment, setProcessingEditComment] = useState(false);
  const [delCommentOpen, setDelCommentOpen] = useState(false);
  const [deletingTaskComment, setDeletingTaskComment] = useState(false);
  const [sendingComment, setSendingComment] = useState(false);
  const [selectedComment, setSelectedComment] = useState(undefined);

  const { enqueueSnackbar } = useSnackbar();
  /* <<--------------------------------------------------------->> */

  const onEditComment = (_comment) => {
    setSelectedComment(_comment);
    setEditingComment(true);
  };
  const onDelComment = (_comment) => {
    setSelectedComment(_comment);
    openDelCommentDialog();
  };
  const handleCancelEditComment = async (e) => {
    setEditingComment(false);
    setSelectedComment(undefined);
  };
  const closeDelCommentDialog = () => {
    setDelCommentOpen(false);
  };
  const openDelCommentDialog = () => {
    setDelCommentOpen(true);
  };
  const currentTask = () => {
    if (selectedTask) {
      return selectedTask;
    }
    return undefined;
  };
  const disableComment = () => {
    if (disabled === true) {
      return true;
    }
    if (!selectedTask) {
      return true;
    }

    return false;
  };
  const handleClose = () => {
    setNewComment({
      title: "",
      type: "0",
    });
    setEditingComment(false);
    setSelectedComment(undefined);
    onClose();
  };
  const handleDelTaskComment = async () => {
    setDeletingTaskComment(true);
    console.log(selectedComment, "to-del");
    if (!selectedComment) {
      console.log("no selectedComment");
      return;
    }
    const result = await onCommentAction("delete_comment", selectedComment);
    setDeletingTaskComment(false);
    closeDelCommentDialog();
    setSelectedComment(undefined);
    if (result.code === 200) {
      enqueueSnackbar("Comment deleted successfully", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleUpdateTaskComment = async () => {
    setProcessingEditComment(true);
    const result = await onCommentAction("edit_comment", selectedComment);
    console.log(result, "edit-com-res");
    setProcessingEditComment(false);
    setEditingComment(false);
    setSelectedComment(undefined);
    if (result.code === 200) {
      enqueueSnackbar("Comment updated successfully", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleSendComment = async () => {
    setSendingComment(true);
    const result = await onCommentAction("add_comment", newComment);
    console.log(result, "add-com-res");
    setSendingComment(false);
    if (result.code === 200) {
      setNewComment({
        title: "",
        type: "0",
      });
      handleScrollBarToBottom();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleScrollBarToBottom = () => {
    console.log(scrollbarRef, "scrl-ref");
    if (!scrollbarRef.current) return;
    scrollbarRef.current.scroll(0, 0);
  };
  /* <<--------------------------------------------------------->> */
  useEffect(() => {
    if (loading === false) {
      handleScrollBarToBottom();
    }
  }, [loading]);
  /* <<--------------------------------------------------------->> */
  return (
    <div>
      <MuiDrawer
        paperProps={{ width: { xs: 1, sm: 540, md: 450 } }}
        isOpen={open}
        onClose={handleClose}
        title="Task Comments"
        scrollbarRef={scrollbarRef}
        bottomComponent={
          <Stack sx={{ px: 2.5, pb: "5px" }}>
            <Stack
              sx={{ py: 1.5 }}
              // direction="row"
              // alignItems="center"
              // justifyContent="center"
            >
              {editingComment ? (
                // <TextField
                //   value={selectedComment?.comment_title}
                //   onChange={({ target }) =>
                //     setSelectedComment({
                //       ...selectedComment,
                //       comment_title: target.value,
                //     })
                //   }
                //   fullWidth
                //   autoFocus={true}
                //   focused={true}
                //   variant="outlined"
                //   label="Comment"
                //   multiline
                //   rows={4}
                //   disabled={processingEditComment || disableComment() === true}
                // />
                <RichEditor
                  editorRef={editorRef}
                  value={selectedComment?.comment_title}
                  setValue={(val) => {
                    setSelectedComment((prev) => {
                      return {
                        ...prev,
                        comment_title: val,
                      };
                    });
                  }}
                  disabled={processingEditComment || disableComment() === true}
                />
              ) : (
                // <TextField
                //   autoFocus={true}
                //   value={newComment.title}
                //   onChange={({ target }) =>
                //     setNewComment((prev) => {
                //       return { ...prev, title: target.value };
                //     })
                //   }
                //   fullWidth
                //   variant="outlined"
                //   label="Comment"
                //   multiline
                //   rows={4}
                //   disabled={sendingComment || disableComment() === true}
                // />
                <RichEditor
                  editorRef={editorRef}
                  value={newComment?.title}
                  setValue={(val) =>
                    setNewComment((prev) => {
                      return { ...prev, title: val };
                    })
                  }
                  disabled={sendingComment || disableComment() === true}
                />
              )}
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center">
                <div className="centered-row">
                  {editingComment ? (
                    <FormControl>
                      <RadioGroup
                        row
                        disabled={
                          processingEditComment || disableComment() === true
                        }
                        aria-labelledby="gender-select-radio-buttons-label"
                        name="row-radio-buttons-group"
                        value={selectedComment?.comment_type}
                        onChange={({ target }) =>
                          setSelectedComment({
                            ...selectedComment,
                            comment_type: target.value,
                          })
                        }
                      >
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Public"
                          disabled={
                            processingEditComment || disableComment() === true
                          }
                        />
                        <FormControlLabel
                          disabled={
                            processingEditComment || disableComment() === true
                          }
                          value="1"
                          control={<Radio />}
                          label="Private"
                        />
                      </RadioGroup>
                    </FormControl>
                  ) : (
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="gender-select-radio-buttons-label"
                        name="row-radio-buttons-group"
                        value={newComment.type}
                        disabled={sendingComment || disableComment() === true}
                        onChange={({ target }) =>
                          setNewComment((prev) => {
                            return { ...prev, type: target.value };
                          })
                        }
                      >
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Public"
                          disabled={sendingComment || disableComment() === true}
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Private"
                          disabled={sendingComment || disableComment() === true}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                </div>
              </Stack>
              {editingComment ? (
                <Stack
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <LoadingButton
                    loading={false}
                    size="small"
                    variant="contained"
                    onClick={() => handleCancelEditComment()}
                    startIcon={<Iconify icon="gridicons:cross" />}
                    color="error"
                    disabled={
                      processingEditComment || disableComment() === true
                    }
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    loading={processingEditComment}
                    size="small"
                    disabled={
                      processingEditComment || disableComment() === true
                    }
                    variant="contained"
                    onClick={() => handleUpdateTaskComment()}
                    startIcon={<Iconify icon="dashicons:saved" />}
                  >
                    Update
                  </LoadingButton>
                </Stack>
              ) : (
                <LoadingButton
                  loading={sendingComment}
                  size="small"
                  variant="contained"
                  disabled={
                    newComment.title.trim() === "" || disableComment() === true
                  }
                  onClick={handleSendComment}
                  startIcon={<Iconify icon="fluent:send-24-regular" />}
                >
                  Send
                </LoadingButton>
              )}
            </Stack>
          </Stack>
        }
        headerComponent={
          currentTask() && (
            <Box sx={{ pt: "12px" }}>
              <Typography sx={{ px: 2.5 }} noWrap fontWeight="bold">
                {currentTask().task_title}
              </Typography>
            </Box>
          )
        }
      >
        {currentTask() && (
          <>
            <Stack className="position-relative" spacing={1}>
              <AnimatePresence layout initial={false}>
                {comments.map((item, index) => {
                  return (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      layout
                      key={item._id}
                    >
                      <CommentCard
                        onEdit={onEditComment}
                        onDel={onDelComment}
                        item={item}
                        currentUserRole={selectedTask.role}
                      />
                    </motion.div>
                  );
                })}
              </AnimatePresence>
              {loading && (
                <span className="comment-loader-wrap">
                  <span className="comment-loader">
                    <CircularLoader size="3rem" />
                  </span>
                </span>
              )}
              {!loading && comments.length === 0 && (
                <span className="comment-loader-wrap">
                  <span className="comment-loader">
                    <Typography
                      color={(theme) => theme.palette.text.disabled}
                      sx={{ mt: 30 }}
                      textAlign="center"
                    >
                      No comments added
                    </Typography>
                  </span>
                </span>
              )}
            </Stack>
          </>
        )}
      </MuiDrawer>
      {/* dialogs */}
      <MuiDialog
        open={delCommentOpen}
        onToggle={closeDelCommentDialog}
        onAgree={handleDelTaskComment}
        loading={deletingTaskComment}
        title="Delete Comment"
        message={`Are you sure you want to delete this comment?`}
      />
    </div>
  );
};

export default CommentsDrawer;
