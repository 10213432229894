import { useState } from "react";
import PropTypes from "prop-types";

// material
import { Menu, Button, MenuItem, Typography } from "@mui/material";
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

export default function ButtonIcon({ title, endIcon, onClick, sx }) {
  const handleClick = (event) => {
    onClick(event);
  };

  return (
    <>
      <Button
        className="btn-select"
        color="inherit"
        onClick={handleClick}
        sx={{
          bgcolor: (theme) => theme.palette.action.hover,
          alignItems: "self-end",
          ...sx,
        }}
        endIcon={
          <Iconify
            sx={{
              //   color: (theme) => theme.palette.primary.main,
              height: "1.4rem",
              width: "1.4rem",
            }}
            icon={endIcon}
          />
        }
      >
        <Typography
          sx={{
            alignSelf: "self-end",
            //  mb: 0.2
          }}
          component="span"
          variant="subtitle2"
          fontWeight="bold"
        >
          {title ? title : `Button`}
        </Typography>
      </Button>
    </>
  );
}
ButtonIcon.propTypes = {
  title: PropTypes.string,
  sx: PropTypes.object,
  endIcon: PropTypes.string.isRequired,
};
