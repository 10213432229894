import { invokeApi } from "src/utils";
import { get_from_localStorage } from "src/utils";

export const _get_active_plans = () => {
  const reqObj = {
    method: "GET",
    path: `api/payment_plan/list_active_subscription_plans_for_client`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(reqObj);
};

export const _get_active_plans_detail = (id) => {
  const reqObj = {
    method: "GET",
    path: `api/payment_plan/get_subscription_detail/${id}`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(reqObj);
};

export const _get_stripe_key = () => {
  const reqObj = {
    method: "GET",
    path: `api/app_api/get_stripe_pk`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(reqObj);
};

export const _stripe_payment = (data) => {
  const reqObj = {
    method: "POST",
    path: `api/subscription/buy_subscription_plan`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(reqObj);
};

export const _confirm_onetime_payment = (data) => {
  const reqObj = {
    method: "POST",
    path: `api/subscription/confirm_one_time_payment`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(reqObj);
};
