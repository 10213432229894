import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Box,
  Card,
  Typography,
  Stack,
  Tooltip,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  IconButton,
  ButtonBase,
  Avatar,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import { baseUri, s3baseUrl } from "src/config/config";
import { useAppContext } from "src/hooks";
import { Label } from "src/components";

// ----------------------------------------------------------------------
export default function TeamUserCard({ item, onInvite, menuOptions }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(null);
  const { dispatch_set_current_selected_item } = useAppContext();
  // ------------------------------------------------------
  const handleClose = () => {
    setOpen(null);
  };

  // ------------------------------------------------------
  return (
    <>
      <Card>
        <Box
          className="d-flex align-items-center justify-content-center"
          sx={{ p: 3, pb: 0, position: "relative" }}
        >
          {item.image ? (
            <Avatar
              sx={{ width: "7rem", height: "7rem" }}
              alt="user-image"
              style={{ cursor: "pointer" }}
              src={s3baseUrl + item.image}
            />
          ) : (
            <Avatar
              sx={{ width: "7rem", height: "7rem" }}
              alt={"name"}
              style={{ cursor: "pointer" }}
            >
              {item.first_name.substring(0, 2)}
            </Avatar>
          )}
        </Box>
        <Stack spacing={1} sx={{ p: 3, pt: 1 }}>
          <Typography
            textAlign="center"
            variant="subtitle2"
            fontWeight="bold"
            noWrap
            style={{ cursor: "pointer" }}
            onClick={() => null}
          >
            {item.first_name + " " + item.last_name}
          </Typography>
          <Typography
            textAlign="center"
            variant="subtitle2"
            noWrap
            style={{ cursor: "pointer" }}
          >
            {item.email}
          </Typography>
          {!item.is_invited && !item.is_added && (
            <ButtonBase
              onClick={() => onInvite(item)}
              sx={{ borderRadius: "5px", width: "100%" }}
            >
              <Label
                sx={{ "&:hover": { cursor: "pointer" }, width: "100%" }}
                color="info"
                variant="ghost"
              >
                Invite
              </Label>
            </ButtonBase>
          )}
          {item.is_invited && !item.is_added && (
            <ButtonBase
              disableRipple
              sx={{ borderRadius: "5px", width: "100%" }}
            >
              <Label color="success" variant="ghost">
                Invite Sent!
              </Label>
            </ButtonBase>
          )}
          {!item.is_invited && item.is_added && (
            <ButtonBase
              disableRipple
              sx={{ borderRadius: "5px", width: "100%" }}
            >
              <Label color="success" variant="ghost">
                Already Member!
              </Label>
            </ButtonBase>
          )}
        </Stack>
      </Card>
    </>
  );
}
