import { get_from_localStorage, invokeApi } from "src/utils";

export const _getAllProjectsInWorkspace = async (id) => {
  const requestObj = {
    path: `api/project/project_list_for_invitation/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _getWorkspaceTeamWithAdmin = (id) => {
  const requestObj = {
    path: `api/team/team_list_with_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: { workspace_id: id },
  };
  return invokeApi(requestObj);
};
