import { Box, CircularProgress, Tooltip, Typography } from "@mui/material";
// ---------------------------------------------------------
export default function Progress(props) {
  const { value } = props;

  // ---------------------------------------------------------
  return (
    <Box
      id="progress-box"
      sx={{
        position: "relative",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "2px",
      }}
    >
      <CircularProgress
        variant="determinate"
        {...props}
        style={{ height: 33, width: 33 }}
      />
      <Tooltip title="Progress">
        <Box
          className="progress-box"
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            border: "1px solid #c5c5c5",
            borderRadius: "50%",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(value)}%`}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
}
