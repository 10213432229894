import { useEffect, useLayoutEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { _get_user_profile } from "src/DAL";
import { useAppContext } from "src/hooks";
import {
  get_from_localStorage,
  logout_user,
  set_to_localStorage,
} from "src/utils";
import { MuiDialog } from "src/components";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------

const APP_BAR_DESKTOP = "8vh";
const APP_BAR_MOBILE = "8vh";

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100vh",
  overflow: "hidden",
  paddingBottom: "0.1rem",
  // backgroundColor: 'grey',
  position: "relative",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: "4.7rem",
  // paddingTop: '9vh',
  overflowX: "hidden",
  overflowY: "hidden",
  // [theme.breakpoints.up('lg')]: {
  // paddingTop: APP_BAR_DESKTOP,
  // paddingLeft: theme.spacing(2),
  // paddingRight: theme.spacing(2),
  // },
  // backgroundColor: "green",
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const {
    dispatch_set_user_profile,
    limitReachedDialogOpen,
    setLimitReachedDialogOpen,
    noSubscriptionDialog,
    setNoSubscriptionDialog,
    dispatch_set_plan_limits,
    dispatch_set_current_plan,
    connectSocket,
    setKey,
    setKeyLoading,
  } = useAppContext();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(useLocation().search);
  const invitation = searchParams.get("invitation");

  const getUserProfile = async () => {
    try {
      const result = await _get_user_profile();
      console.log(result, "customerDetaillll");
      if (result.code == 200) {
        connectSocket(result.customer.user_id);
        dispatch_set_user_profile(result.customer);
        dispatch_set_plan_limits(result.count_data);
        dispatch_set_current_plan(result.current_plans);
        set_to_localStorage("user", result.customer);
        setKey(result.editor_key);
        if (result.customer.subscription_status == "deleted") {
          setNoSubscriptionDialog(true);
        }
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while fetching user data");
    } finally {
      setKeyLoading(false);
    }
  };
  useLayoutEffect(() => {
    get_from_localStorage("token") && getUserProfile();
    return () => {
      setNoSubscriptionDialog(false);
    };
  }, []);
  // ------------------------------------
  const handleNavigate = () => {
    if (invitation) {
      if (localStorage.getItem("token")) {
        if (pathname === "/") {
          navigate("/invitations", { replace: true });
        }
      }
      if (!localStorage.getItem("token")) {
        navigate("/login", { replace: true, state: { invitation: true } });
      }
    } else {
      if (localStorage.getItem("token")) {
        if (pathname === "/") {
          navigate("/dashboard", { replace: true });
        }
      }
      if (!localStorage.getItem("token")) {
        navigate("/login", { replace: true });
      }
    }
  };

  // ------------------------------------
  useEffect(() => {
    handleNavigate();
  }, [pathname]);
  // ------------------------------------
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login" replace={true} />;
  }
  return (
    <RootStyle id="mui-root-style">
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <MainStyle id="mui-main-style">
        <Outlet />
      </MainStyle>
      <MuiDialog
        open={limitReachedDialogOpen}
        onToggle={(val) => setLimitReachedDialogOpen(val)}
        loading={false}
        title="Your Plan limit reached for this action"
        message="Do you want to upgrade your plan?"
        // onAgree={() => {
        //   window.open(
        //     `${window.location.origin}/plansLink?token=${get_from_localStorage(
        //       "token"
        //     )}`
        //   );
        // }}
        onAgree={() => {
          navigate("/plans");
          setLimitReachedDialogOpen(false);
        }}
      />
      <MuiDialog
        open={noSubscriptionDialog}
        onToggle={(val) => setNoSubscriptionDialog(val)}
        loading={false}
        disableDismiss={true}
        cancelbuttonText="No"
        cancelFunction={() => {
          setNoSubscriptionDialog(false);
          // logout_user();
          // navigate("/");
        }}
        title="No Subscription"
        message="You do not have active subscription, Do you want to buy subscription"
        // onAgree={() => {
        //   window.open(
        //     `${window.location.origin}/plansLink?token=${get_from_localStorage(
        //       "token"
        //     )}`
        //   );
        // }}
        onAgree={() => {
          navigate("/plans");
          setNoSubscriptionDialog(false);
        }}
      />
    </RootStyle>
  );
}
