export const truncateText = (string, maxLength) => {
  if (maxLength == string?.length) {
    return string;
  }
  if (string?.length > maxLength) {
    let result = string.slice(0, maxLength).concat(" " + "...");
    return result;
  }
  return string;
};

/////////////Function for showing the links in <a> tag is task titles/////////////
export const replacedText = (text) => {
  const tempText = text.replace(
    /(\b(https?:\/\/)?(www\.)?\S+\.\S+\b)/gi,
    (match, url) => {
      const fullURL = url.startsWith("http") ? url : `http://${url}`;
      return `<a id="clickablelink" href="${fullURL}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    }
  );
  return { __html: tempText };
};
