import { IconButton, Typography } from "@mui/material";
import moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useAppContext } from "src/hooks";
import MessageMenu from "./MessageMenu";
import { useState } from "react";
import { s3baseUrl } from "src/config";
import { AudioPlayer, Iconify, UserAvatar } from "src/components";

function MessageItem({
  message,
  setIsEditingMessage,
  setNewMessageText,
  setTargetEditMessage,
  prevMessage,
}) {
  const { dispatch_get_user_profile } = useAppContext();
  const [anchorEl, setAnchorEl] = useState(null);
  if (message.sender_id == dispatch_get_user_profile().user_id) {
    return (
      <div>
        <div
          className={`d-flex align-items-center justify-content-end message-margin`}
          style={{
            marginBottom:
              message?.sender_id == prevMessage?.sender_id ? "0px" : "",
          }}
        >
          <div className="mr-1 sent-message message-item">
            {message.image && !message.audio_duration && (
              <>
                <a href={s3baseUrl + message.image} target="_blank">
                  <img
                    src={s3baseUrl + message.image}
                    alt={"image"}
                    style={{
                      width: "180px",
                      height: "180px",
                      objectFit: "contain",
                    }}
                  />
                </a>
                <Typography variant="caption">{message.text}</Typography>
              </>
            )}
            {message.image && message.audio_duration && (
              <AudioPlayer
                duration={message.audio_duration}
                audioUrl={s3baseUrl + message.image}
              />
            )}
            {!message.image && (
              <Typography
                style={{
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  wordBreak: "break-word",
                }}
                className="clickable-link"
              >
                {message.text}
              </Typography>
            )}
            <div>
              <Typography fontSize="11px" textAlign="end">
                {moment(message.createdAt).format("DD MMM, LT")}
              </Typography>
            </div>
          </div>
          <div>
            {message._id && (
              <IconButton size="small" onClick={(e) => setAnchorEl(e.target)}>
                <MoreVertIcon height="10px" width="10px" />
              </IconButton>
            )}
            {!message._id && (
              <IconButton size="small">
                <Iconify icon="solar:clock-square-linear" />
              </IconButton>
            )}
          </div>
        </div>
        <MessageMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          message={message}
          setIsEditingMessage={setIsEditingMessage}
          setNewMessageText={setNewMessageText}
          setTargetEditMessage={setTargetEditMessage}
        />
      </div>
    );
  } else {
    return (
      <div
        className={`d-flex align-items-center message-margin justify-content-start`}
        style={{
          marginBottom:
            message?.sender_id == prevMessage?.sender_id ? "0px" : "",
        }}
      >
        {message.sender_id !== prevMessage?.sender_id ? (
          <div className="d-flex">
            <div className="d-flex align-items-end" style={{ width: "38px" }}>
              <UserAvatar
                image={message.message_sender.image}
                name={
                  message?.message_sender?.first_name +
                  " " +
                  message?.message_sender?.last_name
                }
                sx={{ width: "28px", height: "28px", mr: 1 }}
                alt="sender"
              />
            </div>
            <div>
              <div className="ml-1 message-item received-message">
                {/* {message.chat_type == "1" && (
                  <div>
                    <Typography fontSize="11px" textAlign="start">
                      {message?.message_sender?.first_name +
                        " " +
                        message?.message_sender?.last_name}
                    </Typography>
                  </div>
                )} */}
                {message.image && !message.audio_duration && (
                  <>
                    <img
                      src={s3baseUrl + message?.image}
                      loading="lazy"
                      alt={"image"}
                      style={{
                        width: "180px",
                        height: "180px",
                        objectFit: "contain",
                      }}
                    />
                    <Typography variant="caption">{message?.text}</Typography>
                  </>
                )}
                {message.image && message.audio_duration && (
                  <AudioPlayer
                    duration={message.audio_duration}
                    audioUrl={s3baseUrl + message.image}
                  />
                )}
                {message.image == "" && (
                  <Typography
                    style={{
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      wordBreak: "break-word",
                    }}
                    className="clickable-link"
                  >
                    {message.text}
                  </Typography>
                )}
                <div>
                  <Typography fontSize="11px" textAlign="end">
                    {moment(message.createdAt).format("DD MMM, LT")}
                  </Typography>
                </div>
              </div>
              <div style={{ marginLeft: "4px" }}>
                <Typography fontSize="11px" textAlign="start">
                  {message?.message_sender?.first_name +
                    " " +
                    message?.message_sender?.last_name}
                </Typography>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex">
            <div className="d-flex align-items-end" style={{ width: "38px" }}>
              {/* <UserAvatar
                image={message.message_sender.image}
                name={
                  message?.message_sender?.first_name +
                  " " +
                  message?.message_sender?.last_name
                }
                sx={{ width: "28px", height: "28px", mr: 1 }}
                alt="sender"
              /> */}
            </div>
            <div>
              <div className="ml-1 message-item received-message">
                {/* {message.chat_type == "1" && (
                  <div>
                    <Typography fontSize="11px" textAlign="start">
                      {message?.message_sender?.first_name +
                        " " +
                        message?.message_sender?.last_name}
                    </Typography>
                  </div>
                )} */}
                {message.image !== "" && (
                  <>
                    <img
                      src={s3baseUrl + message?.image}
                      loading="lazy"
                      alt={"image"}
                      style={{
                        width: "180px",
                        height: "180px",
                        objectFit: "contain",
                      }}
                    />
                    <Typography variant="caption">{message?.text}</Typography>
                  </>
                )}
                {message.image == "" && (
                  <Typography
                    style={{
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      wordBreak: "break-word",
                    }}
                    className="clickable-link"
                  >
                    {message.text}
                  </Typography>
                )}
                <div>
                  <Typography fontSize="11px" textAlign="end">
                    {moment(message.createdAt).format("DD MMM, LT")}
                  </Typography>
                </div>
              </div>
              {/* <div style={{ marginLeft: "4px" }}>
                <Typography fontSize="11px" textAlign="start">
                  {message?.message_sender?.first_name +
                    " " +
                    message?.message_sender?.last_name}
                </Typography>
              </div> */}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MessageItem;
