import {
  Card,
  Container,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { capitalCase } from "change-case";
import { endOfQuarter } from "date-fns";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { _get_invoice_detail, _update_invoice_status } from "src/DAL";
import {
  CircularLoader,
  Iconify,
  Label,
  Page,
  Scrollbar,
} from "src/components";
import ListHead from "src/components/ListHead";
import ListToolbar from "src/components/ListToolbar";
import { s3baseUrl } from "src/config";
import { useAppContext } from "src/hooks";
import { convertCurrencyToSign } from "src/utils/constant";
const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "title", label: "Task Title", alignRight: false },
  { id: "workspace", label: "Workspace", alignRight: false },
  { id: "project", label: "Project", alignRight: false },
  { id: "time", label: "Time Consumed", alignRight: false },
  { id: "perHourRate", label: "Per Hour Rate", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
];
function InvoiceDetails() {
  const { dispatch_get_user_profile } = useAppContext();
  const { state } = useLocation();
  console.log(state);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [invoiceData, setInvoiceData] = useState();
  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState("details");
  const [page, setPage] = useState(0);
  const [filterName, setFilterName] = useState("");
  const [subMenuOpen, setSubMenuOpen] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleOpenSubMenu = (e) => {
    if (
      dispatch_get_user_profile().user_id ==
        invoiceData?.invoice_client_data?.user_id &&
      invoiceData.invoice_status == "verified"
    ) {
      return;
    }
    setSubMenuOpen(e.target);
  };
  const handleCloseSubMenu = () => {
    setSubMenuOpen(null);
  };
  const changeInvoiceStatus = async (status) => {
    try {
      if (!invoiceData) {
        console.log("no invoice selected");
        return;
      }
      const result = await _update_invoice_status({
        invoice_id: invoiceData._id,
        status,
      });
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setInvoiceData((prev) => {
          return {
            ...prev,
            invoice_status: status,
            updatedAt: moment().toISOString(),
          };
        });
        handleCloseSubMenu();
      }
    } catch (error) {
      console.log(error, " catched while changing invoice status");
    }
  };
  const subMenuOptions = [
    {
      label: "Pending",
      value: "pending",
      clickHandler: () => {
        changeInvoiceStatus("pending");
      },
    },
    {
      label: "Paid",
      value: "paid",
      clickHandler: () => {
        changeInvoiceStatus("paid");
      },
    },
    {
      label: "Verified",
      value: "verified",
      clickHandler: () => {
        changeInvoiceStatus("verified");
      },
    },
  ];
  const getFilteredSubMenu = () => {
    if (
      dispatch_get_user_profile().user_id ==
        invoiceData?.invoice_client_data?.user_id &&
      invoiceData.invoice_status == "verified"
    ) {
      return [];
    } else if (
      dispatch_get_user_profile().user_id ==
      invoiceData?.invoice_client_data?.user_id
    ) {
      return subMenuOptions.filter((item) => item.label !== "Verified");
    } else {
      return subMenuOptions;
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getInvoiceDetail = async () => {
    try {
      const result = await _get_invoice_detail(state._id);
      if (result.code == 200) {
        setInvoiceData(result.invoice_detail);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error, " catched while fetching detail of invoice");
    } finally {
      setLoading(false);
    }
  };
  const filteredTasks = () => {
    if (!filterName) {
      return [...invoiceData.tasks];
    } else {
      return invoiceData.tasks?.filter((x) =>
        x.task_title.toLowerCase().includes(filterName.toLowerCase())
      );
    }
  };
  useLayoutEffect(() => {
    if (!state) {
      navigate(-1, { replace: true });
    } else {
      getInvoiceDetail();
    }
  }, []);
  return (
    <Page title="Invoice Details">
      {loading && <CircularLoader />}
      {invoiceData && (
        <Container maxWidth="xl">
          <Card sx={{ p: 3 }}>
            <div className="custom-tabs">
              <Tabs
                value={currentTab}
                onChange={(e, val) => setCurrentTab(val)}
              >
                <Tab label="Invoice Details" value={"details"} />
                <Tab label="Tasks" value={"tasks"} />
              </Tabs>
            </div>
            {currentTab == "details" && (
              <Grid container spacing={3} mt={1}>
                <Grid item xs={12} sm={4}>
                  <div className="d-flex">
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      fontSize={"13px"}
                      mr={1}
                    >
                      Invoice Status
                    </Typography>
                    {(invoiceData.invoice_status !== "verified" ||
                      dispatch_get_user_profile().user_id !==
                        invoiceData.invoice_client_data.user_id) && (
                      <Tooltip title="Click the staus to update">
                        <div>
                          <Iconify icon="ic:sharp-info" />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                  <Label
                    sx={{
                      cursor: "pointer",
                      minWidth: "90px",
                      height: "30px",
                      marginTop: "8px",
                    }}
                    onClick={handleOpenSubMenu}
                    variant="ghost"
                    color={
                      invoiceData.invoice_status == "pending"
                        ? "error"
                        : invoiceData.invoice_status == "verified"
                        ? "success"
                        : "warning"
                    }
                  >
                    {capitalCase(invoiceData?.invoice_status)}
                    {(invoiceData.invoice_status !== "verified" ||
                      dispatch_get_user_profile().user_id !==
                        invoiceData.invoice_client_data.user_id) && (
                      <>
                        {subMenuOpen && (
                          <Iconify
                            icon="eva:arrow-ios-upward-fill"
                            sx={{
                              height: "15px",
                              width: "15px",
                              ml: 1,
                              pointerEvents: "none",
                            }}
                          />
                        )}
                        {!subMenuOpen && (
                          <Iconify
                            icon="eva:arrow-ios-downward-fill"
                            sx={{
                              height: "15px",
                              width: "15px",
                              ml: 1,
                              pointerEvents: "none",
                            }}
                          />
                        )}
                      </>
                    )}
                  </Label>
                  <Menu
                    open={Boolean(subMenuOpen)}
                    anchorEl={subMenuOpen}
                    onClose={handleCloseSubMenu}
                    // anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    // transformOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    {getFilteredSubMenu().map((option) => (
                      <MenuItem
                        key={option.label}
                        onClick={option.clickHandler}
                        selected={invoiceData?.invoice_status == option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
                <Grid item xs={12} sm={4}>
                  {invoiceData?.image && (
                    <div className="d-flex justify-content-center">
                      <a href={s3baseUrl + invoiceData.image} target="_blank">
                        <img
                          src={s3baseUrl + invoiceData.image}
                          height={"100px"}
                          width={"100px"}
                          style={{ objectFit: "contain" }}
                        />
                      </a>
                    </div>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    fontSize={"13px"}
                    mr={1}
                  >
                    Updated at:
                  </Typography>
                  <Typography variant="subtitle">
                    {moment(invoiceData?.updatedAt).format("LLL")}
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Divider sx={{ width: "100%", borderColor: "black" }}>
                    Invoice Info
                  </Divider>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant="h6" fontWeight={600} fontSize={"13px"}>
                    Invoice Title
                  </Typography>
                  <Typography variant="subtitle">
                    {invoiceData?.title}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant="h6" fontWeight={600} fontSize={"13px"}>
                    Invoice Number
                  </Typography>
                  <Typography variant="subtitle">{invoiceData?._id}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant="h6" fontWeight={600} fontSize={"13px"}>
                    Created at
                  </Typography>
                  <Typography variant="subtitle">
                    {moment(invoiceData?.createdAt).format("LLL")}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant="h6" fontWeight={600} fontSize={"13px"}>
                    Invoice Issue Date
                  </Typography>
                  <Typography variant="subtitle">
                    {invoiceData?.invoice_issue_date}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant="h6" fontWeight={600} fontSize={"13px"}>
                    Invoice Due date
                  </Typography>
                  <Typography variant="subtitle">
                    {invoiceData?.invoice_due_date}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant="h6" fontWeight={600} fontSize={"13px"}>
                    Projects
                  </Typography>
                  <Typography variant="subtitle">
                    {invoiceData.projects
                      .map((project) => project.label)
                      .join(", ")}
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Divider sx={{ width: "100%", borderColor: "black" }}>
                    Invoice Description
                  </Divider>
                </Grid>
                <Grid item xs={12}>
                  {invoiceData.description && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: invoiceData?.description,
                      }}
                    />
                  )}
                  {!invoiceData.description && (
                    <div className="text-center">
                      <Typography fontSize={"12px"}>
                        No Description Added!
                      </Typography>
                    </div>
                  )}
                </Grid>
                {invoiceData.invoice_client_data.user_id !==
                  dispatch_get_user_profile().user_id && (
                  <>
                    <Grid item md={12} xs={12}>
                      <Divider sx={{ width: "100%", borderColor: "black" }}>
                        Client Info
                      </Divider>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={"13px"}
                      >
                        Client Name
                      </Typography>
                      <Typography variant="subtitle">
                        {invoiceData?.invoice_client_data?.first_name +
                          " " +
                          invoiceData?.invoice_client_data?.last_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={"13px"}
                      >
                        Client Email
                      </Typography>
                      <Typography variant="subtitle">
                        {invoiceData?.invoice_client_data?.email}
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} lg={4}>
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={"13px"}
                      >
                        Client Phone
                      </Typography>
                      <Typography variant="subtitle">
                        {invoiceData.invoice_client_data.phone_number
                          ? "(" +
                            invoiceData?.invoice_client_data?.country_code +
                            ") " +
                            invoiceData?.invoice_client_data?.phone_number
                          : "N/A"}
                      </Typography>
                    </Grid> */}
                  </>
                )}
                {invoiceData.invoice_client_data.user_id ===
                  dispatch_get_user_profile().user_id && (
                  <>
                    <Grid item md={12} xs={12}>
                      <Divider sx={{ width: "100%", borderColor: "black" }}>
                        Invoice Creator Info
                      </Divider>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={"13px"}
                      >
                        Creator Name
                      </Typography>
                      <Typography variant="subtitle">
                        {invoiceData?.invoice_creater_data?.first_name +
                          " " +
                          invoiceData?.invoice_creater_data?.last_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={"13px"}
                      >
                        Creator Email
                      </Typography>
                      <Typography variant="subtitle">
                        {invoiceData?.invoice_creater_data?.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={"13px"}
                      >
                        Creator Phone
                      </Typography>
                      <Typography variant="subtitle">
                        {invoiceData.invoice_creater_data.phone_number
                          ? "(" +
                            invoiceData?.invoice_creater_data?.country_code +
                            ") " +
                            invoiceData?.invoice_creater_data?.phone_number
                          : "N/A"}
                      </Typography>
                    </Grid>
                  </>
                )}
                <Grid item md={12} xs={12}>
                  <Divider sx={{ width: "100%", borderColor: "black" }}>
                    Payment Info
                  </Divider>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant="h6" fontWeight={600} fontSize={"13px"}>
                    Amount
                  </Typography>
                  <Typography variant="subtitle">
                    {convertCurrencyToSign(invoiceData.currency) + " "}
                    {Number(invoiceData?.total_price)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant="h6" fontWeight={600} fontSize={"13px"}>
                    Discount
                  </Typography>
                  <Typography variant="subtitle">
                    {convertCurrencyToSign(invoiceData.currency) + " "}{" "}
                    {Number(invoiceData?.discount)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant="h6" fontWeight={600} fontSize={"13px"}>
                    Tax
                  </Typography>
                  <Typography variant="subtitle">
                    {Number(invoiceData?.tax)} %
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant="h6" fontWeight={600} fontSize={"13px"}>
                    Subtotal
                  </Typography>
                  <Typography variant="subtitle">
                    {convertCurrencyToSign(invoiceData.currency) + " "}{" "}
                    {Number(invoiceData?.final_price)}
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Divider sx={{ width: "100%", borderColor: "black" }}>
                    Reminder Info
                  </Divider>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant="h6" fontWeight={600} fontSize={"13px"}>
                    Reminder Title
                  </Typography>
                  <Typography variant="subtitle">
                    {invoiceData?.reminder_title}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant="h6" fontWeight={600} fontSize={"13px"}>
                    Reminder Date
                  </Typography>
                  <Typography variant="subtitle">
                    {invoiceData?.reminder_date
                      ? moment(invoiceData.reminder_date).format("LLL")
                      : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ width: "100%", borderColor: "black" }}>
                    Reminder Description
                  </Divider>
                </Grid>
                <Grid item xs={12}>
                  {invoiceData.reminder_description && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: invoiceData?.reminder_description,
                      }}
                    />
                  )}
                  {!invoiceData.reminder_description && (
                    <div className="text-center">
                      <Typography fontSize={"12px"}>
                        No Description Added!
                      </Typography>
                    </div>
                  )}
                </Grid>
              </Grid>
            )}
            {currentTab == "tasks" && (
              <>
                <ListToolbar
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                  moduleName="Invoice Tasks"
                />
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <ListHead headLabel={TABLE_HEAD} />
                      <TableBody>
                        {filteredTasks()
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            // row1.team.map((row) => {
                            const {
                              task_title,
                              _id,
                              time_track,
                              per_hour_rate,
                              task_billable_amount,
                              project,
                              workspace,
                            } = row;
                            // console.log(
                            //   time_track.final_time,
                            //   "iuauihuiacuiahsuciac"
                            // );
                            return (
                              <TableRow hover key={_id} tabIndex={-1}>
                                <TableCell>
                                  <Typography variant="subtitle2">
                                    {rowsPerPage * page + (index + 1)}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ maxWidth: "300px" }}
                                >
                                  <Typography variant="subtitle2">
                                    {task_title}
                                  </Typography>
                                </TableCell>
                                <TableCell>{workspace?.name}</TableCell>
                                <TableCell>{project.name}</TableCell>
                                <TableCell>
                                  {time_track.final_time
                                    ? time_track.final_time
                                    : "-"}
                                </TableCell>
                                <TableCell>
                                  {convertCurrencyToSign(invoiceData.currency) +
                                    " "}
                                  {Number(per_hour_rate)}
                                </TableCell>
                                <TableCell>
                                  {convertCurrencyToSign(invoiceData.currency) +
                                    " "}
                                  {Number(task_billable_amount)}
                                </TableCell>
                              </TableRow>
                            );
                            // });
                          })}
                        {filteredTasks().length == 0 && (
                          <TableRow style={{ height: 53 }}>
                            <TableCell colSpan={6} align="center">
                              No tasks added!
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={filteredTasks().length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </Card>
        </Container>
      )}
    </Page>
  );
}

export default InvoiceDetails;
