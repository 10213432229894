import React from "react";
// material
import {
  Stack,
  Avatar,
  Box,
  Typography,
  ButtonBase,
  Paper,
  Tooltip,
} from "@mui/material";
import { s3baseUrl } from "src/config";
import { capitalCase } from "change-case";
import { Label } from "src/components";
// ---------------------------------------------------------
const WorkspaceCard = ({ item, onRemove, role }) => {
  const { createdAt, _id, description, image, name } = item;
  // ---------------------------------------------------------
  return (
    <Paper elevation={3} sx={{ p: 1 }}>
      <Stack direction="column">
        <Stack direction="row" sx={{ mb: 2 }}>
          <Box alignItems="center" justifyContent="center">
            {image ? (
              <Avatar src={s3baseUrl + image} />
            ) : (
              <Avatar>{name.substring(0, 2)}</Avatar>
            )}
          </Box>
          <Typography
            sx={{ alignSelf: "center", ml: 1 }}
            variant="body1"
            noWrap
          >
            {name}
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Stack direction="row" spacing={1}>
            <Typography fontWeight="bold" variant="body1">
              Role
            </Typography>
            <Typography variant="body1" noWrap>
              {capitalCase(role)}
            </Typography>
          </Stack>
          {description && (
            <Stack direction="row" spacing={1}>
              <Typography fontWeight="bold" variant="body1">
                Description
              </Typography>
              {description ? (
                <div dangerouslySetInnerHTML={{ __html: description }} />
              ) : (
                <></>
              )}
              {/* <Typography variant="body1">
              {description ? description : "..."}
            </Typography> */}
            </Stack>
          )}
          <Stack direction="row" justifyContent="center" spacing={1}>
            <Tooltip title="remove from workspace">
              <ButtonBase onClick={() => onRemove(item)}>
                <Label
                  sx={{ "&:hover": { cursor: "pointer" } }}
                  color="error"
                  variant="ghost"
                >
                  Remove
                </Label>
              </ButtonBase>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default WorkspaceCard;
