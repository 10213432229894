import React, {
  useState,
  useEffect,
  useTransition,
  useLayoutEffect,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  _getChatList,
  _getChatMessages,
  _getUnreadMessages,
  _get_user_profile,
  _google_login,
  _google_logout,
  _seenMessages,
} from "src/DAL";
import { baseUri, s3baseUrl } from "src/config";
import {
  del_from_local_storage,
  get_from_localStorage,
  logout_user,
  set_to_localStorage,
} from "src/utils";
import socketIOClient from "socket.io-client";
import { useSnackbar } from "notistack";
import { logoSmall } from "src/assets";
import {
  _notificationList,
  _notificationUnreadCount,
} from "src/DAL/notifications";
import { ConstructionOutlined } from "@mui/icons-material";
import {
  signInWithGoogle,
  signOutFromGoogle,
} from "src/pages/dashboard/components/googleAuth";
import moment from "moment";
// import { connect_socket, getSocket } from "./socket";
const Context = React.createContext();
const AppContext = ({ children }) => {
  const { pathname } = useLocation();
  const [profile, setProfile] = useState({
    first_name: "",
    last_name: "",
    email: "",
    image: "",
    contact_number: "",
    biography: "",
    gender: "",
  });
  const { enqueueSnackbar } = useSnackbar();
  const [searchValue, setSearchValue] = useState("");
  const [currentSelectedItem, setCurrentSelectedItem] = useState(undefined);
  const [globalSearchVisible, setGlobalSearchVisible] = useState(false);
  const [isPending, startTransition] = useTransition();
  const [key, setKey] = useState("");
  const [keyLoading, setKeyLoading] = useState(true);
  const [badge, setBadge] = useState({
    chat: 0,
    invitation: 0,
    support: 0,
  });
  const [planLimits, setPlanLimits] = useState({
    workspace_count: 0,
    project_count: 0,
    team_manager_count: 0,
    client_count: 0,
    total_team_count: 0,
  });
  const [currentPlan, setCurrentPlan] = useState({});
  const [limitReachedDialogOpen, setLimitReachedDialogOpen] = useState(false);
  const [noSubscriptionDialog, setNoSubscriptionDialog] = useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [logoutRadioValue, setLogoutRadioValue] = useState("0");
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState();
  const [startSocket, setStartSocket] = useState(false);
  //------------------------------------------------------

  const dispatch_get_user_profile = () => {
    return profile;
  };
  const dispatch_set_user_profile = (value) => {
    setProfile(value);
  };
  const dispatch_set_plan_limits = (limits) => {
    setPlanLimits(limits);
  };
  const dispatch_get_plan_limits = () => {
    return planLimits;
  };
  const dispatch_set_current_plan = (plan) => {
    setCurrentPlan(plan);
  };
  const dispatch_get_current_plan = () => {
    return currentPlan;
  };
  const dispatch_get_search_value = () => {
    return searchValue;
  };
  const dispatch_set_search_value = (value) => {
    setSearchValue(value);
  };
  const dispatch_get_current_selected_item = () => {
    return currentSelectedItem;
  };
  const dispatch_set_current_selected_item = (value) => {
    setCurrentSelectedItem(value);
  };
  const dispatch_get_search_visibility = () => {
    return globalSearchVisible;
  };
  const dispatch_set_search_visibility = (value) => {
    setGlobalSearchVisible(value);
  };
  //------------------------------------------------------
  useLayoutEffect(() => {
    if (get_from_localStorage("user")) {
      setProfile(get_from_localStorage("user"));
    }
    return () => {
      //cleanup
    };
  }, []);
  useEffect(() => {
    if (get_from_localStorage("workspaces")) {
      if (!pathname.includes("/workspaces")) {
        del_from_local_storage("workspaces");
      }
    }
    if (
      !pathname.includes("/workspaces/") &&
      get_from_localStorage("projects")
    ) {
      setSlugProjectsData([]);
      del_from_local_storage("projects");
    }
    if (
      !pathname.includes("/invoices") &&
      get_from_localStorage("invoiceTab")
    ) {
      del_from_local_storage("invoiceTab");
    }
  }, [useNavigate()]);

  const showSnackbar = (result) => {
    enqueueSnackbar(result.message, {
      variant: result.code == 200 ? "success" : "error",
    });
  };

  const showCustomSnackbar = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };
  //=========================Notifications===============================================================

  const [notifications, setNotifications] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const getNotificationList = async (pg = 0) => {
    pg !== 0 && setLoading(true);
    const result = await _notificationList(pg);
    console.log(result, "notifications list");
    if (result.code == 200) {
      const temp = result.notification_list.notification.map((notification) => {
        return {
          ...notification,
          isUnRead: !notification.is_seen,
        };
      });
      setNotifications((prev) => {
        return pg ? prev.concat(temp) : temp;
      });
      setPage(pg + 1);
      setCount(result.notification_list.notification_count);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setLoading(false);
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////===========Desktop Notifications==========================================================

  const desktopNotification = (title, body, link) => {
    try {
      if ("Notification" in window && "hidden" in document) {
        // Request permission for displaying notifications
        Notification.requestPermission()
          .then(function (permission) {
            if (permission === "granted" && document.hidden) {
              // Create a new notification when the tab is hidden
              var notification = new Notification(title, {
                body: body,
              });

              // Optional: Handle click event on the notification
              notification.onclick = function () {
                if (link) {
                  window.location.href = link;
                  window.focus();
                } else {
                  window.focus();
                }
              };
            }
          })
          .catch(function (error) {
            console.error("Notification permission denied:", error);
          });
      } else {
        console.log("Notifications are not supported in this browser.");
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      if ("Notification" in window && Notification.permission !== "granted") {
        Notification.requestPermission();
      } else {
        console.log("Notifications are not allowed");
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error);
    }
  }, []);

  ///===================Notifications==============================================
  const [notificationCount, setNotificationCount] = useState(0);
  const [unreadNotification, setUnreadNotification] = useState(0);
  const getNotificationCount = async () => {
    if (result.code == 200) {
      setNotificationCount(result.unread_notification_count);
      setBadge((prev) => {
        return {
          ...prev,
          chat: result.message_count.totall_unread_message_count,
          invitation: result.message_count.total_invitation_count,
        };
      });
    }
  };
  // useEffect(() => {
  //   get_from_localStorage("token") && getNotificationCount();
  // }, [profile]);

  const [slugProjectsData, setSlugProjectsData] = useState(
    // get_from_localStorage("projects") ??
    {
      loadMoreUrl: "",
      projects: [],
      count: 0,
    }
  );

  /////////////////////////////NEW CHAT/////////////////////////////////////////////
  const [messageList, setMessageList] = useState([]);
  const [messageCount, setMessageCount] = useState(0);
  const [chatListDrawerOpen, setChatListDrawerOpen] = useState(false);
  const [currentChat, setCurrentChat] = useState(
    get_from_localStorage("currentChat")
      ? get_from_localStorage("currentChat")
      : null
  );
  const [chatList, setChatList] = useState([]);
  const [chatCount, setChatCount] = useState(0);
  const [chatType, setChatType] = useState(
    get_from_localStorage("chatType")
      ? get_from_localStorage("chatType")
      : "all"
  );
  useEffect(() => {
    set_to_localStorage("chatType", chatType);
    currentChat && set_to_localStorage("currentChat", currentChat);
  }, [chatType, currentChat]);
  //////////////////Functions////////////////////
  const getModifiedChatItem = (chat) => {
    if (chat.type == "0") {
      return {
        type: chat.type,
        creator_id: chat.chat_creator_id,
        label:
          chat.opposite_user?.first_name + " " + chat.opposite_user?.last_name,
        image:
          chat.opposite_user?.image !== ""
            ? s3baseUrl + chat.opposite_user?.image
            : "",
        _id: chat._id,
        is_online:
          chat.opposite_user?.mobile_app_status.online == "1" ||
          chat.opposite_user?.web_portal_status.online == "1",
        user: chat.opposite_user ? [chat.opposite_user] : null,
        unread_count: chat.unread_count,
        last_message: chat.last_message,
      };
    } else if (chat.type == "1") {
      return {
        type: chat.type,
        label: chat.group_name,
        creator_id: chat.chat_creator_id,
        image: chat.group_image !== "" ? s3baseUrl + chat.group_image : "",
        _id: chat._id,
        user: chat.member_user,
        unread_count: chat.unread_count,
        last_message: chat.last_message,
      };
    }
  };
  const getUnreadCount = async () => {
    const result = await _getUnreadMessages();
    if (result.code == 200) {
      setBadge((prev) => {
        return {
          ...prev,
          chat: result.message_count.totall_unread_message_count,
          invitation: result.message_count.total_invitation_count,
        };
      });
    }
  };
  //////////////////////////////////////////////
  const onSocketConnection = (data) => {
    console.log("Connected to server");
    setStartSocket(true);
  };
  const onSocketDisconnect = (reason) => {
    console.log("Disconnected from server:", reason);
    setStartSocket(false);
  };
  const generateUniqueIdForMessage = () => {
    let id = moment().format("x") * Math.random();
    return id.toString();
  };
  const addMessageSocket = (data) => {
    console.log(data, "add-message");
    if (data.sender_id !== profile.user_id) {
      desktopNotification(
        "Done",
        `New message received from ${
          data.message_sender.first_name + " " + data.message_sender.last_name
        }`
      );
    }
    if (pathname == "/chats") {
      setChatList((prev) => {
        const index = prev.findIndex((element) => element._id == data.chat_id);
        if (index === -1) {
          return prev;
        }

        const temp = prev[index];
        for (let i = index; i > 0; i--) {
          prev[i] = prev[i - 1];
        }

        prev[0] = temp;
        return prev;
      });
      if (data.chat_id == currentChat?._id) {
        if (data.sender_id == profile.user_id) {
          const tempId = data.temp_id;
          setMessageList((prev) => {
            return prev.map((message) => {
              if (message.temp_id == tempId) {
                return { ...data };
              } else {
                return { ...message };
              }
            });
          });
        } else {
          setMessageList((prev) => [data, ...prev]);
        }
        setMessageCount((prev) => prev + 1);
        setChatList((prev) => {
          return prev.map((chat) => {
            if (chat._id == data.chat_id) {
              return { ...chat, last_message: { ...data } };
            } else {
              return { ...chat };
            }
          });
        });
        _seenMessages(currentChat._id);
      } else {
        setChatList((prev) => {
          return prev.map((chat) => {
            if (chat._id == data.chat_id) {
              return {
                ...chat,
                unread_count: chat.unread_count + 1,
                last_message: { ...data },
              };
            } else {
              return { ...chat };
            }
          });
        });
      }
    } else {
      enqueueSnackbar("New message received!", { variant: "info" });
    }
    if (data.chat_id != currentChat?._id) {
      setBadge((prev) => {
        return {
          ...prev,
          chat: prev.chat + 1,
        };
      });
    }
  };
  const updatedMessageReceiver = (data) => {
    if (pathname == "/chats" && currentChat?._id == data.chat_id) {
      setMessageList((prev) => {
        return prev.map((message) => {
          if (message._id == data._id) {
            return { ...message, text: data.text };
          } else {
            return { ...message };
          }
        });
      });
    }
  };
  const deleteMessageReceiver = (data) => {
    console.log(data);
    if (pathname == "/chats") {
      if (currentChat?._id == data.chat_id) {
        setMessageList((prev) => {
          return prev.filter((message) => message._id !== data._id);
        });
        setMessageCount((prev) => prev - 1);
      } else if (data.unread_users.find((id) => id == profile.user_id)) {
        setChatList((prev) => {
          return prev.map((chat) => {
            if (chat._id == data.chat_id && chat.unread_count > 0) {
              return { ...chat, unread_count: chat.unread_count - 1 };
            } else {
              return { ...chat };
            }
          });
        });
      }
    }
  };
  const deleteChat = (data) => {
    if (pathname == "/chats") {
      if (currentChat?._id == data._id) {
        setMessageList([]);
        setMessageCount(0);
        setCurrentChat();
        del_from_local_storage("currentChat");
      }
      setChatList((prev) => {
        return prev.filter((chat) => chat._id !== data._id);
      });
      setChatCount((prev) => prev - 1);
    }
  };
  const addChat = (data) => {
    if (pathname == "/chats") {
      if (chatType == "all") {
        setChatList((prev) => {
          return [getModifiedChatItem(data), ...prev];
        });
      } else {
        if (data.type == "1") {
          setChatList((prev) => {
            return [getModifiedChatItem(data), ...prev];
          });
        }
      }
    }
  };
  const receiveUpdateNewChat = (data) => {
    if (pathname == "/chats") {
      if (currentChat?._id == data._id) {
        setCurrentChat(getModifiedChatItem(data));
      }
      setChatList((prev) => {
        return prev.map((chat) => {
          if (chat._id == data._id) {
            return { ...getModifiedChatItem(data) };
          } else {
            return { ...chat };
          }
        });
      });
    }
  };
  const userStatusChange = (data) => {
    if (pathname == "/chats" && chatType == "all") {
      if (
        currentChat?.user[0].user_id == data.user_id &&
        currentChat?.type == "0"
      ) {
        setCurrentChat((prev) => {
          return {
            ...prev,
            user: [data],
            is_online:
              data.mobile_app_status.online == "1" ||
              data.web_portal_status.online == "1",
          };
        });
      }
      if (chatType == "all") {
        setChatList((prev) => {
          return prev.map((chat) => {
            if (chat.type == "0" && chat.user[0].user_id == data.user_id) {
              return {
                ...chat,
                user: [data],
                is_online:
                  data.mobile_app_status.online == "1" ||
                  data.web_portal_status.online == "1",
              };
            } else {
              return { ...chat };
            }
          });
        });
      }
    }
  };
  const receiveNotification = (data) => {
    desktopNotification("Done Workspace", data.message, data.link);
    enqueueSnackbar(data.message, { variant: "info" });
    // setNotificationCount((prev) => prev + 1);
    socketEmit("send_unread_count", { user_id: profile.user_id });
    // getNotificationCount();
    // getUnreadCount();
  };
  const receiveUnreadCount = (data) => {
    setNotificationCount(data.unread_count);
    setUnreadNotification(data.notification_count);
    setBadge((prev) => {
      return {
        ...prev,
        chat: data.message_count.totall_unread_message_count,
        invitation: data.message_count.total_invitation_count,
        support: data.unread_support_ticket_count,
      };
    });
  };
  //////////////////////////////////////////////////////////////////////////////////
  ///===============Socket.io=========================================================
  const [socket, setSocket] = useState({
    emit: () => {},
    on: () => {},
    off: () => {},
    disconnect: () => {},
    connected: false,
    connecting: false,
  });
  const connectSocket = (user_id) => {
    const ENDPOINT = `${baseUri}?userId=${user_id}&type=${1}`;
    setSocket(
      socketIOClient(ENDPOINT, {
        transports: ["polling"],
        reconnectionDelayMax: 10000,
      })
    );

    setStartSocket(true);
    console.log("socket connection initiated");
  };
  const socketEmit = (name, data) => {
    socket.emit(name, data);
  };
  const disconnectSocket = () => {
    socket.disconnect();
  };
  useEffect(() => {
    if (startSocket) {
      //socketEmit(`create`, profile.user_id.trim());
    }
  }, [startSocket]);
  const listenersArray = [
    {
      listener: "connect",
      action: onSocketConnection,
    },
    { listener: "chatMessage", action: addMessageSocket },
    {
      listener: "disconnect",
      action: onSocketDisconnect,
    },
    { listener: "userStatus", action: userStatusChange },
    {
      listener: "receiveDeleteMessage",
      action: deleteMessageReceiver,
    },
    {
      listener: "editMessageReceive",
      action: updatedMessageReceiver,
    },
    {
      listener: "receiver_notification",
      action: receiveNotification,
    },
    {
      listener: "receiveDeleteGroupChat",
      action: deleteChat,
    },
    { listener: "receiveAddNewChat", action: addChat },
    {
      listener: "receiveUpdateNewChat",
      action: receiveUpdateNewChat,
    },
    { listener: "receive_unread_count", action: receiveUnreadCount },
    ///////////////////////////////////////////////////////////////////////////
    ///////////////listeners for dbugging//////////////////////////////////////

    // {
    //   listener: "pong",
    //   action: () => {
    //     console.log("Received pong from server");
    //   },
    // },
    {
      listener: "error",
      action: (error) => {
        console.log("Socket temporary event error, ", error);
      },
    },
    {
      listener: "reconnect",
      action: (attempt) => {
        console.log("Socket temporary event reconnect, ", attempt);
      },
    },
  ];

  useEffect(() => {
    listenersArray.map((listener) => {
      socket.on(listener.listener, listener.action);
    });

    return () => {
      listenersArray.map((listener) => {
        socket.off(listener.listener, listener.action);
      });
    };
  }, [
    socket,
    slugProjectsData,
    profile,
    messageList,
    currentChat,
    chatList,
    messageCount,
    chatCount,
    chatType,
    useNavigate(),
  ]);
  //================Google conection////////////////////////
  const [disconnectGoogleDialogOpen, setDisconnectGoogleDialogOpen] =
    useState(false);
  const [googleConnectionLoading, setGoogleConnectionLoading] = useState(false);
  const handleConnectionWithGoogleCalendar = async () => {
    try {
      const res = await signInWithGoogle();
      if (res) {
        const { email, result } = res;
        const response = await _google_login({
          code: result.code,
          email,
          time_zone: moment.tz.guess(),
        });
        if (response.code == 200) {
          enqueueSnackbar("Syncronized with Google successfully", {
            variant: "success",
          });
          dispatch_set_user_profile(response.data.user_data);
          set_to_localStorage("user", response.data.user_data);
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
        }
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      enqueueSnackbar(error, { variant: "error" });
      setGoogleConnectionLoading(false);
    }
  };
  const handleDisconnectfromGoogleCalendar = async () => {
    try {
      setGoogleConnectionLoading(true);
      const result = await _google_logout();
      if (result.code == 200) {
        setDisconnectGoogleDialogOpen(false);
        let user = dispatch_get_user_profile();
        delete user.google_account_email;
        delete user.refresh_token;
        dispatch_set_user_profile(user);
        set_to_localStorage("user", user);
        signOutFromGoogle();
        enqueueSnackbar("Disconnected from google account", {
          variant: "success",
        });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while disconnecting from google");
    } finally {
      setGoogleConnectionLoading(false);
    }
  };
  const startLoading = () => {
    setGoogleConnectionLoading(true);
    setTimeout(() => {
      setGoogleConnectionLoading(false);
    }, 5000);
  };
  const handleLogout = () => {
    setLogoutDialogOpen(false);
    logout_user(logoutRadioValue);
    setLogoutRadioValue("0");
    setUnreadNotification(0);
    setNotificationCount(0);
    setBadge({
      chat: 0,
      invitation: 0,
      support: 0,
    });
    disconnectSocket();
    navigate("/login", { replace: true });
    setDashboardData();
    setSocket(socketIOClient());
  };
  //------------------------------------------------------
  const bundle = {
    socket,
    disconnectGoogleDialogOpen,
    setDisconnectGoogleDialogOpen,
    googleConnectionLoading,
    setGoogleConnectionLoading,
    handleConnectionWithGoogleCalendar,
    handleDisconnectfromGoogleCalendar,
    startLoading,
    dispatch_get_user_profile,
    dispatch_set_user_profile,
    dispatch_set_plan_limits,
    dispatch_get_plan_limits,
    dispatch_set_current_plan,
    dispatch_get_current_plan,
    limitReachedDialogOpen,
    setLimitReachedDialogOpen,
    noSubscriptionDialog,
    setNoSubscriptionDialog,
    dispatch_get_search_value,
    dispatch_set_search_value,
    dispatch_get_current_selected_item,
    dispatch_set_current_selected_item,
    dispatch_get_search_visibility,
    dispatch_set_search_visibility,
    messageCount,
    setMessageCount,
    socketEmit,
    slugProjectsData,
    setSlugProjectsData,
    notificationCount,
    setNotificationCount,
    messageList,
    setMessageList,
    currentChat,
    setCurrentChat,
    chatList,
    setChatList,
    chatCount,
    setChatCount,
    chatType,
    setChatType,
    getModifiedChatItem,
    chatListDrawerOpen,
    setChatListDrawerOpen,
    badge,
    setBadge,
    disconnectSocket,
    generateUniqueIdForMessage,
    showSnackbar,
    showCustomSnackbar,
    notifications,
    setNotifications,
    count,
    setCount,
    page,
    setPage,
    loading,
    setLoading,
    getNotificationList,
    unreadNotification,
    setUnreadNotification,
    handleLogout,
    logoutDialogOpen,
    setLogoutDialogOpen,
    logoutRadioValue,
    setLogoutRadioValue,
    connectSocket,
    dashboardData,
    setDashboardData,
    key,
    setKey,
    keyLoading,
    setKeyLoading,
  };
  return <Context.Provider value={bundle}>{children}</Context.Provider>;
};

export default AppContext;
export const useAppContext = () => React.useContext(Context);
