import { TabContext, TabList } from "@mui/lab";
import {
  CircularProgress,
  IconButton,
  Tab,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import StyledChatSearch from "src/components/search/StyledChatSearch";
import ChatList from "./ChatList";
import AddIcon from "@mui/icons-material/Add";
import { useAppContext } from "src/hooks";

function ChatListSidebar({
  chatType,
  setChatType,
  chatListLoading,
  chatCount,
  chatListPage,
  currentChat,
  setCurrentChat,
  loadMoreChats,
  setIsAddChatDrawerOpen,
  onChatClick,
  searchValue,
  setSearchValue,
}) {
  const { chatList, setChatList } = useAppContext();
  return (
    <>
      {!chatListLoading && chatList.length > 0 && (
        <ChatList
          onChatClick={onChatClick}
          chatList={[...chatList]}
          chatListPage={chatListPage}
          setChatList={setChatList}
          currentChat={currentChat}
          chatCount={chatCount}
          setCurrentChat={setCurrentChat}
          loadMoreChats={loadMoreChats}
        />
      )}
      {!chatListLoading && chatList.length == 0 && (
        <div className="center-progress">
          <Typography textAlign="center" fontWeight="bold">
            No chats yet.
          </Typography>
        </div>
      )}
      {chatListLoading && (
        <CircularProgress
          sx={{ position: "absolute", top: "40%", left: "40%" }}
        />
      )}
      <Tooltip title="New Message" arrow>
        <IconButton
          className="add-chat-btn"
          aria-label="add"
          onClick={() => setIsAddChatDrawerOpen(true)}
        >
          <AddIcon htmlColor="#fff" />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default ChatListSidebar;
