import React from "react";
import {
  Stack,
  Typography,
  CircularProgress,
  Card,
  Box,
  Button,
  styled,
} from "@mui/material";
import PropTypes from "prop-types";
import { NoData, TaskCard } from "src/components";
import { filter } from "lodash";
import { notFound, noResults } from "src/assets";
import { useNavigate } from "react-router-dom";
// ---------------------------------------------------------
const ImgContainer = styled("div")({
  width: "30%",
  // position: 'relative',
  margin: "auto",
});
const DashboardSection = ({
  title,
  tasks,
  loading,
  sectionKey,
  onTaskAction,
  hasViewAll,
  onClickViewAll,
}) => {
  const navigate = useNavigate();
  // ---------------------------------------------------------

  const handleNavigate = (_task) => {
    console.log(_task, "tsk");
    if (!_task) {
      return;
    }
    navigate(`/workspaces/projects/${_task.project.id}`, {
      state: {
        scroll_to_task: _task._id,
        expand_section_id: _task.section_id,
      },
    });
  };
  const handleTaskAction = (action, data) => {
    onTaskAction(action, sectionKey, data);
  };
  // ---------------------------------------------------------
  return (
    <Card
      sx={{ boxShadow: 0, border: "solid 1px #c5c2c2" }}
      className="active-border"
    >
      <Stack
        className="position-relative"
        sx={{ px: { sm: 1, md: 3 }, py: 3, minHeight: "13rem" }}
      >
        <Typography gutterBottom variant="h6" sx={{ mb: 2 }}>
          {title ? title : "Untitled"}
        </Typography>
        {hasViewAll && tasks.length > 0 && (
          <Stack
            className="view-all-tasks-box"
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button onClick={onClickViewAll} variant="contained" size="small">
              View all
            </Button>
          </Stack>
        )}
        <Stack spacing={1}>
          {tasks?.map((item, index) => (
            <TaskCard
              onOpenDetail={(task) => handleNavigate(task)}
              onTaskAction={(action, data) => handleTaskAction(action, data)}
              variant="dashboard"
              task={item}
              key={index}
            />
          ))}
        </Stack>
        <NoResults loading={loading} length={tasks?.length} />
      </Stack>
    </Card>
  );
};
// ---------------------------------------------------------
const NoResults = ({ loading, length }) => {
  if (loading) {
    return (
      <div className="centered-row" style={{ flexGrow: 1 }}>
        <CircularProgress sx={{ my: 2 }} size="1.3rem" />
      </div>
    );
  }
  if (!loading) {
    if (length === 0) {
      return (
        <div className="centered-row" style={{ flexGrow: 1 }}>
          <Stack sx={{ height: "1.3rem", my: 2 }}>
            {/* <NoData
              dataLength={length}
              loading={loading}
              title="No Tasks Found !"
            /> */}
            <ImgContainer id="not-found-results-container">
              <img width="20%" src={noResults} style={{ margin: "auto" }} />
            </ImgContainer>
            <Typography
              color={(theme) => theme.palette.text.disabled}
              variant="body2"
              textAlign="center"
              fontWeight="bold"
            >
              Not Found !
            </Typography>
          </Stack>
        </div>
      );
    }
  }
};
export default DashboardSection;
DashboardSection.propTypes = {
  title: PropTypes.string.isRequired,
  tasks: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};
