import * as Yup from "yup";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
// component
import Iconify from "../../../components/Iconify";
import { CountryPicker, MuiDialog } from "src/components";
import {
  country_list,
  MAX_FIRST_NAME_LIMIT,
  MAX_LAST_NAME_LIMIT,
  MAX_PASSWORD_LIMIT,
  MAX_PHONE_LIMIT,
  MIN_FIRST_NAME_LIMIT,
  MIN_LAST_NAME_LIMIT,
  MIN_PASSWORD_LIMIT,
  MIN_PHONE_LIMIT,
} from "src/constants";
import { _user_signup, _verify_email } from "src/DAL";
import { useSnackbar } from "notistack";
import { is_number_with_special_char, set_to_localStorage } from "src/utils";
import { useAppContext } from "src/hooks";
// ----------------------------------------------------------------------

export default function RegisterForm({ invitation, state }) {
  const navigate = useNavigate();
  const { dispatch_set_user_profile } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [formInputs, setFormInputs] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    gender: "male",
    type: 1,
    country: country_list[0],
    deviceToken: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [signupResponse, setSignupResponse] = useState(
    state?.verification ? state.verification : null
  );
  const [isDialogOpen, setIsDialogOpen] = useState(
    state?.verification ? true : false
  );
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(MIN_FIRST_NAME_LIMIT, "First Name Too Short!")
      .max(MAX_FIRST_NAME_LIMIT, "First Name Too Long!")
      .required("First name required"),
    lastName: Yup.string()
      .min(MIN_LAST_NAME_LIMIT, "Last Name too Short!")
      .max(MAX_LAST_NAME_LIMIT, "Last Name Too Long!")
      .required("Last name required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(MIN_PASSWORD_LIMIT, "Password Too Short!")
      // .max(MAX_PASSWORD_LIMIT, "Password Too Long!")
      .required("Password is required"),
    phone: Yup.string()
      .min(MIN_PHONE_LIMIT, "Please enter valid phone number")
      .max(MAX_PHONE_LIMIT, "Phone Too Long!")
      .required("Phone is required"),
    gender: Yup.string().required("Choose gender"),
    gender: Yup.string().required("Choose gender"),
    country: Yup.object()
      .shape({
        code: Yup.string().required("Country code is Required"),
        label: Yup.string().required("Country is Required"),
        phone: Yup.string().required("Country is Required"),
      })
      .required("Country Required"),
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsDialogOpen(true);
    const errors = await RegisterSchema.validate(formInputs).catch((err) => {
      if (err) {
        return err.errors;
      }
      return [];
    });
    if (errors.length > 0) {
      errors.map((er) => {
        enqueueSnackbar(er, { variant: "error" });
      });
      return;
    }
    let country_code = `+${formInputs.country.phone}`;

    const req_obj = {
      email: formInputs.email,
      password: formInputs.password,
      first_name: formInputs.firstName,
      last_name: formInputs.lastName,
      country_code: country_code,
      type: formInputs.type,
      contact_number: formInputs.phone,
      gender: formInputs.gender,
      device_token: formInputs.deviceToken,
    };
    console.log(req_obj, "signup-req");

    setIsLoading(true);
    const result = await _user_signup(req_obj);
    console.log(result, "res");
    if (result.code === 200 || result.code === 202) {
      setIsLoading(false);
      // setIsConfirmationOpen(true);
      navigate("/verification", {
        state: {
          email: req_obj.email,
          from: "register",
          invitation: Boolean(invitation),
        },
      });
      // setIsDialogOpen(true);
      // enqueueSnackbar("Account Created Successfully", {
      //   variant: "success",
      //   autoHideDuration: 4000,
      // });
      //nav to login, show success modal first
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getCode = () => {
    if (formInputs.country.phone) {
      return `+${formInputs.country.phone}`;
    }
    return "";
  };
  const handlePhoneChange = (e) => {
    const { target } = e;
    let txt = target.value;
    let result = is_number_with_special_char(txt);
    if (result === true && txt.length < MAX_PHONE_LIMIT) {
      setFormInputs({ ...formInputs, [target.name]: target.value });
    }
  };
  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handleAgree = (e) => {
    const { target } = e;
    // navigate("/login", { replace: true });
    // navigate("/login", {
    //   replace: true,
    //   state: { email: formInputs.email, password: formInputs.password },
    // });
    set_to_localStorage("token", signupResponse.token);
    set_to_localStorage("user", signupResponse.customer);
    dispatch_set_user_profile(signupResponse.customer);
    setTimeout(() => {
      if (state.invitation) {
        navigate("/invitations", { replace: true });
      } else {
        navigate("/dashboard", { replace: true });
      }
    }, 150);
    //
  };
  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <TextField
            value={formInputs.firstName}
            onChange={handleChange}
            name="firstName"
            fullWidth
            label="First name"
            required
          />

          <TextField
            value={formInputs.lastName}
            onChange={handleChange}
            name="lastName"
            fullWidth
            label="Last name"
            required
          />
        </Stack>

        <CountryPicker
          setFormInputs={setFormInputs}
          country={formInputs.country}
        />
        <Grid container>
          <Grid xs={3} item sx={{ pr: 2 }}>
            <TextField
              value={getCode()}
              focused={false}
              // onChange={handlePhoneChange}
              label="Country Code"
              type="text"
              variant="outlined"
              name="countryCode"
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              value={formInputs.phone}
              onChange={handlePhoneChange}
              label="Phone number"
              required
              type="text"
              variant="outlined"
              name="phone"
              fullWidth
            />
          </Grid>
        </Grid>
        <TextField
          value={formInputs.email}
          onChange={handleChange}
          name="email"
          fullWidth
          autoComplete="current-password"
          type="email"
          label="Email address"
          required
        />
        <TextField
          required
          value={formInputs.password}
          onChange={handleChange}
          name="password"
          fullWidth
          autoComplete="current-password"
          type={showPassword ? "text" : "password"}
          label="Password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormControl>
          <FormLabel id="gender-select-radio-buttons-label">Gender</FormLabel>
          <RadioGroup
            row
            aria-labelledby="gender-select-radio-buttons-label"
            name="row-radio-buttons-group"
            value={formInputs.gender}
            onChange={(e) =>
              setFormInputs({ ...formInputs, gender: e.target.value })
            }
          >
            <FormControlLabel
              value="male"
              control={<Radio value="male" />}
              label="Male"
            />
            <FormControlLabel
              value="female"
              control={<Radio value="female" />}
              label="Female"
            />
          </RadioGroup>
        </FormControl>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isLoading}
        >
          Register
        </LoadingButton>
        <MuiDialog
          title="Account Created"
          message="Your account has been created successfully, would you like to login?"
          disableDismiss={true}
          open={isDialogOpen}
          onToggle={(value) => {
            setIsDialogOpen(value);
            navigate("/login");
          }}
          onAgree={handleAgree}
          loading={false}
        />
      </Stack>
    </form>
  );
}
