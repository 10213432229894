import { CircularProgress } from "@mui/material";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

function InfiniteScroller({
  children,
  inverse,
  dataLength,
  hasMore,
  height,
  next,
  scrollableTarget,
  style,
}) {
  return (
    <InfiniteScroll
      style={style}
      className="container-with-scrollbar"
      inverse={inverse}
      dataLength={dataLength}
      hasMore={hasMore}
      height={height}
      next={next}
      scrollableTarget={scrollableTarget}
      loader={
        <CircularProgress
          style={{
            position: "absolute",
            width: "18px",
            height: "18px",
            left: "40%",
          }}
        />
      }
    >
      {children}
    </InfiniteScroll>
  );
}

export default InfiniteScroller;
